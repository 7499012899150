import { useTranslation } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { DateTime } from 'luxon';
import {
  breakdownsCategory,
  breakdownsType,
} from '../../common/mapper/breakdown.mapper';

export declare namespace ReportTechnicalInfosType {
  type Props = {
    data: {
      startDate: string | null;
      endDate: string | null;
      accessPointType: keyof typeof breakdownsType | null;
      category: keyof typeof breakdownsCategory | null;
      description: string | null;
    };
  };
}

export const ReportTechnicalWizardInfos = ({
  data: { startDate, endDate, accessPointType, category, description },
}: ReportTechnicalInfosType.Props) => {
  const { t, lang } = useTranslation();

  return (
    <Wizard.CardResume
      data={{
        title: t('antiPassbackInformations'),
        resume: [
          {
            label: t('startDate'),
            value: startDate
              ? DateTime.fromISO(startDate).toFormat('dd/MM/yy HH:mm:ss')
              : t('notDefined'),
            color: startDate ? 'text' : 'disabled',
          },
          {
            label: t('endDate'),
            value: endDate
              ? DateTime.fromISO(endDate).toFormat('dd/MM/yy HH:mm:ss')
              : t('notDefined'),
            color: endDate ? 'text' : 'disabled',
          },
          {
            label: t('duration'),
            value:
              startDate && endDate
                ? DateTime.fromISO(endDate)
                    .diff(DateTime.fromISO(startDate), [
                      'days',
                      'hours',
                      'minutes',
                      'seconds',
                    ])
                    .toFormat(t('DURATION_FORMAT_WITH_DAY_LUXON'))
                : t('notDefined'),
            color: startDate && endDate ? 'text' : 'disabled',
          },
          {
            label: t('accessPointType'),
            value: accessPointType
              ? t(breakdownsType[accessPointType].translate)
              : t('notDefined'),
            color: accessPointType ? 'text' : 'disabled',
          },
          {
            label: t('category'),
            value: category
              ? t(breakdownsCategory[category].translate)
              : t('notDefined'),
            color: category ? 'text' : 'disabled',
          },
          {
            label: t('description'),
            value: description ? description : t('notDefined'),
            color: description ? 'text' : 'disabled',
          },
        ],
      }}
    />
  );
};
