import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useEffect } from 'react';
import { ProductCategoryEnum } from '../../../../client/graphql';
import { ProductForm } from '../../../../form/product/product.form';
import { CreateNewMasterPoolContractWizardContext } from '../../createNewMasterPoolContract.wizard';

export const ProductStep = ({
  context: { product, parkingId, contract },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewMasterPoolContractWizardContext>) => {
  useEffect(() => {
    validate(!!product.id);
  }, [product]);

  return parkingId ? (
    <ProductForm
      handleEvent={{
        select: ({ id, period, APBMode, credentials }) => {
          submit({
            product: { period, id, credentials: credentials || [] },
            contract: {
              startDate: new Date().toISOString(),
              endDate: null,
              APBMode: APBMode,
              APBNext: contract.APBNext,
              reference: null,
              description: null,
              state: true,
              capacity: null,
            },
            tenant: {
              name1: null,
              name2: null,
              address1: null,
              address2: null,
              city: null,
              country: 'CHE',
              zipCode: null,
              hasAdminPool: false,
              canCreateSubContract: false,
            },
            poolAdmin: {
              firstName: null,
              lastName: null,
              lang: null,
              phone: null,
              username: null,
            },
            motorists: {
              checked: [],
              created: [],
            },
            motoristMode: 'exist',
            motoristExistedSave: [],
          });
        },
      }}
      data={{
        parkingId,
        productId: product.id,
        category: ProductCategoryEnum.OperatorPool,
      }}
    />
  ) : null;
};
