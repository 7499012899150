import {
  AppLoaderEnd,
  AppLoaderStart,
  ConfigAnt,
  ConfigApp,
  ConfigAuth,
  ConfigGQL,
  ConfigI18N,
  ConfigMenu,
  ConfigNotification,
  ConfigRoot,
  ConfigSocket,
  ConfigTheme,
  ConfigWizard,
} from '@gimlite/watermelon';
import { createRoot } from 'react-dom/client';
import OSPSmallLogo from './assets/logos/osp_small';
import { config } from './config';
import { CustomRouter } from './CustomRouter';
import { AddOneMotoristToAnExistingPoolContractWizard } from './wizards/addOneMotoristToAnExistingPoolContract/addOneMotoristToAnExistingPoolContract.wizard';
import { AddSeveralMotoristsToAnExistingPoolContractWizard } from './wizards/addSeveralMotoristsToAnExistingPoolContract/addSeveralMotoristsToAnExistingPoolContract.wizard';
import { ChoiceCreateContractWizard } from './wizards/choiceCreateContract/choiceCreateContract.wizard';
import { CreateNewMasterPoolContractWizard } from './wizards/createNewMasterPoolContract/createNewMasterPoolContract.wizard';
import { CreateNewSingleContractWizard } from './wizards/createNewSingleContract/createNewSingleContract.wizard';
import { CreateNewSubPoolContractWizard } from './wizards/createNewSubPoolContract/createNewSubPoolContract.wizard';
import { CreateSeveralMotoristsWizard } from './wizards/createSeveralMotorists/createSeveralMotorists.wizard';
import { ReportTechnicalDeviceFailureWizard } from './wizards/reportTechnicalDeviceFailure/reportTechnicalDeviceFailure.wizard';

const root = createRoot(document.getElementById('root') as HTMLElement);

const { ENV, VERSION_FRONT, BFF_HOST, BFF_WS_HOST, BFF_TOKEN } = config;

const defaultTheme = 'light';

root.render(
  <ConfigRoot>
    <AppLoaderStart config={{ logo: OSPSmallLogo(), defaultTheme }}>
      <ConfigApp
        version={{ env: ENV, front: VERSION_FRONT }}
        favicon={
          config.PORTAL === 'operator'
            ? 'https://cellar-c2.services.clever-cloud.com/yoonite-resources/default/osp-operator.svg'
            : 'https://cellar-c2.services.clever-cloud.com/yoonite-resources/default/osp-tenant.svg'
        }
      >
        <ConfigGQL
          uri={BFF_HOST}
          headers={{
            authorization: `Bearer ${BFF_TOKEN}`,
          }}
        >
          <ConfigAuth
            features={{
              updatePassword: true,
              tfa: {
                label:
                  config.PORTAL === 'operator'
                    ? 'OSP: operator-portal'
                    : 'OSP: pool-portal',
              },
            }}
          >
            <ConfigI18N>
              <ConfigSocket uri={BFF_WS_HOST}>
                <ConfigWizard
                  registry={[
                    {
                      id: 'choiceCreateContract',
                      component: ChoiceCreateContractWizard,
                    },

                    {
                      id: 'addSeveralMotoristsToAnExistingPoolContract',
                      component:
                        AddSeveralMotoristsToAnExistingPoolContractWizard,
                    },
                    {
                      id: 'addOneMotoristToAnExistingPoolContract',
                      component: AddOneMotoristToAnExistingPoolContractWizard,
                    },
                    {
                      id: 'createNewSubPoolContract',
                      component: CreateNewSubPoolContractWizard,
                    },
                    {
                      id: 'createSeveralMotorists',
                      component: CreateSeveralMotoristsWizard,
                    },
                    ...(config.PORTAL === 'operator'
                      ? [
                          {
                            id: 'createNewSingleContract',
                            component: CreateNewSingleContractWizard,
                          },
                          {
                            id: 'createNewMasterPoolContract',
                            component: CreateNewMasterPoolContractWizard,
                          },
                          {
                            id: 'reportTechnicalDeviceFailure',
                            component: ReportTechnicalDeviceFailureWizard,
                          },
                        ]
                      : []),
                  ]}
                >
                  <ConfigMenu>
                    <ConfigNotification>
                      <ConfigTheme
                        color={{
                          dark:
                            config.PORTAL === 'operator'
                              ? '#fe1900'
                              : '#dc00dc',
                          light:
                            config.PORTAL === 'operator'
                              ? '#c81400'
                              : '#800080',
                        }}
                        defaultTheme={defaultTheme}
                      >
                        <ConfigAnt>
                          <AppLoaderEnd>
                            <CustomRouter />
                          </AppLoaderEnd>
                        </ConfigAnt>
                      </ConfigTheme>
                    </ConfigNotification>
                  </ConfigMenu>
                </ConfigWizard>
              </ConfigSocket>
            </ConfigI18N>
          </ConfigAuth>
        </ConfigGQL>
      </ConfigApp>
    </AppLoaderStart>
  </ConfigRoot>,
);
