import { useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import { AntiPassBackMode, LocalMotoristsEntity } from '../../client/graphql';
import { CredentialType } from '../../common/mapper/credential.mapper';
import { ContractCreateFormType } from '../../form/contract-create/contract-create.form';
import { MotoristsCreateFormType } from '../../form/motorists-create/motorists-create.form';
import { ProductFormType } from '../../form/product/product.form';
import { ConfirmationStep } from './steps/confirmation/confirmation.step';
import { ContractStep } from './steps/contract/contract.step';
import { MotoristsStep } from './steps/motorists/motorists.step';
import { ProductStep } from './steps/product/product.step';
import { SummaryStep } from './steps/summary/summary.step';
import { TenantStep } from './steps/tenant/tenant.step';

export type CreateNewMasterPoolContractWizardContext = {
  parkingId: string | null;
  product: {
    id: ProductFormType.Data.ProductId | null;
    period: number | null;
    credentials: CredentialType.GlobalName[];
  };
  contract: ContractCreateFormType.Data.ContractPool;
  tenant: {
    name1: string | null;
    name2: string | null;
    address1: string | null;
    address2: string | null;
    zipCode: string | null;
    city: string | null;
    country: string | null;
    hasAdminPool: boolean;
    canCreateSubContract: boolean;
  };
  poolAdmin: {
    username: string | null;
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    lang: string | null;
  };
  motorists: {
    created: MotoristsCreateFormType.Data.Created[];
    checked: LocalMotoristsEntity[];
  };
  motoristMode: 'create' | 'exist';
  motoristExistedSave: LocalMotoristsEntity[];
};

export const CreateNewMasterPoolContractWizard = observer(() => {
  const { t } = useTranslation();
  const { WizardStore } = useRootStore();

  const { parkingId } = WizardStore?.selected?.store;

  return (
    <Wizard<CreateNewMasterPoolContractWizardContext>
      config={{
        title: t(`createANewMasterPoolContract`),
      }}
      data={{
        defaultContext: {
          parkingId,
          product: {
            id: null,
            period: null,
            credentials: [],
          },
          contract: {
            startDate: new Date().toISOString(),
            endDate: null,
            APBMode: AntiPassBackMode.Hard,
            APBNext: false,
            reference: null,
            description: null,
            state: true,
            capacity: null,
          },
          tenant: {
            name1: null,
            name2: null,
            address1: null,
            address2: null,
            city: null,
            country: 'CHE',
            zipCode: null,
            hasAdminPool: false,
            canCreateSubContract: false,
          },
          poolAdmin: {
            firstName: null,
            lastName: null,
            lang: null,
            phone: null,
            username: null,
          },
          motorists: {
            checked: [],
            created: [],
          },
          motoristMode: 'exist',
          motoristExistedSave: [],
        },
      }}
      handleEvent={{
        done: async (data) => {
          console.log('done', { data });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: t('product'), component: ProductStep }} />
      <Wizard.Item config={{ title: t('contract'), component: ContractStep }} />
      <Wizard.Item config={{ title: t('tenant'), component: TenantStep }} />
      <Wizard.Item
        config={{ title: t('motorists'), component: MotoristsStep }}
      />
      <Wizard.Item
        config={{ title: t('confirmation'), component: ConfirmationStep }}
      />
      <Wizard.Item config={{ title: t('summary'), component: SummaryStep }} />
    </Wizard>
  );
});
