import { useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import {
  CategoryEnum,
  MotoristContractEntity,
  TypeEnum,
} from '../../client/graphql';
import { IssueStep } from './steps/issue/issue.step';
import { MotoristsStep } from './steps/motorists/motorists.step';
import { SummaryStep } from './steps/summary/summary.step';

export type ReportTechnicalDeviceFailureContext = {
  parkingId: string | null;
  issue: {
    startDate: string | null;
    endDate: string | null;
    duration: number | null;
    accessPointType: TypeEnum;
    category: CategoryEnum;
    description: string | null;
  };
  motoristsConcerned: MotoristContractEntity[];
};

export const ReportTechnicalDeviceFailureWizard = observer(() => {
  const { t, lang } = useTranslation();
  const { WizardStore } = useRootStore();
  const { parkingId } = WizardStore?.selected?.store;

  return (
    <Wizard<ReportTechnicalDeviceFailureContext>
      config={{
        title: t(`reportTechnicalDeviceFailure`),
      }}
      data={{
        defaultContext: {
          parkingId,
          issue: {
            startDate: new Date().toISOString(),
            endDate: null,
            duration: null,
            accessPointType: TypeEnum.Entry,
            category: CategoryEnum.C10,
            description: null,
          },
          motoristsConcerned: [],
        },
      }}
      handleEvent={{
        done: async (data) => {
          console.log('done', { data });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: t('issue'), component: IssueStep }} />
      <Wizard.Item
        config={{ title: t('motorists'), component: MotoristsStep }}
      />
      <Wizard.Item config={{ title: t('summary'), component: SummaryStep }} />
    </Wizard>
  );
});
