import { Page, Zone, useMyUrl, useTranslation } from '@gimlite/watermelon';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MotoristList } from '../../composition/list/motorist.list';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import { MotoristDetails } from '../../composition/details/motorist.details';
import { observer } from 'mobx-react-lite';
export const MotoristPage = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getParamsUrlString } = useMyUrl({});
  const { motoristId } = useParams();

  const [selected, setSelected] = useState<string>(motoristId || '');
  useEffect(() => {
    if (selected) {
      navigate(`/motorists/${selected}?${getParamsUrlString}`);
    }
  }, [selected]);
  return (
    <Page>
      <Zone
        config={{
          zones: [['list', 'details']],
          rows: ['1fr'],
          columns: ['1fr', '1fr'],
        }}
      >
        <Zone.Area
          config={{
            area: 'list',
          }}
        >
          <MotoristList
            widget={{
              title: t('listOfMotorists'),
              icon: iconGlobalEntity['motorist'],
            }}
            setSelectedMotorist={setSelected}
          />
        </Zone.Area>

        <Zone.Area
          config={{
            area: 'details',
          }}
        >
          <MotoristDetails
            widget={{ title: t('detailsOfMotorist') }}
            localMotoristId={selected}
          />
        </Zone.Area>
      </Zone>
    </Page>
  );
});
