import { Icon, useTranslation } from '@gimlite/watermelon';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Popover } from '@gimlite/watermelon/components/popover/popover.component';
import { createContext, useState } from 'react';

import { ProductEntity } from '../../client/graphql';
import { CredentialType } from '../../common/mapper/credential.mapper';
import { PLATECredentialMini } from '../../composition/credential/plate.credential';
import { QRCODECredentialMini } from '../../composition/credential/qrcode.credential';
import { RFIDCredentialMini } from '../../composition/credential/rfid.credential';
import { TCSCredentialMini } from '../../composition/credential/tcs.credential';
import './product.component.scss';

const ProductGroupContext = createContext<{
  click?: (_id: ProductType.Data.Id) => void;
  selected: ProductType.Data.Id | null;
}>({ selected: null });

export declare namespace ProductGroupType {
  type Props = {
    data: {
      selected: ProductType.Data.Id | null;
    };
    children: React.ReactNode | React.ReactNode[];
    handleEvent?: {
      click?: (_id: string) => void;
    };
  };
}

export const ProductGroup = ({
  handleEvent: { click } = {},
  children,
  data: { selected },
}: ProductGroupType.Props) => {
  const [currentSelect, setCurrentSelected] =
    useState<ProductType.Data.Id | null>(selected);

  return (
    <ProductGroupContext.Provider
      value={{
        click: (id) => {
          setCurrentSelected(id);
          click?.(id);
        },
        selected: currentSelect,
      }}
    >
      {children}
    </ProductGroupContext.Provider>
  );
};

export declare namespace ProductType {
  type Props = {
    className?: string;
    data: {
      _id: ProductType.Data.Id;
      title: string;
      category: ProductEntity['category'];
      description: string;
      price: number | 'CUSTOM' | null;
      witEngament: boolean;
      currency: string | null;
      period: {
        time: string | null;
        count: number | null;
      } | null;
      advantage?: Array<{
        label: string;
        isPresent: boolean;
      }>;
      credential: CredentialType.GlobalName[] | null;
      info?: {
        articleCode: string | null;
        createdAt: string;
      };
    };
  };

  namespace Data {
    type Id = string;
  }
}

export const Product = ({
  className = '',
  data: {
    _id,
    title,
    description,
    price,
    witEngament,
    currency,
    period,
    advantage,
    credential,
    info,
    category,
  },
}: ProductType.Props) => {
  const { t } = useTranslation();
  return (
    <ProductGroupContext.Consumer>
      {({ click, selected }) => (
        <div
          onClick={() => {
            click?.(_id);
          }}
          className={`product ${click ? 'product--over' : ''} ${
            selected === _id ? 'product--select' : ''
          } ${className}`}
        >
          <div className="product__header">
            <div className="product__header__text">
              <span className="product__header__text__title">{title}</span>
              <span className="product__header__text__description">
                {description}
              </span>
            </div>
            <div className="product__header__info">
              {info && (
                <Popover
                  config={{
                    trigger: 'hover',
                  }}
                  data={
                    <div className="product__header__info__item">
                      {
                        <Description
                          config={{
                            mode: 'line',
                          }}
                          data={[
                            {
                              key: 'articleCode',
                              label: `${t('code')} SKIDATA`,
                              value: info?.articleCode,
                            },
                            {
                              key: 'createdAt',
                              label: t('dateOfCreation'),
                              value: info.createdAt,
                            },
                          ]}
                        />
                      }
                    </div>
                  }
                >
                  <Icon config={{ type: 'faCircleInfoSolid', size: 'small' }} />
                </Popover>
              )}
            </div>
          </div>
          {price && currency && period?.count && period?.time ? (
            <div className="product__price">
              <div className="product__price__value">
                <span className="product__price__value__number">
                  {price} {currency}
                </span>
                {period?.count && period?.time && (
                  <span className="product__price__value__period">
                    /{' '}
                    {period?.count === 1
                      ? period?.time
                      : `${period?.count} ${period.time}`}
                  </span>
                )}
              </div>
              <span className="product__price__complement">
                {witEngament ? t('withEngament') : t('withoutEngament')}
              </span>
            </div>
          ) : (
            <div className="product__price--empty"></div>
          )}
          <div className="product__advantage">
            {advantage &&
              advantage.map(({ label, isPresent }, index) => (
                <div
                  key={`product__advantage__item-${index}`}
                  className="product__advantage__item"
                >
                  <div className="product__advantage__item__icon">
                    <Icon
                      config={{
                        type: isPresent ? 'faCheckSolid' : 'faXmarkSolid',
                        size: 'xsmall',
                        color: isPresent ? 'success' : 'error',
                      }}
                    />
                  </div>
                  <span className="product__advantage__item__text">
                    {label}
                  </span>
                </div>
              ))}
          </div>
          {credential && credential.length > 0 && (
            <div className="product__credential">
              <span className="product__credential__title">
                {t('credentialsCompatibility')}
              </span>
              <div className="product__credential__contain">
                {Array.from(new Set(credential))
                  .filter(Boolean)
                  .map((item, index) => (
                    <div
                      key={`product__credential__contain__item-${index}`}
                      className="product__credential__contain__item"
                    >
                      {item === 'TCS' ? (
                        <TCSCredentialMini />
                      ) : item === 'PLATE' ? (
                        <PLATECredentialMini />
                      ) : item === 'QRCODE' ? (
                        <QRCODECredentialMini />
                      ) : item === 'RFID' ? (
                        <RFIDCredentialMini />
                      ) : null}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </ProductGroupContext.Consumer>
  );
};
