import { gql } from '@apollo/client';

export const contractsGql = gql`
  query contracts(
    $parkingId: String
    $motoristId: String
    $freeflagId: String
    $order: String
    $isMotoristIn: Boolean
    $isActive: Boolean
    $contractMotoristPage: Int
    $contractMotoristLimit: Int
    $categories: [String]
  ) {
    contracts(
      parkingId: $parkingId
      motoristId: $motoristId
      freeflagId: $freeflagId
      order: $order
      isActive: $isActive
      isMotoristIn: $isMotoristIn
      categories: $categories
      page: $contractMotoristPage
      limit: $contractMotoristLimit
    ) {
      list {
        _id
        ospContractId
        isEnabled
        productId
        name1
        name2
        contractCount
        spacesAllocated
        presenceCounter
        stopDate
        startDate
        parkingId
        category
        isInside
        motoristId
        spacesOccupied

        freeflag {
          _id
        }

        motorist {
          firstName
          lastName
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
