import { gql } from '@apollo/client';

export const createSubPoolGQL = gql`
  mutation createSubPool($input: CreateSubPoolDto!) {
    createSubPool(input: $input) {
      _id
      ospContractId
      name1
      isEnabled
      name2
      address1
      address2
      zipcode
      city
      country
      createdAt
    }
  }
`;
