import { useLazyQuery, useRootStore } from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  PoolContractEntity,
  Query,
  QueryGlobalSearchArgs,
  QueryPoolContractAndSubArgs,
  QueryPoolContractsAndSubArgs,
  QueryProductArgs,
} from '../../../client/graphql';
import { ContractMasterPoolLine } from '../../../composition/line/contract-master-pool.line';
import {
  SingleCheckForm,
  SingleCheckFormType,
} from '../../../form/single-check/single-check.form';
import { AddOneMotoristToAnExistingPoolContractWizardContext } from '../../../wizards/addOneMotoristToAnExistingPoolContract/addOneMotoristToAnExistingPoolContract.wizard';
import { AddSeveralMotoristsToAnExistingPoolContractWizardContext } from '../../../wizards/addSeveralMotoristsToAnExistingPoolContract/addSeveralMotoristsToAnExistingPoolContract.wizard';
import { CreateNewSubPoolContractContext } from '../../../wizards/createNewSubPoolContract/createNewSubPoolContract.wizard';
import { globalSearchGQL } from '../../gql/globalSearch.gql';
import { poolContractAndSubGQL } from '../../gql/poolContractAndSub.gql';
import { poolContractsAndSubGQL } from '../../gql/poolContractsAndSub.gql';
import { productGQL } from '../../gql/product.gql';
import { convertCredentialsBackToFrontName } from '../../mapper/credential.mapper';
import { APBModeDetectWithCycleControl } from '../../mapper/product.mapper';

export const MasterContractStep = ({
  context: {
    parkingId,
    masterPoolContractId,
    masterPoolExistedSave,
    contract,
    mode,
    skipSubContract,
  },
  validate,
  submit,
  withRuleTenantCanCreateSubContract = false,
  ignoreDefaultSub = false,
}: WizardItemType.Config.Component<
  | AddSeveralMotoristsToAnExistingPoolContractWizardContext
  | AddOneMotoristToAnExistingPoolContractWizardContext
  | CreateNewSubPoolContractContext
> & {
  withRuleTenantCanCreateSubContract?: boolean | null;
  ignoreDefaultSub?: boolean | null;
}) => {
  const pageLimit = 50;
  const { t, lang } = useTranslation();
  // const { WizardStore } = useRootStore();
  const [searchMasterPoolContractResult, setSearchMasterPoolContractResult] =
    useState<PoolContractEntity[] | null>(null);

  const [selectMasterPoolId, setSelectMasterPoolId] = useState<string>();

  const [globalSearchCall] = useLazyQuery<
    { globalSearch: Query['globalSearch'] },
    QueryGlobalSearchArgs
  >(globalSearchGQL, {
    fetchPolicy: 'network-only',
  });

  const [poolContractsAndSubCall, poolContractsAndSubQuery] = useLazyQuery<
    { poolContractsAndSub: Query['poolContractsAndSub'] },
    QueryPoolContractsAndSubArgs
  >(poolContractsAndSubGQL, {
    fetchPolicy: 'network-only',
  });

  const [poolContractAndSubCall] = useLazyQuery<
    { poolContractAndSub: Query['poolContractAndSub'] },
    QueryPoolContractAndSubArgs
  >(poolContractAndSubGQL, {
    fetchPolicy: 'network-only',
  });

  const [productCall] = useLazyQuery<
    { product: Query['product'] },
    QueryProductArgs
  >(productGQL);

  const poolContractsAndSub = useMemo(() => {
    if (!poolContractsAndSubQuery.data) return null;

    return poolContractsAndSubQuery.data?.poolContractsAndSub;
  }, [poolContractsAndSubQuery.data?.poolContractsAndSub]);

  const masterContractFormattedLine = useCallback(
    (contracts: PoolContractEntity[]) => {
      return contracts.map(
        ({
          _id,
          name1,
          name2,
          isEnabled,
          ospContractId,
          spacesAllocated,
          contractCount,
          spacesOccupied,
          reference,
        }) => ({
          _id,
          items: {
            component: (
              <ContractMasterPoolLine
                config={{
                  action: false,
                }}
                data={{
                  code: ospContractId || null,
                  name1: name1!,
                  name2: name2 || null,
                  status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                  carCounter: [spacesOccupied || 0, spacesAllocated!],
                  classification: reference,
                  contractCounter: contractCount,
                }}
              />
            ),
          },
        }),
      );
    },
    [],
  );

  const handleSearch = useCallback(
    async (value: string) => {
      if (value && value.length >= 3) {
        const queryGlobalSearch = await globalSearchCall({
          variables: {
            content: value,
            tenantCanCreateSubContract:
              (mode === 'tenant' && withRuleTenantCanCreateSubContract) || null,
          },
        });

        const contractsNotFormatted =
          queryGlobalSearch.data?.globalSearch.contracts;

        if (!contractsNotFormatted) return;

        const masterPoolContract = contractsNotFormatted.filter(
          (element) => element.category === 'OPERATOR_POOL',
        ) as unknown as PoolContractEntity[];

        setSearchMasterPoolContractResult(() => masterPoolContract);

        submit({
          masterPoolExistedSave: [
            ...masterPoolExistedSave,
            ...masterPoolContract,
          ].reduce((acc: PoolContractEntity[], { _id, ...rest }) => {
            if (!acc.find((element) => element._id === _id)) {
              return [...acc, { _id, ...rest }];
            }
            return acc;
          }, []),
        });

        await poolContractsAndSubCall({
          variables: {
            page: 1,
            limit: pageLimit,
            ...params,
          },
        });
      } else {
        setSearchMasterPoolContractResult(() => null);
      }
    },
    [mode, withRuleTenantCanCreateSubContract],
  );

  const { fresh, paging: pagingFormatted } = useMemo((): {
    fresh: PoolContractEntity[] | undefined;
    paging?: SingleCheckFormType.Props['data']['paging'];
  } => {
    if (!poolContractsAndSub?.list) {
      return { fresh: undefined };
    } else {
      const listTarget = !searchMasterPoolContractResult
        ? poolContractsAndSub.list
        : searchMasterPoolContractResult;

      return {
        fresh: listTarget,
        paging: !searchMasterPoolContractResult
          ? poolContractsAndSub.paging
          : {
              count: searchMasterPoolContractResult.length,
              current: 1,
              limit: searchMasterPoolContractResult.length,
            },
      };
    }
  }, [
    poolContractsAndSub?.list,
    poolContractsAndSub?.paging,
    searchMasterPoolContractResult,
  ]);

  const params = useMemo(() => {
    return {
      order: 'name1',
      parkingId,
      category: 'OPERATOR_POOL',
      tenantCanCreateSubContract:
        (mode === 'tenant' && withRuleTenantCanCreateSubContract) || null,
    };
  }, [mode, withRuleTenantCanCreateSubContract, parkingId]);

  useEffect(() => {
    if (masterPoolContractId && masterPoolContractId !== selectMasterPoolId) {
      setSelectMasterPoolId(() => masterPoolContractId);

      (async () => {
        const currentContractData = await poolContractAndSubCall({
          variables: {
            contractId: masterPoolContractId,
          },
        });

        if (!currentContractData?.data?.poolContractAndSub) return;

        const currentContract = currentContractData.data.poolContractAndSub;
       
        skipSubContract?.(
          currentContract.contractCount <= 1 && !ignoreDefaultSub,
        );
        let startDate = currentContract.startDate;
        let endDate = currentContract.stopDate;
        let APBMode = APBModeDetectWithCycleControl(
          currentContract?.rules?.cycleControl || null,
        );

        const currentSubPoolContractId =
          currentContract.tenantContracts?.[0]?._id;

        if (currentContract.contractCount <= 1 && !ignoreDefaultSub) {
          submit({
            subPoolContractId: currentSubPoolContractId,
          });

          if (!currentSubPoolContractId) return;

          const currentSubContractData = await poolContractAndSubCall({
            variables: {
              contractId: currentSubPoolContractId,
            },
          });

          if (!currentSubContractData?.data?.poolContractAndSub) return;

          const currentSubContract =
            currentSubContractData.data.poolContractAndSub;

          startDate = currentSubContract.startDate;
          endDate = currentContract.stopDate;
          APBMode = APBModeDetectWithCycleControl(
            currentSubContract?.rules?.cycleControl || null,
          );
        }

        const productData = await productCall({
          variables: {
            productId: currentContract.productId,
          },
        });

        if (!productData?.data?.product) return;

        const rules = productData.data.product?.rules;

        submit({
          product: {
            id: currentContract.productId,
            credentials: rules?.credentialsTypes?.types
              ? rules.credentialsTypes.types
                  .map((item) => convertCredentialsBackToFrontName(item))
                  .filter((item) => item !== null)
              : [],
          },
          parentContract: {
            description: currentContract.description || null,
            reference: currentContract.reference || null,
            capacity: currentContract.spacesAllocated || null,
            startDate: currentContract.startDate,
            endDate: currentContract.stopDate,
            APBMode: APBModeDetectWithCycleControl(
              currentContract?.rules?.cycleControl || null,
            ),
            APBNext: null,
            state: currentContract.isEnabled,
          },
          contract: {
            ...contract,
            state: currentContract.isEnabled,
            APBMode,
            startDate,
            endDate,
          },
        });
      })();
    }
  }, [fresh, contract, masterPoolContractId, masterPoolExistedSave]);

  useEffect(() => {
    const newList = masterPoolExistedSave;

    if (poolContractsAndSub?.list) {
      poolContractsAndSub.list.forEach(({ _id, ...rest }) => {
        if (
          masterPoolExistedSave.findIndex((element) => element._id === _id) ===
          -1
        ) {
          newList.push({ _id, ...rest });
        }
      });
    }

    submit({
      masterPoolExistedSave: newList,
    });
  }, [poolContractsAndSub?.list, masterPoolExistedSave]);

  useEffect(() => {
    (async () => {
      await poolContractsAndSubCall({
        variables: {
          page: 1,
          limit: pageLimit,
          ...params,
        },
      });
    })();
  }, [params]);

  useEffect(() => {
    validate(!!masterPoolContractId);
  }, [masterPoolContractId]);

  return (
    <Zone
      config={{
        gap: {
          y: 0,
          x: 0,
        },
        zones: [['form']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'form' }}>
        <Widget
          config={{
            title: t('selectAMasterPoolContract'),
            shadow: false,
          }}
          state={{
            error: poolContractsAndSubQuery.error,
            loading: poolContractsAndSubQuery.loading,
            refetch: poolContractsAndSubQuery.refetch,
            showPermanentRefetch: false,
          }}
        >
          <SingleCheckForm
            handleEvent={{
              paging: async (data) => {
                await poolContractsAndSubCall({
                  variables: {
                    page: data.page,
                    limit: data.limit,
                    ...params,
                  },
                });
              },
              checkItem: (id) => {
                submit({
                  masterPoolContractId: id,
                });
              },
              search: handleSearch,
            }}
            data={{
              check: masterPoolContractId,
              fresh: fresh ? masterContractFormattedLine(fresh) : undefined,
              saved: masterContractFormattedLine(masterPoolExistedSave),
              paging: pagingFormatted,
            }}
            config={{
              searchPlaceholder: t('searchAMasterPoolContract'),
            }}
          />
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
