import { useRootStore, useTranslation } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { CredentialType } from '../../common/mapper/credential.mapper';
import {
  CredentialCard,
  CredentialCardType,
  CredentialMini,
} from '../../components/credential/credential.component';

export class PLATECredentialStatic {
  static info: CredentialType.Static<PLATECredentialCardType.Data> = {
    name: 'PLATE',
    icon: 'faCarSolid',
    backgroundColor: { light: '#333333', dark: '#e8e8e8' },
    color: { light: '#e8e8e8', dark: '#333333' },
    category: 'PLATE',
    primaryValue: 'plate',
  };
}

export declare namespace PLATECredentialMiniType {
  type Props = {
    data?: {
      value?: string | null;
    };
  };
}

export const PLATECredentialMini = observer(
  ({ data: { value } = {} }: PLATECredentialMiniType.Props) => {
    const { ThemeStore } = useRootStore();

    return (
      <CredentialMini
        data={{ text: value }}
        config={{
          icon: PLATECredentialStatic.info.icon,
          backgroundColor:
            PLATECredentialStatic.info.backgroundColor[ThemeStore.theme],
          color: PLATECredentialStatic.info.color[ThemeStore.theme],
        }}
      />
    );
  },
);

export declare namespace PLATECredentialCardType {
  type Props = {
    handleEvent?: {
      event?: CredentialCardType.HandleEvent.Event['function'];
    };
    data: PLATECredentialCardType.Data;
    config?: {
      isActive?: boolean;
    };
  };

  type Data = {
    plate: string;
    description: string;
  };
}

export const PLATECredentialCard = observer(
  ({
    handleEvent: { event } = {},
    data: { plate, description },
    config: { isActive = true } = {},
  }: PLATECredentialCardType.Props) => {
    const { t, lang } = useTranslation();
    const { ThemeStore } = useRootStore();

    return (
      <CredentialCard.Context.Consumer>
        {({ place, edit }) => (
          <CredentialCard
            handleEvent={{
              event,
              edit,
            }}
            data={{
              name: PLATECredentialStatic.info.name,
              info: [
                {
                  key: 'description',
                  label: t('description'),
                  value: description,
                },
                { key: 'plate', label: t('plate'), value: plate },
              ],
              category: PLATECredentialStatic.info.category,
              primaryValue: PLATECredentialStatic.info.primaryValue,
            }}
            config={{
              icon: PLATECredentialStatic.info.icon,
              backgroundColor:
                PLATECredentialStatic.info.backgroundColor[ThemeStore.theme],
              color: PLATECredentialStatic.info.color[ThemeStore.theme],
              disabled: !isActive,
              action:
                place === 'contractDisabled'
                  ? []
                  : !isActive
                    ? [{ label: t('import'), value: 'import' }]
                    : place === 'contract'
                      ? [
                          { label: t('edit'), value: 'edit' },
                          { label: t('unload'), value: 'unload' },
                        ]
                      : place === 'wizardMotoristExist'
                        ? [{ label: t('unload'), value: 'unload' }]
                        : place === 'wizardNewMotorist'
                          ? [{ label: t('delete'), value: 'delete' }]
                          : place === 'motorist'
                            ? [
                                { label: t('edit'), value: 'edit' },
                                {
                                  label: t('delete'),
                                  value: 'delete',
                                  isCritical: true,
                                },
                              ]
                            : [],
            }}
          />
        )}
      </CredentialCard.Context.Consumer>
    );
  },
);
