import { gql } from '@apollo/client';

export const globalSearchGQL = gql`
  query globalSearch(
    $content: String!
    $linkedContractId: String
    $tenantCanCreateSubContract: Boolean
  ) {
    globalSearch(
      content: $content
      linkedContractId: $linkedContractId
      tenantCanCreateSubContract: $tenantCanCreateSubContract
    ) {
      localMotorists {
        _id
        firstName
        lastName
        email
        address1
        address2
        zipcode
        city
        country
        phone
        lang
        status
        plates {
          type
          provider
          value
          description
        }
        uids {
          type
          provider
          value
          description
        }
        cards {
          type
          provider
          value
          description
        }
        activeAndTotalCount {
          active
          total
        }
      }
      contracts {
        _id
        parkingId
        name1
        name2
        ospContractId
        isEnabled
        category
        contractCount
        presenceCounter
        spacesAllocated
        spacesOccupied
        productId
        startDate
        linkedContractId
        stopDate
        reference
        credentials {
          type
          provider
          value
          description
        }
        tenantContracts {
          _id
        }
      }
    }
  }
`;
