import { useMutation, useRootStore, useTranslation } from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Mutation, MutationUpdateBreakdownArgs } from '../../client/graphql';
import { breakdownUpdateGql } from '../../common/gql/breakdown-update.gql';
import {
  ActionInline,
  ActionInlineType,
} from '../../components/action-inline/action-inline.component';

export declare namespace BreakdownActionType {
  type Props = {
    config: {
      size: ActionInlineType.Config.Size;
    };
    data: {
      breakdownId: string;
      endDate?: string;
    };
  };
}

export const BreakdownAction = observer(
  ({
    config: { size },
    data: { breakdownId, endDate },
  }: BreakdownActionType.Props) => {
    const { t } = useTranslation();
    const { GQLStore } = useRootStore();

    const [updateBreakdownCall] = useMutation<
      { updateBreakdown: Mutation['updateBreakdown'] },
      MutationUpdateBreakdownArgs
    >(breakdownUpdateGql);

    return (
      <ActionInline
        config={{ size }}
        data={[
          {
            key: 'stopBreakdown',
            text: t('stopBreakdown'),
            color: 'error',
            isCritical: true,
            show: DateTime.fromISO(endDate || '') > DateTime.now(),
            event: async () => {
              try {
                if (!breakdownId) throw new Error('Breakdown ID is required');

                await updateBreakdownCall({
                  variables: {
                    breakdownId,
                    input: {
                      endDate: DateTime.now().toISO(),
                    },
                  },
                });
              } catch (error) {
                console.error(error);
              }
            },
          },
        ]}
      />
    );
  },
);
