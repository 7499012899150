import { Page, Zone, useMyUrl, useTranslation } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import { SessionDetails } from '../../composition/details/session.details';
import { SessionList } from '../../composition/list/session.list';
export const SessionPage = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { parkingId, sessionId } = useParams();
  const [selected, setSelected] = useState<string | null>(sessionId || null);
  const { getParamsUrlString } = useMyUrl({});

  useEffect(() => {
    if (selected) {
      navigate(`/${parkingId}/sessions/${selected}?${getParamsUrlString}`);
    }
  }, [selected]);

  useEffect(() => {
    setSelected(null);
  }, [parkingId]);

  return (
    parkingId && (
      <Page>
        <Zone
          config={{
            zones: [['list details']],
            rows: ['1fr'],
            columns: ['1fr', '1fr'],
          }}
        >
          <Zone.Area
            config={{
              area: 'list',
            }}
          >
            <SessionList
              widget={{
                title: t('lastSessions'),
                icon: iconGlobalEntity['session'],
              }}
              parkingId={parkingId}
              setSelectedSession={setSelected}
            />
          </Zone.Area>
          <Zone.Area
            config={{
              area: 'details',
            }}
          >
            <SessionDetails
              widget={{ title: t('detailsOfSession') }}
              sessionId={selected}
              parkingId={parkingId}
            />
          </Zone.Area>
        </Zone>
      </Page>
    )
  );
});
