import { gql } from '@apollo/client';

export const poolContractsAndSubGQL = gql`
  query poolContractsAndSub(
    $page: Int!
    $limit: Int!
    $order: String
    $category: String
    $parkingId: String
    $tenantCanCreateSubContract: Boolean
  ) {
    poolContractsAndSub(
      page: $page
      limit: $limit
      order: $order
      category: $category
      parkingId: $parkingId
      tenantCanCreateSubContract: $tenantCanCreateSubContract
    ) {
      list {
        _id
        name1
        name2
        isEnabled
        ospContractId
        spacesAllocated
        spacesOccupied
        presenceCounter
        contractCount
        productId
        startDate
        stopDate
        reference
        tenantContracts {
          _id
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
