import { gql } from '@apollo/client';

export const subContractsGql = gql`
  query subContracts(
    $page: Int!
    $limit: Int!
    $order: String
    $linkedContractId: String
  ) {
    subContracts(
      page: $page
      limit: $limit
      order: $order
      linkedContractId: $linkedContractId
    ) {
      list {
        _id
        ospContractId
        isEnabled
        productId
        name1
        name2
        contractCount
        spacesAllocated
        presenceCounter
        spacesOccupied
        stopDate
        startDate
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
