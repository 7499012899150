/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Channel value. */
  ChannelScalarType: { input: any; output: any; }
  /** Any value in context */
  ContextEntity: { input: any; output: any; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** Any value. */
  DocumentTranslations: { input: any; output: any; }
  /** Any value. */
  OperatorTranslations: { input: any; output: any; }
  /** Any value. */
  ParkingTranslations: { input: any; output: any; }
  /** Any value in payload */
  PayloadEntity: { input: any; output: any; }
  /** Any value in context */
  RulesEntity: { input: any; output: any; }
};

export type AccessPointEntity = {
  __typename?: 'AccessPointEntity';
  _id: Scalars['String']['output'];
  code: Scalars['String']['output'];
  shortCode: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ActivateTfaDto = {
  otpToken: Scalars['String']['input'];
  tfaToken: Scalars['String']['input'];
};

export type ActivateTfaResponse = {
  __typename?: 'ActivateTfaResponse';
  success: Scalars['Boolean']['output'];
};

export type Address = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type AddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export enum AntiPassBackMode {
  Hard = 'HARD',
  None = 'NONE',
  Soft = 'SOFT'
}

export type BreakdownEntity = {
  __typename?: 'BreakdownEntity';
  _id: Scalars['String']['output'];
  category: Scalars['String']['output'];
  channels?: Maybe<Array<ChannelEntity>>;
  contractsNeutralized?: Maybe<ContractsPaging>;
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type BreakdownsPagingDto = {
  __typename?: 'BreakdownsPagingDto';
  list: Array<BreakdownEntity>;
  paging: Pagination;
};

export type CardInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  type?: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum CategoryEnum {
  C10 = 'C10',
  C20 = 'C20',
  C30 = 'C30',
  C40 = 'C40'
}

export type Channel = {
  __typename?: 'Channel';
  _id: Scalars['String']['output'];
  channel: Scalars['String']['output'];
};

export type ChannelEntity = {
  __typename?: 'ChannelEntity';
  _id: Scalars['ChannelScalarType']['output'];
  channel: Scalars['String']['output'];
};

export type ChannelInput = {
  _id: Scalars['ChannelScalarType']['input'];
  channel: Scalars['String']['input'];
};

export type ContractAddress = {
  __typename?: 'ContractAddress';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type ContractWParentEntity = {
  __typename?: 'ContractWParentEntity';
  _id: Scalars['String']['output'];
  category: Scalars['String']['output'];
  contractCount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['String']['output'];
  credentials?: Maybe<Array<CredentialEntity>>;
  description?: Maybe<Scalars['String']['output']>;
  freeflag?: Maybe<FreeFlagEntity>;
  isEnabled: Scalars['Boolean']['output'];
  motorist?: Maybe<MotoristEntity>;
  motoristId?: Maybe<Scalars['String']['output']>;
  name1?: Maybe<Scalars['String']['output']>;
  name2?: Maybe<Scalars['String']['output']>;
  ospContractId?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<ContractWParentEntity>;
  parking?: Maybe<ParkingEntity>;
  parkingId: Scalars['String']['output'];
  presenceCounter?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<ProductEntity>;
  productId: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  spacesAllocated?: Maybe<Scalars['Int']['output']>;
  startDate: Scalars['String']['output'];
  stopDate?: Maybe<Scalars['String']['output']>;
};

export type ContractsPaging = {
  __typename?: 'ContractsPaging';
  list: Array<MotoristContractEntity>;
  paging: Pagination;
};

export type CreateBreakdownDto = {
  category: CategoryEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['String']['input'];
  parkingId: Scalars['String']['input'];
  type: TypeEnum;
};

export type CreateLocalMotoristsDto = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  cards?: InputMaybe<Array<CardInput>>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedContractId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  plates?: InputMaybe<Array<PlateInput>>;
  sendInvitation?: InputMaybe<Scalars['Boolean']['input']>;
  uids?: InputMaybe<Array<UidInput>>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLocalMotoristsResponse = {
  __typename?: 'CreateLocalMotoristsResponse';
  motorist: LocalMotoristsEntity;
};

export type CreateMotoristDto = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contract?: InputMaybe<MotoristContractDto>;
  country?: InputMaybe<Scalars['String']['input']>;
  credentials: Array<CredentialInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  localMotoristId?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMotoristResponse = {
  __typename?: 'CreateMotoristResponse';
  contract: MotoristContractEntity;
  contracts: Array<MotoristContractEntity>;
  motorist: MotoristEntity;
};

export type CreatePoolDto = {
  pool: PoolDto;
  tenant: TenantDto;
  user: UserDto;
};

export type CreatePoolResponse = {
  __typename?: 'CreatePoolResponse';
  _id?: Maybe<Scalars['String']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  defaultPoolId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  name1: Scalars['String']['output'];
  name2?: Maybe<Scalars['String']['output']>;
  ospContractId: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type CreateSubPoolDto = {
  antiPassBackMode?: InputMaybe<AntiPassBackMode>;
  contractId: Scalars['String']['input'];
  contractName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name2?: InputMaybe<Scalars['String']['input']>;
  newContractSpacesAllocated: Scalars['Int']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  stopDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Cred = {
  description?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CredentialEntity = {
  __typename?: 'CredentialEntity';
  description?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  type: CredentialEnum;
  value: Scalars['String']['output'];
};

export enum CredentialEnum {
  LogicalId = 'LOGICAL_ID',
  Plate = 'PLATE',
  ProviderExternalId = 'PROVIDER_EXTERNAL_ID',
  Qrcode = 'QRCODE',
  Rfid = 'RFID'
}

export type CredentialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  type: CredentialEnum;
  value: Scalars['String']['input'];
};

export type Credentials = {
  description?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CredentialsTypesRule = {
  __typename?: 'CredentialsTypesRule';
  types: Array<CredentialsTypesTypesRule>;
};

export type CredentialsTypesTypesRule = {
  __typename?: 'CredentialsTypesTypesRule';
  provider?: Maybe<Scalars['String']['output']>;
  type: CredentialEnum;
};

export type CycleControlRule = {
  __typename?: 'CycleControlRule';
  maxOpenedSessions: Scalars['Int']['output'];
  pastDaysCount: Scalars['Int']['output'];
};

export type DateBoundary = {
  __typename?: 'DateBoundary';
  from: Scalars['String']['output'];
  to: Scalars['String']['output'];
};

export type DeactivateTfaDto = {
  tfaToken: Scalars['String']['input'];
};

export type DeactivateTfaResponse = {
  __typename?: 'DeactivateTfaResponse';
  success: Scalars['Boolean']['output'];
};

export type DocumentEntity = {
  __typename?: 'DocumentEntity';
  lang: Scalars['String']['output'];
  translations: Scalars['DocumentTranslations']['output'];
};

export type EventEntity = {
  __typename?: 'EventEntity';
  accessPointId?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  credentials?: Maybe<Array<Maybe<CredentialEntity>>>;
  reason?: Maybe<Scalars['String']['output']>;
  state: EventEnum;
};

export enum EventEnum {
  AuthorizePedestrian = 'AUTHORIZE_PEDESTRIAN',
  Created = 'CREATED',
  Entered = 'ENTERED',
  EntryAuthorized = 'ENTRY_AUTHORIZED',
  EntryDenied = 'ENTRY_DENIED',
  Exited = 'EXITED',
  ExitAuthorized = 'EXIT_AUTHORIZED',
  ExitAuthorizePending = 'EXIT_AUTHORIZE_PENDING',
  ExitDenied = 'EXIT_DENIED',
  PaymentNotified = 'PAYMENT_NOTIFIED'
}

export type ExistAdminPoolResponse = {
  __typename?: 'ExistAdminPoolResponse';
  found: Scalars['Boolean']['output'];
  user?: Maybe<User>;
};

export type ExistsMotoristResponse = {
  __typename?: 'ExistsMotoristResponse';
  found: Scalars['Boolean']['output'];
  motorist?: Maybe<MotoristEntity>;
};

export type Externalinput = {
  description?: InputMaybe<Scalars['String']['input']>;
  provider?: Scalars['String']['input'];
  type?: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type FreeFlagEntity = {
  __typename?: 'FreeFlagEntity';
  _id: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  payload?: Maybe<Scalars['PayloadEntity']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type GenerateTfaResponse = {
  __typename?: 'GenerateTfaResponse';
  label: Scalars['String']['output'];
  secret: Scalars['String']['output'];
  uri: Scalars['String']['output'];
};

export type GlobalSearchMergeResponseDto = {
  __typename?: 'GlobalSearchMergeResponseDto';
  motoristsMerge: Array<MotoristMergeEntity>;
};

export type GlobalSearchResponseDto = {
  __typename?: 'GlobalSearchResponseDto';
  contracts: Array<MotoristContractEntity>;
  localMotorists: Array<LocalMotoristsEntity>;
  motorists: Array<MotoristEntity>;
};

export type I18n = {
  __typename?: 'I18n';
  documents: Array<DocumentEntity>;
  languages: Array<Scalars['String']['output']>;
};

export type LocalMotoristsEntity = {
  __typename?: 'LocalMotoristsEntity';
  _id: Scalars['String']['output'];
  activeAndTotalCount: ActiveAndTotalCountResponse;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  cards: Array<CredentialEntity>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  /** email */
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  inside?: Maybe<Array<Scalars['String']['output']>>;
  lang?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  left: Scalars['Boolean']['output'];
  linkedMotoristId?: Maybe<Scalars['String']['output']>;
  linkedmotorist?: Maybe<MotoristEntity>;
  operatorId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  plates: Array<CredentialEntity>;
  status?: Maybe<Scalars['String']['output']>;
  uids: Array<CredentialEntity>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type LocalMotoristsPaging = {
  __typename?: 'LocalMotoristsPaging';
  list: Array<LocalMotoristsEntity>;
  paging: Pagination;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token?: Maybe<Scalars['String']['output']>;
  user: OperatorUser;
  validationSteps: Array<Scalars['String']['output']>;
};

export type LpnRule = {
  __typename?: 'LpnRule';
  allowOnlyOneContract: Scalars['Boolean']['output'];
};

export type MotoristContractDto = {
  antiPassBackMode?: InputMaybe<AntiPassBackMode>;
  description?: InputMaybe<Scalars['String']['input']>;
  freeFlagIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled: Scalars['Boolean']['input'];
  linkedContractId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
  sendInvitation?: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['DateTime']['input'];
  stopDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MotoristContractEntity = {
  __typename?: 'MotoristContractEntity';
  _id: Scalars['String']['output'];
  address?: Maybe<ContractAddress>;
  category: Scalars['String']['output'];
  contractCount?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  credentials?: Maybe<Array<CredentialEntity>>;
  dateBoundaryFromParent: DateBoundary;
  description?: Maybe<Scalars['String']['output']>;
  freeflag?: Maybe<FreeFlagEntity>;
  hasChildren: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isInside: Scalars['Boolean']['output'];
  linkedContractId?: Maybe<Scalars['String']['output']>;
  motorist?: Maybe<MotoristEntity>;
  motoristCount?: Maybe<Scalars['Int']['output']>;
  motoristId?: Maybe<Scalars['String']['output']>;
  name1?: Maybe<Scalars['String']['output']>;
  name2?: Maybe<Scalars['String']['output']>;
  ospContractId?: Maybe<Scalars['String']['output']>;
  parking: ParkingEntity;
  parkingId: Scalars['String']['output'];
  poolUsers?: Maybe<Array<PoolUserEntity>>;
  presenceCounter?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<ProductEntity>;
  productId: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  rules?: Maybe<Scalars['RulesEntity']['output']>;
  sessions: PaginatedSessions;
  spacesAllocated?: Maybe<Scalars['Float']['output']>;
  spacesOccupied?: Maybe<Scalars['Int']['output']>;
  startDate: Scalars['String']['output'];
  stopDate?: Maybe<Scalars['String']['output']>;
  tenantContracts?: Maybe<Array<MotoristContractEntityTenants>>;
};

export type MotoristContractEntityTenants = {
  __typename?: 'MotoristContractEntityTenants';
  _id: Scalars['String']['output'];
};

export type MotoristEntity = {
  __typename?: 'MotoristEntity';
  _id: Scalars['String']['output'];
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  channels?: Maybe<Array<Maybe<ChannelEntity>>>;
  city?: Maybe<Scalars['String']['output']>;
  consumer: Scalars['String']['output'];
  contractIds: Array<Scalars['String']['output']>;
  contracts?: Maybe<ContractsPaging>;
  contractsIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  credentials: Array<CredentialEntity>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  inside?: Maybe<Array<Scalars['String']['output']>>;
  isAnonymous?: Maybe<Scalars['Boolean']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  qrcode?: Maybe<QrcodeEntity>;
  sessions?: Maybe<SessionsPaging>;
  shortUid: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  /** email */
  username?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};


export type MotoristEntityContractsArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  freeflagId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isMotoristIn?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: Scalars['Int']['input'];
  motoristId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  parkingId?: InputMaybe<Scalars['String']['input']>;
};


export type MotoristEntitySessionsArgs = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  dateRangeEnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateRangeStart?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  device?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: Scalars['Int']['input'];
  motoristId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  parkingId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MotoristMergeEntity = {
  __typename?: 'MotoristMergeEntity';
  _id: Scalars['String']['output'];
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  credentials: Array<CredentialEntity>;
  email?: Maybe<Scalars['String']['output']>;
  entityOrigin: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  qrcode?: Maybe<QrcodeEntity>;
  status?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type MotoristsPaging = {
  __typename?: 'MotoristsPaging';
  list: Array<MotoristEntity>;
  paging: Pagination;
};

export type MutateContractDto = {
  address?: InputMaybe<Address>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name1?: InputMaybe<Scalars['String']['input']>;
  name2?: InputMaybe<Scalars['String']['input']>;
  presenceCounter?: InputMaybe<Scalars['Float']['input']>;
  spacesAllocated?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  stopDate?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activate2FA: ActivateTfaResponse;
  addCred: CredentialEntity;
  addCredOnContract: MotoristContractEntity;
  addFreeFlagContract: Scalars['Float']['output'];
  archiveTechnicalEvent: TechnicalEventEntity;
  closeSession: Scalars['Boolean']['output'];
  createBreakdown: BreakdownEntity;
  createLocalMotorist: CreateLocalMotoristsResponse;
  createMotorist: CreateMotoristResponse;
  createPool: CreatePoolResponse;
  createSubPool: CreatePoolResponse;
  deactivate2FA: DeactivateTfaResponse;
  editCredOnContract: MotoristContractEntity;
  enableAPBNext: MotoristContractEntity;
  exitMotorist: LocalMotoristsEntity;
  generate2FA: GenerateTfaResponse;
  loadCredOnContract: MotoristContractEntity;
  login: LoginResponse;
  mutateContract: MotoristContractEntity;
  regenerateQrcode: RegenerateQrcodeResponse;
  removeCred: Array<CredentialEntity>;
  removeCredOnContract: MotoristContractEntity;
  removeFreeFlagContract: Scalars['Float']['output'];
  sendConfirmationMail: Scalars['String']['output'];
  sendOtpMail: SendOtpMailResponse;
  stopIt: MotoristContractEntity;
  switchAPBNext: SwitchApbResponse;
  updateBreakdown: BreakdownEntity;
  updateContract: MotoristContractEntity;
  updateLocalMotorist: LocalMotoristsEntity;
  updatePassword: UpdatePasswordResponseDto;
  updatePoolContract: PoolContractEntity;
  updatelang: UpdateLangResponse;
  validate2fa: Validate2faResponse;
  verifyAndUpdateMotorist: SetVerifyMotoristResponse;
};


export type MutationActivate2FaArgs = {
  input: ActivateTfaDto;
};


export type MutationAddCredArgs = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  motoristId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plate?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  visibleId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddCredOnContractArgs = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  motoristId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plate?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  visibleId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddFreeFlagContractArgs = {
  contractId: Scalars['String']['input'];
  parkingId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationArchiveTechnicalEventArgs = {
  technicalEventId: Scalars['String']['input'];
};


export type MutationCloseSessionArgs = {
  sessionId: Scalars['String']['input'];
};


export type MutationCreateBreakdownArgs = {
  input: CreateBreakdownDto;
};


export type MutationCreateLocalMotoristArgs = {
  input: CreateLocalMotoristsDto;
};


export type MutationCreateMotoristArgs = {
  input: CreateMotoristDto;
};


export type MutationCreatePoolArgs = {
  input: CreatePoolDto;
};


export type MutationCreateSubPoolArgs = {
  input: CreateSubPoolDto;
};


export type MutationDeactivate2FaArgs = {
  input: DeactivateTfaDto;
};


export type MutationEditCredOnContractArgs = {
  contractId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oldValue?: InputMaybe<OldCredValue>;
  plate?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  uid?: InputMaybe<Scalars['String']['input']>;
  visibleId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEnableApbNextArgs = {
  contractId: Scalars['String']['input'];
  parkingId: Scalars['String']['input'];
};


export type MutationExitMotoristArgs = {
  localMotoristId: Scalars['String']['input'];
};


export type MutationLoadCredOnContractArgs = {
  contractId: Scalars['String']['input'];
  credential: Credentials;
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationMutateContractArgs = {
  id: Scalars['String']['input'];
  input: MutateContractDto;
};


export type MutationRegenerateQrcodeArgs = {
  motoristId: Scalars['String']['input'];
};


export type MutationRemoveCredArgs = {
  motoristId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationRemoveCredOnContractArgs = {
  contractId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationRemoveFreeFlagContractArgs = {
  contractId: Scalars['String']['input'];
  parkingId: Scalars['String']['input'];
};


export type MutationSendConfirmationMailArgs = {
  input: SendConfirmationMailDto;
};


export type MutationStopItArgs = {
  id: Scalars['String']['input'];
};


export type MutationSwitchApbNextArgs = {
  contractId: Scalars['String']['input'];
  parkingId: Scalars['String']['input'];
};


export type MutationUpdateBreakdownArgs = {
  breakdownId: Scalars['String']['input'];
  input: UpdateBreakdownDto;
};


export type MutationUpdateContractArgs = {
  contractId: Scalars['String']['input'];
  input: UpdateContractDto;
};


export type MutationUpdateLocalMotoristArgs = {
  input: UpdateLocalMotoristsDto;
  localMotoristId: Scalars['String']['input'];
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordDto;
};


export type MutationUpdatePoolContractArgs = {
  contractId: Scalars['String']['input'];
  input: UpdatePoolContractDto;
};


export type MutationUpdatelangArgs = {
  lang: Scalars['String']['input'];
};


export type MutationValidate2faArgs = {
  token: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationVerifyAndUpdateMotoristArgs = {
  input: SetVerifyDto;
};

export type OldCredValue = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plate?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  visibleId?: InputMaybe<Scalars['String']['input']>;
};

export type OperatorDisplayEntity = {
  __typename?: 'OperatorDisplayEntity';
  logoMain: Scalars['String']['output'];
  logoSecondary: Scalars['String']['output'];
};

export type OperatorEntity = {
  __typename?: 'OperatorEntity';
  _id?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  display: OperatorDisplayEntity;
  name: Scalars['String']['output'];
  operatorCode: Scalars['String']['output'];
  operatorContractCount: Scalars['Int']['output'];
  translation: Scalars['OperatorTranslations']['output'];
};

export type OperatorLite = {
  __typename?: 'OperatorLite';
  _id: Scalars['String']['output'];
  code: Scalars['String']['output'];
  display?: Maybe<OperatorDisplayEntity>;
  name: Scalars['String']['output'];
  operatorCode?: Maybe<Scalars['String']['output']>;
};

export type OperatorUser = {
  __typename?: 'OperatorUser';
  _id: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  operator: OperatorLite;
};

export type OperatorUserEntity = {
  __typename?: 'OperatorUserEntity';
  _id?: Maybe<Scalars['String']['output']>;
  accountId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lang: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  operator: OperatorLite;
  tfaActivated: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
};

export type PaginatedSessions = {
  __typename?: 'PaginatedSessions';
  list: Array<SessionEntity>;
  paging: PagingInfo;
};

export type Pagination = {
  __typename?: 'Pagination';
  count: Scalars['Int']['output'];
  current: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
};

export type PagingInfo = {
  __typename?: 'PagingInfo';
  count: Scalars['Int']['output'];
  current: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
};

export type ParkingEntity = {
  __typename?: 'ParkingEntity';
  _id: Scalars['String']['output'];
  accessPoints?: Maybe<Array<Maybe<AccessPointEntity>>>;
  code: Scalars['String']['output'];
  coordinates: Array<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  translation: Scalars['ParkingTranslations']['output'];
  zones?: Maybe<Array<ZoneEntity>>;
};

export type ParkingsPaging = {
  __typename?: 'ParkingsPaging';
  list: Array<ParkingEntity>;
  paging: Pagination;
};

export type PlateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  type?: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type PoolContractEntity = {
  __typename?: 'PoolContractEntity';
  _id: Scalars['String']['output'];
  activeMotoristsContractsCount?: Maybe<Scalars['Int']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  category: Scalars['String']['output'];
  channels?: Maybe<Array<Maybe<ChannelEntity>>>;
  city?: Maybe<Scalars['String']['output']>;
  contractCount: Scalars['Int']['output'];
  country?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  linkedContractId?: Maybe<Scalars['String']['output']>;
  motoristCount?: Maybe<Scalars['Int']['output']>;
  motoristsContracts?: Maybe<ContractsPaging>;
  name?: Maybe<Scalars['String']['output']>;
  name1: Scalars['String']['output'];
  name2?: Maybe<Scalars['String']['output']>;
  operatorContract?: Maybe<PoolContractEntity>;
  operatorId: Scalars['String']['output'];
  ospContractId: Scalars['String']['output'];
  parking?: Maybe<ParkingEntity>;
  parkingId: Scalars['String']['output'];
  poolUsers?: Maybe<PoolUsersPaging>;
  presenceCounter?: Maybe<Scalars['Float']['output']>;
  product?: Maybe<ProductEntity>;
  productId: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  rules?: Maybe<Scalars['RulesEntity']['output']>;
  sessions?: Maybe<SessionsPaging>;
  spacesAllocated: Scalars['Int']['output'];
  spacesOccupied?: Maybe<Scalars['Int']['output']>;
  startDate: Scalars['String']['output'];
  stopDate: Scalars['String']['output'];
  tenantContracts?: Maybe<Array<Maybe<PoolContractEntity>>>;
  zipcode?: Maybe<Scalars['String']['output']>;
};


export type PoolContractEntityMotoristsContractsArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  channels?: InputMaybe<Array<InputMaybe<ChannelInput>>>;
  limit?: Scalars['Int']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  parkingId?: InputMaybe<Scalars['String']['input']>;
  tenantCanCreateSubContract?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PoolContractEntitySessionsArgs = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  dateRangeEnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateRangeStart?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  device?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: Scalars['Int']['input'];
  motoristId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  parkingId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PoolContractsPaging = {
  __typename?: 'PoolContractsPaging';
  list: Array<PoolContractEntity>;
  paging: Pagination;
};

export type PoolDto = {
  antiPassBackMode?: InputMaybe<AntiPassBackMode>;
  description?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  parkingId?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  spacesAllocated?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  stopDate?: InputMaybe<Scalars['String']['input']>;
};

export type PoolUserEntity = {
  __typename?: 'PoolUserEntity';
  _id: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type PoolUsersPaging = {
  __typename?: 'PoolUsersPaging';
  list: Array<PoolUserEntity>;
  paging: Pagination;
};

export enum ProductCategoryEnum {
  OperatorPool = 'OPERATOR_POOL',
  PeriodSubscription = 'PERIOD_SUBSCRIPTION',
  Visitor = 'VISITOR'
}

export type ProductEntity = {
  __typename?: 'ProductEntity';
  _id: Scalars['String']['output'];
  category: ProductCategoryEnum;
  code: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  externalPayload?: Maybe<ProductExternalPayload>;
  name: Scalars['String']['output'];
  operator: OperatorEntity;
  operatorId: Scalars['String']['output'];
  parameters?: Maybe<ProductsParameters>;
  parkingId: Scalars['String']['output'];
  rules?: Maybe<ProductRules>;
  startDate: Scalars['String']['output'];
  stopDate: Scalars['String']['output'];
};

export type ProductExternalPayload = {
  __typename?: 'ProductExternalPayload';
  ArticleCode?: Maybe<Scalars['String']['output']>;
};

export type ProductRules = {
  __typename?: 'ProductRules';
  credentialsTypes?: Maybe<CredentialsTypesRule>;
  cycleControl?: Maybe<CycleControlRule>;
  lpn?: Maybe<LpnRule>;
};

export type ProductsPaging = {
  __typename?: 'ProductsPaging';
  list: Array<ProductEntity>;
  paging: Pagination;
};

export type ProductsParameters = {
  __typename?: 'ProductsParameters';
  cents?: Maybe<Scalars['Int']['output']>;
  contractTag?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  durationUnit?: Maybe<Scalars['String']['output']>;
  durationValue?: Maybe<Scalars['Int']['output']>;
  maxNoPayDaysCount?: Maybe<Scalars['Int']['output']>;
};

export type QrcodeEntity = {
  __typename?: 'QrcodeEntity';
  createdAt: Scalars['String']['output'];
  disabledAt?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  breakdown: BreakdownEntity;
  breakdowns: BreakdownsPagingDto;
  childrenContracts: Array<MotoristContractEntity>;
  consumeToken: ReadConsumeTokenResponseDto;
  contract: MotoristContractEntity;
  contractSessions: PaginatedSessions;
  contractWParents: ContractWParentEntity;
  contracts: ContractsPaging;
  /** Check if a motorist already exists and ask user provider if the credential is known */
  controlMotorist: ExistsMotoristResponse;
  existUser: ExistAdminPoolResponse;
  existsMotorist: ExistsMotoristResponse;
  globalSearch: GlobalSearchResponseDto;
  globalSearchMerge: GlobalSearchMergeResponseDto;
  i18n: I18n;
  localMotorist: LocalMotoristsEntity;
  localMotorists: LocalMotoristsPaging;
  me: OperatorUserEntity;
  motorist: MotoristEntity;
  motorists: MotoristsPaging;
  parking: ParkingEntity;
  parkings: ParkingsPaging;
  poolContractAndSub: PoolContractEntity;
  poolContractsAndSub: PoolContractsPaging;
  product: ProductEntity;
  products: ProductsPaging;
  rootContracts: Array<MotoristContractEntity>;
  session: SessionEntity;
  sessions: SessionsPaging;
  subContracts: PoolContractsPaging;
  technicalEvent: TechnicalEventEntity;
  technicalEvents: TechnicalEventsPaging;
  transactions: TransactionsPaging;
  verifyMotorist: VerifyMotoristResponse;
};


export type QueryBreakdownArgs = {
  breakdownId: Scalars['String']['input'];
};


export type QueryBreakdownsArgs = {
  limit?: Scalars['Int']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  parkingId: Scalars['String']['input'];
};


export type QueryChildrenContractsArgs = {
  category?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contractId: Scalars['String']['input'];
  isExpired?: InputMaybe<Scalars['Boolean']['input']>;
  isMotoristIn?: InputMaybe<Scalars['Boolean']['input']>;
  parkingId: Scalars['String']['input'];
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryConsumeTokenArgs = {
  contractId: Scalars['String']['input'];
};


export type QueryContractArgs = {
  id: Scalars['String']['input'];
};


export type QueryContractSessionsArgs = {
  category: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
  device?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: Scalars['Int']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryContractWParentsArgs = {
  contractId: Scalars['String']['input'];
  parkingId: Scalars['String']['input'];
};


export type QueryContractsArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  freeflagId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isMotoristIn?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: Scalars['Int']['input'];
  motoristId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  parkingId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryControlMotoristArgs = {
  externalId: Scalars['String']['input'];
  provider: Scalars['String']['input'];
};


export type QueryExistUserArgs = {
  username: Scalars['String']['input'];
};


export type QueryExistsMotoristArgs = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGlobalSearchArgs = {
  content: Scalars['String']['input'];
  linkedContractId?: InputMaybe<Scalars['String']['input']>;
  tenantCanCreateSubContract?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGlobalSearchMergeArgs = {
  content: Scalars['String']['input'];
};


export type QueryLocalMotoristArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  localMotoristId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  plates?: InputMaybe<Array<PlateInput>>;
  uids?: InputMaybe<Array<UidInput>>;
};


export type QueryLocalMotoristsArgs = {
  isLinked?: InputMaybe<Scalars['String']['input']>;
  left?: InputMaybe<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
  localMotoristId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
};


export type QueryMotoristArgs = {
  motoristId: Scalars['String']['input'];
};


export type QueryMotoristsArgs = {
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: Scalars['Int']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  parkingId: Scalars['String']['input'];
};


export type QueryParkingArgs = {
  parkingId: Scalars['String']['input'];
};


export type QueryParkingsArgs = {
  limit?: Scalars['Int']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
};


export type QueryPoolContractAndSubArgs = {
  contractId: Scalars['String']['input'];
};


export type QueryPoolContractsAndSubArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  channels?: InputMaybe<Array<InputMaybe<ChannelInput>>>;
  limit?: Scalars['Int']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  parkingId?: InputMaybe<Scalars['String']['input']>;
  tenantCanCreateSubContract?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryProductArgs = {
  productId: Scalars['String']['input'];
};


export type QueryProductsArgs = {
  category?: InputMaybe<ProductCategoryEnum>;
  limit?: Scalars['Int']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  parkingId: Scalars['String']['input'];
};


export type QueryRootContractsArgs = {
  category?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isExpired?: InputMaybe<Scalars['Boolean']['input']>;
  isMotoristIn?: InputMaybe<Scalars['Boolean']['input']>;
  parkingId: Scalars['String']['input'];
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySessionArgs = {
  sessionId: Scalars['String']['input'];
};


export type QuerySessionsArgs = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  dateRangeEnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateRangeStart?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  device?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: Scalars['Int']['input'];
  motoristId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  parkingId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySubContractsArgs = {
  limit?: Scalars['Int']['input'];
  linkedContractId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
};


export type QueryTechnicalEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryTechnicalEventsArgs = {
  archived?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  criticity?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: Scalars['Int']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  parkingId: Scalars['String']['input'];
};


export type QueryTransactionsArgs = {
  limit?: Scalars['Int']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  page?: Scalars['Int']['input'];
  parkingId: Scalars['String']['input'];
};


export type QueryVerifyMotoristArgs = {
  token: Scalars['String']['input'];
};

export type ReadConsumeTokenResponseDto = {
  __typename?: 'ReadConsumeTokenResponseDto';
  contractId: Scalars['String']['output'];
  verifLink: Scalars['String']['output'];
};

export type RegenerateQrcodeResponse = {
  __typename?: 'RegenerateQrcodeResponse';
  motorist: MotoristEntity;
};

export type SendConfirmationMailDto = {
  contractId: Scalars['String']['input'];
  motoristId: Scalars['String']['input'];
};

export type SendOtpMailResponse = {
  __typename?: 'SendOtpMailResponse';
  success: Scalars['Boolean']['output'];
};

export type SessionEntity = {
  __typename?: 'SessionEntity';
  _id: Scalars['String']['output'];
  amount?: Maybe<Scalars['Int']['output']>;
  authorizeTransaction?: Maybe<TransactionEntity>;
  authorizeTransactionId?: Maybe<Scalars['String']['output']>;
  captureTransactionId?: Maybe<Scalars['String']['output']>;
  context: Scalars['ContextEntity']['output'];
  contract?: Maybe<MotoristContractEntity>;
  contractId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  entryDate?: Maybe<Scalars['String']['output']>;
  events: Array<EventEntity>;
  exitDate?: Maybe<Scalars['String']['output']>;
  inconsistencyReasons: Array<Scalars['String']['output']>;
  isInconsistent?: Maybe<Scalars['Boolean']['output']>;
  lastAccessPointId: Scalars['String']['output'];
  motorist?: Maybe<MotoristEntity>;
  motoristId?: Maybe<Scalars['String']['output']>;
  occupancyDuration?: Maybe<Scalars['Int']['output']>;
  operatorId: Scalars['String']['output'];
  parking: ParkingEntity;
  parkingId: Scalars['String']['output'];
  product?: Maybe<ProductEntity>;
  productId?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['String']['output'];
  state: EventEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type SessionsPaging = {
  __typename?: 'SessionsPaging';
  list: Array<SessionEntity>;
  paging: Pagination;
};

export type SetVerifyDto = {
  motorist: SetVerifyMotoristDto;
  token: Scalars['String']['input'];
};

export type SetVerifyMotoristResponse = {
  __typename?: 'SetVerifyMotoristResponse';
  motorist: MotoristEntity;
};

export type SwitchApbResponse = {
  __typename?: 'SwitchAPBResponse';
  apbNext?: Maybe<Scalars['Boolean']['output']>;
};

export type TechnicalEventEntity = {
  __typename?: 'TechnicalEventEntity';
  _id: Scalars['String']['output'];
  archived: Scalars['Boolean']['output'];
  category: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  criticity: Scalars['String']['output'];
  from: Scalars['String']['output'];
  key: Scalars['String']['output'];
  payload?: Maybe<Scalars['PayloadEntity']['output']>;
  to: Array<Channel>;
  updatedAt: Scalars['String']['output'];
};

export type TechnicalEventsPaging = {
  __typename?: 'TechnicalEventsPaging';
  list: Array<TechnicalEventEntity>;
  paging: Pagination;
};

export type TenantDto = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name1: Scalars['String']['input'];
  name2?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  tenantCanCloseSession?: InputMaybe<Scalars['Boolean']['input']>;
  tenantCanCreateSubContract?: InputMaybe<Scalars['Boolean']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type TransactionEntity = {
  __typename?: 'TransactionEntity';
  _id: Scalars['String']['output'];
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  psp: Scalars['String']['output'];
  pspTransactionDate: Scalars['String']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type TransactionsPaging = {
  __typename?: 'TransactionsPaging';
  list: Array<TransactionEntity>;
  paging: Pagination;
};

export enum TypeEnum {
  Entry = 'ENTRY',
  Exit = 'EXIT'
}

export type UidInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  provider?: Scalars['String']['input'];
  type?: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UpdateBreakdownDto = {
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContractDto = {
  address?: InputMaybe<AddressInput>;
  apbMode?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  motoristCount?: InputMaybe<Scalars['Float']['input']>;
  name1?: InputMaybe<Scalars['String']['input']>;
  name2?: InputMaybe<Scalars['String']['input']>;
  plates?: InputMaybe<Array<InputMaybe<PlateInput>>>;
  presenceCounter?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  spacesAllocated?: InputMaybe<Scalars['Int']['input']>;
  spacesOccupied?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  stopDate?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLangResponse = {
  __typename?: 'UpdateLangResponse';
  success: Scalars['Boolean']['output'];
};

export type UpdateLocalMotoristsDto = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  cards?: InputMaybe<Array<InputMaybe<Externalinput>>>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  plates?: InputMaybe<Array<InputMaybe<PlateInput>>>;
  uids?: InputMaybe<Array<InputMaybe<UidInput>>>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePasswordDto = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type UpdatePasswordResponseDto = {
  __typename?: 'UpdatePasswordResponseDto';
  success: Scalars['Boolean']['output'];
};

export type UpdatePoolContractDto = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name1?: InputMaybe<Scalars['String']['input']>;
  name2?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  spacesAllocated?: InputMaybe<Scalars['Int']['input']>;
  spacesOccupied?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  stopDate?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserDto = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type Validate2faResponse = {
  __typename?: 'Validate2faResponse';
  granted: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type VerifyMotoristResponse = {
  __typename?: 'VerifyMotoristResponse';
  fieldsToVerify: FieldsToVerifyType;
  motorist: MotoristEntity;
};

export type ZoneEntity = {
  __typename?: 'ZoneEntity';
  _id: Scalars['String']['output'];
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ActiveAndTotalCountResponse = {
  __typename?: 'activeAndTotalCountResponse';
  active: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FieldsToVerifyType = {
  __typename?: 'fieldsToVerifyType';
  motorist: Array<Scalars['String']['output']>;
};

export type SetVerifyMotoristDto = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  credentials?: InputMaybe<Array<InputMaybe<Cred>>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  plates?: InputMaybe<Array<InputMaybe<PlateInput>>>;
  username?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};
