import { gql } from '@apollo/client';

export const createMotoristGQL = gql`
  mutation createMotorist($input: CreateMotoristDto!) {
    createMotorist(input: $input) {
      motorist {
        _id
        username
        firstName
        lastName
        state
      }
      contract {
        ospContractId
        isEnabled
      }
    }
  }
`;
