import { useTranslation, Page, Zone } from '@gimlite/watermelon';
import { useParams } from 'react-router';
import { IncidentList } from '../../composition/list/incident.list';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import { SessionList } from '../../composition/list/session.list';
import { observer } from 'mobx-react-lite';

export const DashboardPage = observer(() => {
  const { parkingId } = useParams();
  const { t } = useTranslation();

  return (
    parkingId && (
      <Page>
        <Zone
          config={{
            zones: [['left right']],
            rows: ['1fr'],
            columns: ['1fr', '1fr'],
          }}
        >
          <Zone.Area
            config={{
              area: 'left',
            }}
          >
            <IncidentList
              widget={{
                title: t('listOfIncidents'),
                icon: iconGlobalEntity['incident'],
              }}
              parkingId={parkingId}
            />
          </Zone.Area>

          <Zone.Area
            config={{
              area: 'right',
            }}
          >
            <Zone
              config={{
                zones: [['lastSessions'], ['ocupancy']],
                rows: ['1fr', '1fr'],
                columns: ['1fr'],
              }}
            >
              <Zone.Area
                config={{
                  area: 'lastSessions',
                }}
              >
                <SessionList
                  widget={{
                    title: t('lastSessions'),
                    icon: iconGlobalEntity['session'],
                  }}
                  parkingId={parkingId}
                />
              </Zone.Area>

              {/* <Zone.Area
                config={{
                  area: 'ocupancy',
                }}
              >
                <OccupancyDetails
                  widget={{
                    title: t('actualSeasonParkerOccupancy'),
                    icon: 'customPieFull',
                  }}
                  parkingId={parkingId}
                />
              </Zone.Area> */}
            </Zone>
          </Zone.Area>
        </Zone>
      </Page>
    )
  );
});
