import {
  Col,
  DatePicker,
  DatePickerType,
  Form,
  Select,
  Space,
} from '@gimlite/watermelon';
import { TextArea } from '@gimlite/watermelon/components/textarea/textarea.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import {
  breakdownsCategory,
  breakdownsType,
} from '../../../../common/mapper/breakdown.mapper';
import { ReportTechnicalWizardInfos } from '../../../../composition/wizard-infos/report-technical.wizard-infos';
import { ReportTechnicalDeviceFailureContext } from '../../reportTechnicalDeviceFailure.wizard';

export const IssueStep = ({
  context: { issue },
  validate,
  submit,
}: WizardItemType.Config.Component<ReportTechnicalDeviceFailureContext>) => {
  const { t, lang } = useTranslation();

  const endDateLimit = useMemo((): {
    min: DatePickerType.Config.Min | null;
    max: DatePickerType.Config.Max | null;
  } => {
    return {
      min: issue.startDate || new Date().toISOString(),
      max: issue.startDate
        ? DateTime.fromISO(issue.startDate).plus({ days: 3 }).toISO()
        : DateTime.now().plus({ days: 3 }).toISO(),
    };
  }, [issue.startDate]);

  const startDateLimit = useMemo((): {
    min: DatePickerType.Config.Min | null;
    max: DatePickerType.Config.Max | null;
  } => {
    return {
      min: new Date().toISOString(),
      max: issue.endDate,
    };
  }, [issue.endDate]);

  return (
    <Form
      data={{ value: issue }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit({ issue: { ...value } });
        },
        validate: (value: boolean) => {
          validate(value);
        },
      }}
    >
      <Zone
        config={{
          gap: {
            y: 1,
            x: 1,
          },
          zones: [['info', 'form']],
          rows: ['1fr'],
          columns: ['min-content', '1fr'],
        }}
      >
        <Zone.Area config={{ area: 'info' }}>
          <ReportTechnicalWizardInfos
            data={{
              startDate: issue.startDate,
              endDate: issue.endDate,
              accessPointType: issue.accessPointType,
              category: issue.category,
              description: issue.description,
            }}
          />
        </Zone.Area>

        <Zone.Area config={{ area: 'form' }}>
          <Zone
            config={{
              zones: [['informations']],
              rows: ['1fr'],
              columns: ['1fr'],
            }}
          >
            <Zone.Area config={{ area: 'informations' }}>
              <Widget config={{ title: t('issueInformations'), shadow: false }}>
                <Col>
                  <Form.Item
                    data={{ defaultValue: issue.startDate }}
                    config={{
                      name: 'startDate',
                      label: t('startDate'),
                      rules: [{ required: true }],
                      way: 'vertical',
                    }}
                  >
                    <DatePicker
                      config={{
                        disabled: true,
                        width: 'full',
                        format: 'datetime',
                        min: startDateLimit.min,
                        max: startDateLimit.max,
                      }}
                    />
                  </Form.Item>
                  <Space config={{ count: 2 }} />
                  <Form.Item
                    data={{ defaultValue: issue.endDate }}
                    config={{
                      name: 'endDate',
                      label: t('ISSUE_ESTIMATES_END_DATE'),
                      rules: [{ required: true }],
                      way: 'vertical',
                    }}
                  >
                    <DatePicker
                      config={{
                        width: 'full',
                        format: 'datetime',
                        min: endDateLimit.min,
                        max: endDateLimit.max,
                      }}
                    />
                  </Form.Item>
                  <Space config={{ count: 2 }} />
                  <Form.Item
                    data={{ defaultValue: issue.accessPointType }}
                    config={{
                      name: 'accessPointType',
                      label: t('accessPointType'),
                      rules: [{ required: true }],
                      way: 'vertical',
                    }}
                  >
                    <Select
                      data={{
                        items: Object.entries(breakdownsType).map(
                          ([key, value]) => {
                            return {
                              value: key,
                              label: t(value.translate),
                            };
                          },
                        ),
                      }}
                      config={{ width: 'full' }}
                    />
                  </Form.Item>
                  <Space config={{ count: 2 }} />
                  <Form.Item
                    data={{ defaultValue: issue.category }}
                    config={{
                      name: 'category',
                      label: t('CATEGORY_INFORMATIVE_ONLY'),
                      rules: [{ required: true }],
                      way: 'vertical',
                    }}
                  >
                    <Select
                      data={{
                        items: Object.entries(breakdownsCategory).map(
                          ([key, value]) => {
                            return {
                              value: key,
                              label: t(value.translate),
                            };
                          },
                        ),
                      }}
                      config={{ width: 'full' }}
                    />
                  </Form.Item>
                  <Space config={{ count: 2 }} />
                  <Form.Item
                    data={{ defaultValue: issue.description }}
                    config={{
                      name: 'description',
                      label: t('description'),
                      way: 'vertical',
                    }}
                  >
                    <TextArea config={{ width: 'full', height: 'xlarge' }} />
                  </Form.Item>
                </Col>
              </Widget>
            </Zone.Area>
          </Zone>
        </Zone.Area>
      </Zone>
    </Form>
  );
};
