import { IconType } from '@gimlite/watermelon';
import { ListFlatInfo } from '@gimlite/watermelon/components/list/list.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { MotoristContractEntity, PoolUserEntity } from '../../client/graphql';
import { PoolAdminLine } from '../line/pool-admin.line';

export type PoolAdminListProps = {
  contract: MotoristContractEntity;
  widget?: {
    title?: string;
    icon?: IconType.Config.Type;
  };
};

function renderPoolUser({
  _id,
  firstName,
  lastName,
  username,
}: PoolUserEntity) {
  return {
    _id,
    items: {
      component: (
        <PoolAdminLine
          data={{
            firstName: firstName || null,
            lastName: lastName || null,
            email: username || null,
          }}
        />
      ),
    },
  };
}

export const PoolAdminList = ({ widget, contract }: PoolAdminListProps) => {
  return (
    <Widget
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
        icon: widget?.icon,
      }}
    >
      <ListFlatInfo<any>
        data={{
          list: contract.poolUsers?.map(renderPoolUser) || [],
          paging: {
            count: 20,
            current: 1,
            limit: 20,
          },
        }}
        config={{
          pagination: 'scroll',
          columns: [{ key: 'component', size: '1fr' }],
        }}
      />
    </Widget>
  );
};
