import { Icon, Identifiable } from '@gimlite/watermelon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './tree.component.scss';

const defaultCategoryFilter = JSON.stringify([
  'PERIOD_SUBSCRIPTION',
  'OPERATOR_POOL',
]);

export type Filter = {
  category?: string[];
  status?: string[];
  isMotoristIn?: boolean;
};

export type TreeNodeProps<T> = {
  _id: string;
  data: T;
  selectedId?: string;
  depth: number;
  fetchChildren: (id: string, filterQ: object) => Promise<T[]>;
  filterQ?: Filter;
  isLeaf: (data: T) => boolean;
  setSelectedId?: (id: string) => void;
  isExpanded?: (data: T) => boolean;
  nodeRenderer?: (
    data: T,
    depth?: number,
  ) => { component: JSX.Element; hasChildren: boolean; lastDepth: boolean };
  wsSubscriptions?: string[];
  cacheTtlMs?: number;
  registerNodeRef: (id: string, ref: HTMLDivElement) => void;
  ancestors?: string[];
  clicked?: boolean;
};

export const TreeNode = <T extends Identifiable>({
  _id,
  data,
  depth,
  fetchChildren,
  filterQ = {},
  isLeaf,
  setSelectedId,
  isExpanded = () => false,
  nodeRenderer,
  selectedId,
  wsSubscriptions = [],
  registerNodeRef,
  ancestors = [],
  clicked,
}: TreeNodeProps<T>) => {
  const [expanded, setExpanded] = useState(isExpanded(data));
  const [children, setChildren] = useState<TreeNodeProps<T>[]>([]);
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const leaf = isLeaf(data);

  async function expandChildren(_id: string) {
    const filter = {
      ...filterQ,
      category:
        JSON.stringify(filterQ.category) === defaultCategoryFilter
          ? undefined
          : filterQ.category,
    };

    fetchChildren(_id, filter).then((children) => {
      setChildren([]);

      if (children.length === 0) {
        setChildren([]);
      } else {
        setChildren(
          children.map((child) => ({
            _id: child._id,
            data: child,
            fetchChildren,
            filterQ,
            depth: depth + 1,
            isLeaf,
            setSelectedId,
            nodeRenderer,
            selectedId,
            isExpanded,
            registerNodeRef,
            ancestors: ancestors,
            clicked,
          })),
        );

        if (nodeRef.current) registerNodeRef(_id, nodeRef.current);
      }
    });
  }

  const handleExpand = async () => {
    if (leaf) return;

    if (expanded) {
      setExpanded(false);
      setChildren([]);
    } else {
      setExpanded(true);
      expandChildren(_id);
    }
  };

  const childrenNodes = () => {
    return children.map((child) => (
      <TreeNode<T>
        key={JSON.stringify(child)}
        {...child}
        selectedId={selectedId}
      />
    ));
  };

  const nodeRendererExe = useMemo(() => {
    return nodeRenderer?.(data);
  }, [data]);

  const lastDepth = useMemo(() => {
    return nodeRendererExe && nodeRendererExe?.lastDepth;
  }, [nodeRendererExe]);

  useEffect(() => {
    if (nodeRef.current) {
      registerNodeRef(_id, nodeRef.current);
    }
  }, [nodeRef]);

  useEffect(() => {
    if (ancestors.includes(_id)) {
      expandChildren(_id);
    }
  }, [ancestors]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleExpand();
      }}
      className={`tree-row  
        ${lastDepth ? 'tree-row--lastDepth' : ''}
      `}
      style={{ paddingLeft: depth === 0 ? 0 : 25 }}
    >
      <div className="tree-row__contain">
        <div className={`tree-row__contain__button`}>
          {!leaf && nodeRendererExe?.hasChildren && !lastDepth && (
            <Icon
              className={`
                tree-row__contain__button__icon
                ${expanded ? 'tree-row__contain__button__icon--expanded' : ''}
                `}
              config={{
                type: 'faChevronRightSolid',
                size: 'small',
                color: 'primary',
              }}
            />
          )}
        </div>

        <div
          className={`
            tree-row__contain__item
            ${selectedId === _id ? ' tree-row__contain__item__selected' : ''}
          `}
          onClick={() =>
            navigate(
              `/${(data as any).parkingId}/contracts/${_id}?clicked=true`,
            )
          }
          ref={nodeRef}
        >
          {nodeRendererExe && nodeRendererExe.component}
        </div>
      </div>

      <div className="tree-row__child">
        {!leaf && expanded && <>{childrenNodes()}</>}
      </div>
    </div>
  );
};
