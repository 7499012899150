import { Form } from '@gimlite/watermelon/components/form/form.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { ContractWizardInfos } from '../../../../composition/wizard-infos/contract.wizard-infos';
import { ContractFormCreate } from '../../../../form/contract-create/contract-create.form';
import { CreateNewSubPoolContractContext } from '../../createNewSubPoolContract.wizard';

export const ContractStep = ({
  context: { parentContract, contract },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewSubPoolContractContext>) => {
  return (
    <Form
      data={{ value: contract }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          let contractFormatted = value;

          if (contractFormatted.APBMode === 'NONE') {
            contractFormatted = {
              ...contractFormatted,
              APBNext: null,
            };
          } else if (
            contractFormatted.APBMode !== 'NONE' &&
            [null, undefined].includes(contractFormatted.APBNext)
          ) {
            contractFormatted = {
              ...contractFormatted,
              APBNext: true,
            };
          }

          submit({ contract: contractFormatted });
        },
        validate: (value: boolean) => {
          validate(value);
        },
      }}
    >
      <Zone
        config={{
          gap: {
            y: 1,
            x: 1,
          },
          zones: [['info', 'form']],
          rows: ['1fr'],
          columns: ['min-content', '1fr'],
        }}
      >
        <Zone.Area config={{ area: 'info' }}>
          <ContractWizardInfos
            data={{
              APBMode: contract.APBMode,
              APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
              state: contract.state,
              startDate: contract.startDate,
              endDate: contract.endDate,
              description: contract.description,
              reference: contract.reference,
            }}
          />
        </Zone.Area>

        {parentContract && (
          <Zone.Area config={{ area: 'form' }}>
            <ContractFormCreate
              data={{ contract }}
              config={{ parentContract }}
            />
          </Zone.Area>
        )}
      </Zone>
    </Form>
  );
};
