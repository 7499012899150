import { useRootStore } from '@gimlite/watermelon';
import {
  Wizard,
  WizardItemType,
} from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import { AntiPassBackMode, PoolContractEntity } from '../../client/graphql';
import { MasterContractStep } from '../../common/steps/masterContract/masterContract.step';
import { portal } from '../../config';
import { ContractCreateFormType } from '../../form/contract-create/contract-create.form';

import { ConfirmationStep } from './steps/confirmation/confirmation.step';
import { ContractStep } from './steps/contract/contract.step';
import { InformationsStep } from './steps/informations/informations.step';
import { SummaryStep } from './steps/summary/summary.step';

export type CreateNewSubPoolContractContext = {
  parkingId: string | null;
  masterPoolContractId: string | null;
  parentContract: ContractCreateFormType.Data.ContractPool | null;
  contract: ContractCreateFormType.Data.ContractPool;
  informations: {
    name1: string | null;
    name2: string | null;
  };
  masterPoolExistedSave: PoolContractEntity[];
  mode: typeof portal;
  skipSubContract?: (skip: boolean) => void;
};

export const CreateNewSubPoolContractWizard = observer(() => {
  const { t, lang } = useTranslation();

  const { WizardStore } = useRootStore();
  const { parkingId, mode } = WizardStore?.selected?.store;
  return (
    <Wizard<CreateNewSubPoolContractContext>
      config={{
        title: t(`createNewSubPoolContract`),
      }}
      data={{
        defaultContext: {
          parkingId,
          masterPoolContractId: null,
          parentContract: null,
          contract: {
            startDate: new Date().toISOString(),
            endDate: null,
            APBMode: AntiPassBackMode.Hard,
            APBNext: false,
            reference: null,
            description: null,
            state: true,
            capacity: null,
          },
          informations: {
            name1: null,
            name2: null,
          },
          masterPoolExistedSave: [],
          mode,
        },
      }}
      handleEvent={{
        done: async (data) => {
          console.log('done', { data });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item
        config={{
          title: t('masterContract'),
          component: (
            params: WizardItemType.Config.Component<CreateNewSubPoolContractContext>,
          ) =>
            MasterContractStep({
              ...params,
              withRuleTenantCanCreateSubContract: true,
              ignoreDefaultSub: true,
            }),
        }}
      />
      <Wizard.Item config={{ title: t('contract'), component: ContractStep }} />
      <Wizard.Item
        config={{ title: t('informations'), component: InformationsStep }}
      />
      <Wizard.Item
        config={{ title: t('confirmation'), component: ConfirmationStep }}
      />
      <Wizard.Item config={{ title: t('summary'), component: SummaryStep }} />
    </Wizard>
  );
});
