import { ListFlatMultipleCheckType, useLazyQuery } from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LocalMotoristsEntity,
  Query,
  QueryGlobalSearchArgs,
  QueryLocalMotoristsArgs,
} from '../../../../client/graphql';
import { globalSearchGQL } from '../../../../common/gql/globalSearch.gql';
import { localMotoristsGql } from '../../../../common/gql/local-motorists.gql';
import { anonimizedEmail } from '../../../../common/utils/anonymise-email';
import { SwitcherText } from '../../../../components/switcher-text/switcher-text.component';
import { MotoristLine } from '../../../../composition/line/motorist.line';
import { ContractWizardInfos } from '../../../../composition/wizard-infos/contract.wizard-infos';
import { MotoristsCreateForm } from '../../../../form/motorists-create/motorists-create.form';
import {
  MultipleCheckForm,
  MultipleCheckFormType,
} from '../../../../form/multiple-check/multiple-check.form';
import { CreateNewMasterPoolContractWizardContext } from '../../createNewMasterPoolContract.wizard';

const params = { order: 'lastName' };
const pageLimit = 50;

export const MotoristsStep = ({
  context: { contract, product, motorists, motoristExistedSave, motoristMode },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewMasterPoolContractWizardContext>) => {
  const { t, lang } = useTranslation();
  const [searchLocalMotoristsResult, setSearchLocalMotoristsResult] = useState<
    LocalMotoristsEntity[] | null
  >(null);

  const [localMotoristsCall, localMotoristsQuery] = useLazyQuery<
    { localMotorists: Query['localMotorists'] },
    QueryLocalMotoristsArgs
  >(localMotoristsGql, {
    variables: {
      page: 1,
      limit: pageLimit,
      order: 'lastName',
    },
    socket: {
      'localmotorist:created': () => true,
      'localmotorist:updated': () => true,
    },
  });

  const [globalSearchCall] = useLazyQuery<
    { globalSearch: Query['globalSearch'] },
    QueryGlobalSearchArgs
  >(globalSearchGQL);

  useEffect(() => {
    (async () => {
      try {
        await localMotoristsCall({
          variables: {
            page: 1,
            limit: pageLimit,
            order: 'lastName',
          },
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const localMotorists = useMemo(() => {
    if (!localMotoristsQuery.data) return null;

    return localMotoristsQuery.data?.localMotorists;
  }, [localMotoristsQuery.data?.localMotorists]);

  const motoristsFormattedLine = useCallback(
    (
      motorists: LocalMotoristsEntity[],
    ): ListFlatMultipleCheckType.Data.Line<any>[] => {
      return motorists.map(({ _id, firstName, lastName, email, status }) => {
        return {
          _id,
          items: {
            component: (
              <MotoristLine
                data={{
                  firstName: firstName || null,
                  lastName: lastName || null,
                  email: email ? anonimizedEmail(email) : null,
                  status: status === 'VERIFIED' ? 'VERIFIED' : 'UNVERIFIED',
                }}
                config={{ action: false, icon: true }}
              />
            ),
          },
        };
      });
    },
    [],
  );

  const handleSearch = useCallback(async (value: string) => {
    if (value.length >= 3) {
      const { data } = await globalSearchCall({
        variables: {
          content: value,
        },
      });

      if (data?.globalSearch?.localMotorists) {
        setSearchLocalMotoristsResult(data?.globalSearch?.localMotorists);
      }

      submit({
        motoristExistedSave: [
          ...motoristExistedSave,
          ...(data?.globalSearch?.localMotorists || []),
        ].reduce((acc: LocalMotoristsEntity[], { _id, ...rest }) => {
          if (!acc.find((element) => element._id === _id)) {
            return [...acc, { _id, ...rest }];
          }
          return acc;
        }, []),
      });

      try {
        await localMotoristsCall({
          variables: {
            page: 1,
            limit: pageLimit,
            order: 'lastName',
          },
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      setSearchLocalMotoristsResult(() => null);
    }
  }, []);

  const { fresh, paging: pagingFormatted } = useMemo((): {
    fresh: LocalMotoristsEntity[] | undefined;
    paging?: MultipleCheckFormType.Props['data']['paging'];
  } => {
    if (!localMotorists?.list) {
      return { fresh: undefined };
    } else {
      const listTarget = !searchLocalMotoristsResult
        ? localMotorists.list
        : searchLocalMotoristsResult;

      return {
        fresh: listTarget,
        paging: !searchLocalMotoristsResult
          ? localMotorists.paging
          : {
              count: searchLocalMotoristsResult.length,
              current: 1,
              limit: searchLocalMotoristsResult.length,
            },
      };
    }
  }, [localMotorists, searchLocalMotoristsResult]);

  useEffect(() => {
    validate(true);
  }, []);

  useEffect(() => {
    if (motoristMode === 'exist') {
      const newList = motoristExistedSave;

      if (localMotorists?.list) {
        localMotorists.list.forEach(({ _id, ...rest }) => {
          if (
            motoristExistedSave.findIndex((element) => element._id === _id) ===
            -1
          ) {
            newList.push({ _id, ...rest });
          }
        });
      }

      submit({
        motoristExistedSave: newList,
      });
    }
  }, [localMotorists?.list, motoristExistedSave, motoristMode]);

  useEffect(() => {
    (async () => {
      try {
        await localMotoristsCall({
          variables: {
            page: 1,
            limit: pageLimit,
            order: 'lastName',
          },
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'form']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ContractWizardInfos
          data={{
            productName: product.id ? t(`product-${product.id}-name`) : null,
            APBMode: contract.APBMode,
            APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
            state: contract.state,
            startDate: contract.startDate,
            endDate: contract.endDate,
            description: contract.description,
            reference: contract.reference,
            capacity: contract.capacity,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'form' }}>
        <Zone
          config={{
            gap: {
              y: 0,
              x: 0,
            },
            zones: [
              ['link', '.'],
              ['work', 'work'],
            ],
            rows: ['min-content', '1fr'],
            columns: ['1fr', '1fr'],
          }}
        >
          <Zone.Area config={{ area: 'link' }}>
            <Widget config={{ shadow: false, full: true }}>
              <SwitcherText<
                CreateNewMasterPoolContractWizardContext['motoristMode']
              >
                handleEvent={{
                  mode: async (value) => {
                    try {
                      await localMotoristsCall({
                        variables: {
                          page: 1,
                          limit: pageLimit,
                          order: 'lastName',
                        },
                      });
                    } catch (error) {
                      console.error(error);
                    }
                    validate(true);
                    submit({
                      motoristMode: value,
                      motorists: {
                        checked: [],
                        created: [],
                      },
                      motoristExistedSave:
                        value === 'create' ? [] : motoristExistedSave,
                    });
                  },
                }}
                data={{ value: motoristMode }}
                config={{
                  mode: [
                    {
                      label: t('chooseExistingMotorists?'),
                      value: 'create',
                    },
                    {
                      label: t('createMotorists?'),
                      value: 'exist',
                    },
                  ],
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'work' }}>
            <Widget
              config={{
                title:
                  motoristMode === 'exist'
                    ? t('listOfExistingMotorist')
                    : t('createSeveralMotoristContracts'),
                shadow: false,
              }}
              state={{
                loading:
                  motoristMode === 'exist'
                    ? localMotoristsQuery.loading
                    : undefined,
                refetch:
                  motoristMode === 'exist'
                    ? localMotoristsQuery.refetch
                    : undefined,
                error:
                  motoristMode === 'exist'
                    ? localMotoristsQuery.error
                    : undefined,
                showPermanentRefetch:
                  motoristMode === 'exist' ? false : undefined,
              }}
            >
              {motoristMode === 'exist' ? (
                <MultipleCheckForm
                  config={{
                    searchPlaceholder: t('searchAMotorist'),
                  }}
                  handleEvent={{
                    checkList: (list) => {
                      submit({
                        motorists: {
                          ...motorists,
                          checked: list.map(
                            (id) =>
                              [fresh ? fresh : [], motoristExistedSave]
                                .flat()
                                .find(
                                  (element) => element._id === id,
                                ) as LocalMotoristsEntity,
                          ),
                        },
                      });
                    },
                    paging: async (data) => {
                      try {
                        await localMotoristsCall({
                          variables: {
                            page: data.page,
                            limit: data.limit,
                            order: 'lastName',
                          },
                        });
                      } catch (error) {
                        console.error(error);
                      }
                    },
                    search: handleSearch,
                  }}
                  data={{
                    paging: pagingFormatted,
                    checked: motorists.checked.map((element) => element._id),
                    fresh: fresh ? motoristsFormattedLine(fresh) : undefined,
                    saved: motoristsFormattedLine(motoristExistedSave),
                  }}
                />
              ) : (
                <MotoristsCreateForm
                  config={{
                    validIfEmpty: true,
                  }}
                  handleEvent={{
                    validate,
                    submit: (data) =>
                      submit({ motorists: { ...motorists, created: data } }),
                  }}
                  data={{
                    created: motorists.created,
                  }}
                />
              )}
            </Widget>
          </Zone.Area>
        </Zone>
      </Zone.Area>
    </Zone>
  );
};
