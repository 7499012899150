import {
  AutoComplete,
  AutoCompleteType,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Widget,
  Zone,
  countries,
  deleteAllWithSameProperties,
  hasAtLeastOneWithSameProperties,
  isValidEmail,
  languages,
  useRootStore,
  useTranslation,
} from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { DefaultMotoristWizard } from '../../common/defaultValue/wizard';
import {
  CredentialCard,
  CredentialCardGroupType,
} from '../../components/credential/credential.component';
import {
  CredentialType,
  downloadQrCode,
  globalSortCredentialsFn,
} from '../../common/mapper/credential.mapper';
import {
  PLATECredentialCard,
  PLATECredentialStatic,
} from '../../composition/credential/plate.credential';
import { QRCODECredentialCard } from '../../composition/credential/qrcode.credential';
import {
  RFIDCredentialCard,
  RFIDCredentialStatic,
} from '../../composition/credential/rfid.credential';
import { TCSCredentialCard } from '../../composition/credential/tcs.credential';
import './motorist-create.form.scss';
import { config } from '../../config';

export declare namespace MotoristCreateFormType {
  type Props = {
    handleEvent: {
      submitCredentials: (
        data: MotoristCreateFormType.Data.Credentials,
      ) => void;
      submitMotorist: (data: MotoristCreateFormType.Data.Motorist) => void;
      validate: (isValid: boolean) => void;
    };
    data: {
      credentials: MotoristCreateFormType.Data.Credentials;
      motorist: MotoristCreateFormType.Data.Motorist;
    };
    config?: {
      credentialsEdit?: CredentialCardGroupType.Config.Credentials;
      search?: {
        selectMotorist: (value: AutoCompleteType.Data.Item | null) => void;
        clearSearchMotorist: () => void;
        searchMotorist: (value: AutoCompleteType.Data.Item['value']) => void;
        submitSearchMotoristValue: (
          data: AutoCompleteType.Data.Item | null,
        ) => void;
        currentSearchMotoristItems: AutoCompleteType.Data.Item[];
        currentSearchMotoristValue: MotoristCreateFormType.Data.CurrentSearchMotoristValue;
      };
    };
  };

  namespace Data {
    type Credentials = {
      actived: CredentialType.CredentialFromFront[];
      desactived: CredentialType.CredentialFromFront[];
      context: CredentialCardGroupType.Config.Place;
    };

    type Motorist = {
      _id: string;
      firstName: string | null;
      lastName: string | null;
      address1: string | null;
      address2: string | null;
      city: string | null;
      zipcode: string | null;
      country: string | null;
      username: string | null;
      status: string | null;
      lang: string | null;
      invitationMotorist: boolean;
    };

    type CurrentSearchMotoristValue = AutoCompleteType.Data.Item | null;
  }
}

export const MotoristCreateForm = observer(
  ({
    data: { credentials, motorist },
    handleEvent: { submitCredentials, submitMotorist, validate },
    config: { search, credentialsEdit } = {},
  }: MotoristCreateFormType.Props) => {
    const { t, lang } = useTranslation();
    const { AppStore } = useRootStore();

    const cardIsActived = useCallback(
      (value: CredentialType.CredentialFromFront): boolean => {
        return !hasAtLeastOneWithSameProperties(credentials.desactived, value);
      },
      [credentials],
    );

    const cardCreate = useCallback(
      (value: CredentialType.CredentialFromFront) => {
        submitCredentials({
          desactived: credentials.desactived,
          context: credentials.context,
          actived: [
            ...credentials.actived,
            {
              ...value,
            },
          ],
        });
      },
      [credentials],
    );

    const cardDelete = useCallback(
      (value: CredentialType.CredentialFromFront) => {
        submitCredentials({
          desactived: credentials.desactived,
          context: credentials.context,
          actived:
            deleteAllWithSameProperties<CredentialType.CredentialFromFront>(
              credentials.actived,
              value,
            ),
        });
      },
      [credentials],
    );

    const cardImport = useCallback(
      (value: CredentialType.CredentialFromFront) => {
        submitCredentials({
          actived: credentials.actived,
          context: credentials.context,
          desactived:
            deleteAllWithSameProperties<CredentialType.CredentialFromFront>(
              credentials.desactived,
              value,
            ),
        });
      },
      [credentials],
    );

    const cardUnload = useCallback(
      (value: CredentialType.CredentialFromFront) => {
        submitCredentials({
          actived: credentials.actived,
          context: credentials.context,
          desactived: [...credentials.desactived, value],
        });
      },
      [credentials],
    );

    useEffect(() => {
      if (lang && !motorist.lang) {
        submitMotorist({ ...motorist, lang });
      }
    }, [motorist, lang]);

    useEffect(() => {
      validate(true);
    }, [motorist, credentials]);

    return (
      <Col config={{ height: 'full', scrollY: true }}>
        <Row>
          <Col>
            {search ? (
              <Widget config={{ shadow: false }}>
                <AutoComplete
                  handleEvent={{
                    clear: () => {
                      submitCredentials({
                        actived: [],
                        desactived: [],
                        context: 'wizardNewMotorist',
                      });
                      submitMotorist(DefaultMotoristWizard({ t, lang }));
                    },
                    select: (value) => {
                      search.selectMotorist(value);
                    },
                    search: (value) => {
                      search.submitSearchMotoristValue(value);
                    },
                    input: (value) => {
                      value &&
                        (value.length >= 3
                          ? search.searchMotorist?.(value)
                          : search.clearSearchMotorist?.());

                      if (credentials.context === 'wizardMotoristExist') {
                        submitCredentials({
                          actived: [],
                          desactived: [],
                          context: 'wizardNewMotorist',
                        });

                        submitMotorist(DefaultMotoristWizard({ t, lang }));
                      }
                    },
                  }}
                  data={{
                    items: search.currentSearchMotoristItems,
                    value: search.currentSearchMotoristValue,
                  }}
                  config={{
                    search: {
                      accentSensitive: false,
                      caseSensitive: false,
                      pattern: 'everywhere',
                    },
                    height: 'medium',
                    width: 'full',
                    placeholder: t('searchAExistingMotorist'),
                    design: 'search',
                  }}
                />
              </Widget>
            ) : (
              <></>
            )}
          </Col>
          <Col>
            <></>
          </Col>
        </Row>
        <Row>
          <Col config={{ width: 'full' }}>
            <Col config={{ width: 'full' }}>
              <Widget config={{ title: t('identity'), shadow: false }}>
                <Form.Item
                  data={{ defaultValue: motorist.username }}
                  config={{
                    name: 'username',
                    label: t('email'),
                    rules: [{ required: true }, { validator: isValidEmail }],
                    way: 'vertical',
                  }}
                >
                  <Input
                    config={{
                      width: 'full',
                      type: { name: 'email' },
                      disabled: credentials.context === 'wizardMotoristExist',
                    }}
                  />
                </Form.Item>
                <Space config={{ count: 1.5 }} />
                <Row>
                  <Form.Item
                    data={{
                      defaultValue: motorist.firstName,
                    }}
                    config={{
                      name: 'firstName',
                      label: t('firstName'),
                      rules: [{ required: true }],
                      way: 'vertical',
                    }}
                  >
                    <Input
                      config={{
                        width: 'full',
                        disabled: credentials.context === 'wizardMotoristExist',
                      }}
                    />
                  </Form.Item>
                  <Space config={{ way: 'horizontal', count: 1.5 }} />
                  <Form.Item
                    data={{
                      defaultValue: motorist.lastName,
                    }}
                    config={{
                      name: 'lastName',
                      label: t('lastName'),
                      rules: [{ required: true }],
                      way: 'vertical',
                    }}
                  >
                    <Input
                      config={{
                        width: 'full',
                        disabled: credentials.context === 'wizardMotoristExist',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Space config={{ count: 1.5 }} />
                <Form.Item
                  data={{ defaultValue: motorist.lang }}
                  config={{
                    name: 'lang',
                    label: t('language'),
                    way: 'vertical',
                  }}
                >
                  <Select
                    data={{ items: lang ? languages[lang] : [] }}
                    config={{
                      width: 'full',
                      disabled: credentials.context === 'wizardMotoristExist',
                    }}
                  />
                </Form.Item>

                <Space config={{ count: 1.5 }} />
              </Widget>
            </Col>

            <Col config={{ width: 'full' }}>
              <Widget config={{ title: t('address'), shadow: false }}>
                <Form.Item
                  data={{ defaultValue: motorist.address1 }}
                  config={{
                    name: 'address1',
                    label: t('address1'),
                    way: 'vertical',
                  }}
                >
                  <Input
                    config={{
                      width: 'full',
                      disabled: credentials.context === 'wizardMotoristExist',
                    }}
                  />
                </Form.Item>

                <Space config={{ count: 1.5 }} />

                <Form.Item
                  data={{ defaultValue: motorist.address2 }}
                  config={{
                    name: 'address2',
                    label: t('address2'),
                    way: 'vertical',
                  }}
                >
                  <Input
                    config={{
                      width: 'full',
                      disabled: credentials.context === 'wizardMotoristExist',
                    }}
                  />
                </Form.Item>

                <Space config={{ count: 1.5 }} />
                <Row>
                  <Form.Item
                    data={{ defaultValue: motorist.zipcode }}
                    config={{
                      name: 'zipcode',
                      label: t('zipCode'),
                      way: 'vertical',
                    }}
                  >
                    <Input
                      config={{
                        width: 'small',
                        disabled: credentials.context === 'wizardMotoristExist',
                      }}
                    />
                  </Form.Item>
                  <Space config={{ way: 'horizontal', count: 1.5 }} />
                  <Form.Item
                    data={{ defaultValue: motorist.city }}
                    config={{
                      name: 'city',
                      label: t('city'),
                      way: 'vertical',
                    }}
                  >
                    <Input
                      config={{
                        width: 'full',
                        disabled: credentials.context === 'wizardMotoristExist',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Space config={{ count: 1.5 }} />

                <Form.Item
                  data={{
                    defaultValue: motorist.country,
                  }}
                  config={{
                    name: 'country',
                    label: t('country'),
                    way: 'vertical',
                  }}
                >
                  <Select
                    data={{
                      items: lang
                        ? countries[lang].map(({ label, value }) => ({
                            label,
                            value: value.ISO3,
                          }))
                        : [],
                    }}
                    config={{
                      width: 'full',
                      disabled: credentials.context === 'wizardMotoristExist',
                    }}
                  />
                </Form.Item>
              </Widget>
            </Col>
          </Col>
          <Col>
            <Zone
              config={{
                zones: [['configuration'], ['credentials']],
                rows: ['min-content', '1fr'],
                columns: ['1fr'],
              }}
            >
              <Zone.Area config={{ area: 'configuration' }}>
                <Widget config={{ title: t('configuration'), shadow: false }}>
                  <Form.Item
                    data={{ defaultValue: motorist.invitationMotorist }}
                    config={{
                      name: 'invitationMotorist',
                      label: t('sendInvitationEmailToMotorist'),
                      way: 'horizontal',
                    }}
                  >
                    <Checkbox
                      config={{
                        size: 'xlarge',
                        disabled: motorist.status === 'VERIFIED',
                      }}
                    />
                  </Form.Item>
                </Widget>
              </Zone.Area>

              <Zone.Area config={{ area: 'credentials' }}>
                <Widget config={{ title: t('credentials'), shadow: false }}>
                  <Col
                    config={{
                      horizontal: 'start',
                      vertical: 'start',
                      scrollY: true,
                      height: 'full',
                    }}
                  >
                    <CredentialCard.Group
                      handleEvent={{
                        event: ({ event, newValue }) => {
                          return new Promise((resolve, reject) => {
                            if (event === 'create') {
                              cardCreate(
                                newValue as CredentialType.CredentialFromFront,
                              );

                              resolve('');
                            }

                            reject('UNKONW_ERROR');
                          });
                        },
                      }}
                      config={{
                        place: credentials.context,
                        vertical: 'start',
                        credentials: credentialsEdit,
                      }}
                    >
                      {credentials.actived
                        .sort(globalSortCredentialsFn)
                        .map(
                          (
                            credential: CredentialType.CredentialFromFront,
                            index: number,
                          ) => (
                            <div key={index}>
                              {credential.name === 'QRCODE' ? (
                                <QRCODECredentialCard
                                  handleEvent={{
                                    event: async (value) => {
                                      await downloadQrCode({
                                        motoristId: motorist._id,
                                        host: config.BFF_OSP_MOTORISTS_HOST!,
                                        token: config.BFF_OSP_MOTORIST_TOKEN!,
                                      });
                                    },
                                  }}
                                  data={{
                                    generatedAt: credential.generatedAt,
                                    value: credential.value,
                                  }}
                                />
                              ) : credential.name === 'TCS' ? (
                                <TCSCredentialCard
                                  handleEvent={{
                                    event: ({ event, name, primaryValue }) => {
                                      switch (event) {
                                        case 'import':
                                          cardImport({
                                            name,
                                            externalId:
                                              credential.externalId ?? null,
                                          } as CredentialType.CredentialFromFront);

                                          break;

                                        case 'unload':
                                          cardUnload({
                                            name,
                                            externalId:
                                              credential.externalId ?? null,
                                          } as CredentialType.CredentialFromFront);

                                          break;
                                      }
                                    },
                                  }}
                                  config={{
                                    isActive: cardIsActived({
                                      name: credential.name,
                                      externalId: credential.externalId ?? null,
                                    }),
                                  }}
                                  data={{
                                    externalId: credential.externalId,
                                  }}
                                />
                              ) : credential.name === 'RFID' ? (
                                <RFIDCredentialCard
                                  config={{
                                    isActive: cardIsActived({
                                      name: credential.name,
                                      uid: credential.uid ?? null,
                                      visibleId: credential.visibleId ?? null,
                                    }),
                                  }}
                                  handleEvent={{
                                    event: ({ event, name, primaryValue }) => {
                                      switch (event) {
                                        case 'delete':
                                          cardDelete({
                                            name,
                                            [RFIDCredentialStatic.info
                                              .primaryValue]:
                                              primaryValue ?? null,
                                          } as CredentialType.CredentialFromFront);

                                          break;

                                        case 'import':
                                          cardImport({
                                            name,
                                            uid: credential.uid ?? null,
                                            visibleId:
                                              credential.visibleId ?? null,
                                          } as CredentialType.CredentialFromFront);

                                          break;

                                        case 'unload':
                                          cardUnload({
                                            name,
                                            uid: credential.uid ?? null,
                                            visibleId:
                                              credential.visibleId ?? null,
                                          } as CredentialType.CredentialFromFront);

                                          break;
                                      }
                                    },
                                  }}
                                  data={{
                                    uid: credential.uid,
                                    visibleId: credential.visibleId,
                                  }}
                                />
                              ) : (
                                <PLATECredentialCard
                                  config={{
                                    isActive: cardIsActived({
                                      name: credential.name,
                                      description:
                                        credential.description ?? null,
                                      plate: credential.plate ?? null,
                                    }),
                                  }}
                                  handleEvent={{
                                    event: ({ event, name, primaryValue }) => {
                                      switch (event) {
                                        case 'delete':
                                          cardDelete({
                                            name: credential.name,
                                            [PLATECredentialStatic.info
                                              .primaryValue]:
                                              primaryValue ?? null,
                                          } as CredentialType.CredentialFromFront);

                                          break;

                                        case 'import':
                                          cardImport({
                                            name: credential.name,
                                            description:
                                              credential.description ?? null,
                                            plate: credential.plate ?? null,
                                          } as CredentialType.CredentialFromFront);

                                          break;

                                        case 'unload':
                                          cardUnload({
                                            name: credential.name,
                                            description:
                                              credential.description ?? null,
                                            plate: credential.plate ?? null,
                                          } as CredentialType.CredentialFromFront);

                                          break;
                                      }
                                    },
                                  }}
                                  data={{
                                    description: credential.description,
                                    plate: credential.plate,
                                  }}
                                />
                              )}
                            </div>
                          ),
                        )}
                    </CredentialCard.Group>
                  </Col>
                </Widget>
              </Zone.Area>
            </Zone>
          </Col>
        </Row>
      </Col>
    );
  },
);
