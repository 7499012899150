import { useMutation, useRootStore, useTranslation } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import {
  Mutation,
  MutationSendConfirmationMailArgs,
  MutationStopItArgs,
  MutationSwitchApbNextArgs,
  MutationUpdateContractArgs,
} from '../../client/graphql';
import { sendConfirmaionMail } from '../../common/gql/send-confirmation-mail.gql';
import { stopItGql } from '../../common/gql/stop-it.gql';
import { switchApbNextGql } from '../../common/gql/switch-apb-next.gql';
import { updateContractGql } from '../../common/gql/update-contract.gql';
import {
  ActionInline,
  ActionInlineType,
} from '../../components/action-inline/action-inline.component';
import { InlineActionChangeEvent } from './pool-contract.action';

export declare namespace MotoristContractActionType {
  type Props = {
    initialState?: MotContractActionState;
    onChange?: (value: MotContractActionState) => void;
    config: { size: ActionInlineType.Config.Size };
  };
}

export type MotContractActionState = {
  id?: string;
  status?: boolean;
  parkingId?: string;
  APBNext?: boolean;
  isStopped?: boolean;
  freeflag?: boolean | null;
  motoristId?: string;
  category?: string;
};

export const MotoristContractAction = observer(
  ({ initialState, config: { size } }: MotoristContractActionType.Props) => {
    const { t } = useTranslation();
    const { isStopped, id, parkingId, motoristId, APBNext, status, category } =
      initialState || {};
    const [apb, setApb] = useState<boolean>(APBNext || false);

    const [stopItCall] = useMutation<
      { stopIt: Mutation['stopIt'] },
      MutationStopItArgs
    >(stopItGql);

    const [sendConfirmationMailCall] = useMutation<
      { sendConfirmationMail: Mutation['sendConfirmationMail'] },
      MutationSendConfirmationMailArgs
    >(sendConfirmaionMail, {
      socket: {
        signal: (data) => false,
      },
    });

    const [updateContractCall] = useMutation<
      { updateContract: Mutation['updateContract'] },
      MutationUpdateContractArgs
    >(updateContractGql);

    const [switchAPBNextCall] = useMutation<
      { switchAPBNext: Mutation['switchAPBNext'] },
      MutationSwitchApbNextArgs
    >(switchApbNextGql);

    const handleUpdate = async ({
      value,
      type,
      key,
    }: InlineActionChangeEvent) => {
      if (type && id) {
        switch (key) {
          case 'stopContract':
            try {
              if (!id) throw new Error('Contract ID is required');

              await stopItCall({
                variables: { id },
              });
            } catch (error) {
              console.error(error);
            }

            break;

          case 'APBNext':
            try {
              if (!id || !parkingId) throw new Error('Contract ID is required');

              const mutation = await switchAPBNextCall({
                variables: { contractId: id, parkingId },
              });

              setApb(!mutation?.data);
            } catch (error) {
              console.error(error);
            }
            break;

          case 'sendInvitation':
            try {
              if (!id || !motoristId)
                throw new Error('Contract ID and Motorist ID are required');

              await sendConfirmationMailCall({
                variables: {
                  input: { contractId: id, motoristId },
                },
              });
            } catch (error) {
              console.error(error);
            }

            break;

          case 'status':
            try {
              if (!id) throw new Error('Contract ID is required');

              await updateContractCall({
                variables: { contractId: id, input: { isEnabled: value } },
              });
            } catch (error) {
              console.error(error);
            }

            break;
        }
      }
    };

    const getActions = (
      isStopped?: boolean,
      id?: string,
      status?: boolean,
    ): ActionInlineType.Data[] => {
      let actions: ActionInlineType.Data[] = [
        {
          key: 'status',
          text: t('contractStatus'),
          color: 'label',
          event: handleUpdate,
          switch: {
            isActive: initialState!!.status || false,
            text: { active: t('active'), inactive: t('suspended') },
            color: { active: 'success', inactive: 'warn' },
          },
        },
      ];

      if (parkingId && !isStopped && status) {
        actions = [
          ...actions,
          {
            key: 'APBNext',
            text: t('APBNext'),
            color: 'label',
            event: handleUpdate,
            switch: {
              isActive: apb,
              text: { active: t('active'), inactive: t('suspended') },
              color: { active: 'success', inactive: 'warn' },
            },
          },
        ];
      }

      if (
        id &&
        !isStopped &&
        ((category && category === 'MOTORIST_POOL') || !category)
      ) {
        actions = [
          ...actions,
          {
            key: 'stopContract',
            text: t('stopContract'),
            color: 'error',
            isCritical: true,
            event: handleUpdate,
          },
        ];
      }

      if (motoristId) {
        actions = [
          ...actions,
          {
            key: 'sendInvitation',
            text: t('sendInvitation'),
            color: 'label',
            event: handleUpdate,
          },
        ];
      }

      return actions;
    };

    return (
      <ActionInline
        config={{ size }}
        data={getActions(isStopped, id, status)}
      />
    );
  },
);
