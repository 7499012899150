import { AntiPassBackMode } from '../../client/graphql';

export const APBModeDetectWithCycleControl = (
  cycleControl:
    | { pastDaysCount: number; maxOpenedSessions: number }
    | {}
    | null,
): AntiPassBackMode => {
  if (!cycleControl || !('pastDaysCount' in cycleControl)) {
    return AntiPassBackMode.None;
  } else if (cycleControl.pastDaysCount === 1) {
    return AntiPassBackMode.Soft;
  } else {
    return AntiPassBackMode.Hard;
  }
};
