import { gql } from '@apollo/client';

export const createBreakdownGQL = gql`
  mutation createBreakdown($input: CreateBreakdownDto!) {
    createBreakdown(input: $input) {
      _id
      startDate
      endDate
      category
      description
      type
      createdAt
      updatedAt
    }
  }
`;
