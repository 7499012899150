import {
  Empty,
  Widget,
  useLazyQuery,
  useTranslation,
} from '@gimlite/watermelon';
import { useEffect, useMemo } from 'react';
import { Query, QueryContractArgs } from '../../client/graphql';
import { contractGql } from '../../common/gql/contract.gql';
import { MasterPoolContractDetails } from './master-pool-contract.details';
import { MotoristContractDetails } from './motorist-contract.details';
import { TenantPoolContractDetails } from './tenant-pool-contract.details';
import { observer } from 'mobx-react-lite';
export type ContractDetailsProps = {
  parkingId: string;
  contractId?: string;
};

export const ContractDetails = observer(
  ({ parkingId, contractId }: ContractDetailsProps) => {
    const { t, lang } = useTranslation();

    const [contractCall, contractState] = useLazyQuery<
      {
        contract: Query['contract'];
      },
      QueryContractArgs
    >(contractGql, {
      variables: {
        id: contractId || '',
      },
      socket: {
        'contracts:updated': (data) => {
          return [
            data?.contract?.linkedContractId,
            data?.contracts?.map((event: any) => event?._id),
            data?.motorist?.contractIds,
            data?.contract?._id,
          ]
            .flat()
            .filter(Boolean)
            .includes(contractId);
        },
        'motorists:updated': (data) => {
          return [
            data?.contract?.linkedContractId,
            data?.contracts?.map((event: any) => event?._id),
            data?.motorist?.contractIds,
            data?.contract?._id,
          ]
            .flat()
            .filter(Boolean)
            .includes(contractId);
        },
      },
    });

    const contracts = useMemo(() => {
      if (!contractState.data?.contract) return null;

      return contractState.data.contract;
    }, [contractState.data]);

    const emptyFormatted = useMemo(() => {
      return (
        <Widget config={{ title: t('detailsOfContract'), backtitle: true }}>
          <Empty
            config={{
              mode: { name: 'select', text: `${t('selectContract')} ...` },
            }}
          ></Empty>
        </Widget>
      );
    }, [lang]);

    useEffect(() => {
      if (contractId) {
        contractCall({ variables: { id: contractId } });
      } else {
        contractState.resetData();
      }
    }, [contractId]);

    if (!contractId) return emptyFormatted;

    switch (contracts?.category) {
      case 'MOTORIST_POOL':
      case 'PERIOD_SUBSCRIPTION':
        return (
          <MotoristContractDetails
            widget={{
              title: t('detailsOfMotoristContract'),
              state: {
                loading: contractState.loading,
                error: contractState.error,
                refetch: contractState.refetch,
                showPermanentRefetch: false,
              },
            }}
            contract={contracts}
            parkingId={parkingId}
          />
        );

      case 'TENANT_POOL':
        return (
          <TenantPoolContractDetails
            widget={{
              title: t('detailsOfSubPoolContract'),
              state: {
                loading: contractState.loading,
                error: contractState.error,
                refetch: contractState.refetch,
                showPermanentRefetch: false,
              },
            }}
            contract={contracts}
          />
        );

      case 'OPERATOR_POOL':
        return (
          <MasterPoolContractDetails
            widget={{
              title: t('detailsOfMasterPoolContract'),
              state: {
                loading: contractState.loading,
                error: contractState.error,
                refetch: contractState.refetch,
                showPermanentRefetch: false,
              },
            }}
            contract={contracts}
          />
        );

      default:
        return emptyFormatted;
    }
  },
);
