import { useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import {
  AntiPassBackMode,
  LocalMotoristsEntity,
  PoolContractEntity,
} from '../../client/graphql';
import { CredentialType } from '../../common/mapper/credential.mapper';
import { MasterContractStep } from '../../common/steps/masterContract/masterContract.step';
import { SubContractStep } from '../../common/steps/subContract/subContract.step';
import { portal } from '../../config';
import { ContractCreateFormType } from '../../form/contract-create/contract-create.form';
import { MotoristsCreateFormType } from '../../form/motorists-create/motorists-create.form';
import { ConfirmationStep } from './steps/confirmation/confirmation.step';
import { ContractStep } from './steps/contract/contract.step';
import { MotoristsStep } from './steps/motorists/motorists.step';
import { SummaryStep } from './steps/summary/summary.step';

export type AddSeveralMotoristsToAnExistingPoolContractWizardContext = {
  parkingId: string | null;
  masterPoolContractId: string | null;
  product: {
    id: string | null;
    credentials: CredentialType.GlobalName[];
  };
  subPoolContractId: string | null;
  parentContract: ContractCreateFormType.Data.ContractPool | null;
  contract: ContractCreateFormType.Data.ContractMotorist;
  motorists: {
    created: MotoristsCreateFormType.Data.Created[];
    checked: LocalMotoristsEntity[];
  };
  motoristMode: 'create' | 'exist';
  motoristExistedSave: LocalMotoristsEntity[];
  subPoolExistedSave: PoolContractEntity[];
  masterPoolExistedSave: PoolContractEntity[];

  mode: typeof portal;
  skipSubContract: (skip: boolean) => void;
};

export const AddSeveralMotoristsToAnExistingPoolContractWizard = observer(
  () => {
    const { t, lang } = useTranslation();
    const { WizardStore } = useRootStore();

    const { parkingId, skipSubContract, mode } = WizardStore?.selected?.store;

    return (
      <Wizard<AddSeveralMotoristsToAnExistingPoolContractWizardContext>
        config={{
          title: t(`addSeveralMotoristsToAnExistingPoolContract`),
        }}
        data={{
          defaultContext: {
            parkingId,
            product: {
              id: null,
              credentials: [],
            },
            masterPoolContractId: null,
            subPoolContractId: null,
            parentContract: null,
            contract: {
              startDate: new Date().toISOString(),
              endDate: null,
              APBMode: AntiPassBackMode.Hard,
              APBNext: false,
              reference: null,
              description: null,
              state: true,
            },
            motorists: {
              checked: [],
              created: [],
            },
            motoristMode: 'exist',
            motoristExistedSave: [],
            subPoolExistedSave: [],
            masterPoolExistedSave: [],
            mode,
            skipSubContract: (skip) => {
              WizardStore.updateStore({
                skipSubContract: skip,
              });
            },
          },
        }}
        handleEvent={{
          done: async (data: any) => {
            console.log('done', { data });
          },
          clear: () => {
            console.log('CLEAR');
          },
          close: () => {
            WizardStore.clear();
          },
        }}
      >
        <Wizard.Item
          config={{ title: t('masterContract'), component: MasterContractStep }}
        />
        {!skipSubContract && (
          <Wizard.Item
            config={{ title: t('subContract'), component: SubContractStep }}
          />
        )}
        <Wizard.Item
          config={{ title: t('contract'), component: ContractStep }}
        />
        <Wizard.Item
          config={{ title: t('motorists'), component: MotoristsStep }}
        />
        <Wizard.Item
          config={{ title: t('confirmation'), component: ConfirmationStep }}
        />
        <Wizard.Item config={{ title: t('summary'), component: SummaryStep }} />
      </Wizard>
    );
  },
);
