import { useMutation, useRootStore, useTranslation } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import {
  Mutation,
  MutationStopItArgs,
  MutationUpdateContractArgs,
} from '../../client/graphql';
import { stopItGql } from '../../common/gql/stop-it.gql';
import { updateContractGql } from '../../common/gql/update-contract.gql';
import {
  ActionInline,
  ActionInlineType,
} from '../../components/action-inline/action-inline.component';

export type InlineActionChangeEvent = {
  key: string;
  type: string;
  value?: boolean;
};

export declare namespace PoolContractActionType {
  type Props = {
    initialState?: Partial<PoolContractActionState>;
    onChange?: (value: PoolContractActionState) => void;
    config: {
      size: ActionInlineType.Config.Size;
    };
  };
}

export type PoolContractActionState = {
  id?: string;
  parkingId?: string;
  isStopped?: boolean;
  status?: boolean;
  stopContract?: boolean;
};

export const PoolContractAction = observer(
  ({ initialState, config: { size } }: PoolContractActionType.Props) => {
    const { t } = useTranslation();
    const { isStopped, id, status } = initialState || {
      isStopped: false,
    };
    const [stopItCall] = useMutation<
      { stopIt: Mutation['stopIt'] },
      MutationStopItArgs
    >(stopItGql);

    const [updateContractCall] = useMutation<
      { updateContract: Mutation['updateContract'] },
      MutationUpdateContractArgs
    >(updateContractGql);

    const handleUpdate = async ({
      value,
      type,
      key,
    }: InlineActionChangeEvent) => {
      if (type && id) {
        switch (key) {
          case 'stopContract':
            try {
              if (!id) throw new Error('Contract ID is required');

              await stopItCall({
                variables: { id },
              });
            } catch (error) {
              console.error(error);
            }

            break;

          case 'status':
            if (id) {
              try {
                await updateContractCall({
                  variables: {
                    contractId: id,
                    input: { isEnabled: value },
                  },
                });
              } catch (error) {
                console.error(error);
              }
            }
            break;
        }
      }
    };

    const getActions = (
      isStopped?: boolean,
      id?: string,
      status?: boolean,
    ): ActionInlineType.Data[] => {
      let actions: ActionInlineType.Data[] = [
        {
          key: 'status',
          text: t('contractStatus'),
          color: 'label',
          event: handleUpdate,
          switch: {
            isActive: initialState!!.status || false,
            text: { active: t('active'), inactive: t('suspended') },
            color: { active: 'success', inactive: 'warn' },
          },
        },
      ];

      if (!isStopped && id) {
        actions = [
          ...actions,
          {
            key: 'stopContract',
            text: t('stopContract'),
            color: 'error',
            event: handleUpdate,
            isCritical: true,
          },
        ];
      }

      return actions;
    };

    return (
      <ActionInline
        config={{ size }}
        data={getActions(isStopped, id, status)}
      />
    );
  },
);
