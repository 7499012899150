import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationForm } from '../../../../form/confirmation/confirmation.form';
import { AddSeveralMotoristsToAnExistingPoolContractWizardContext } from '../../addSeveralMotoristsToAnExistingPoolContract.wizard';
import { MotoristLine } from '../../../../composition/line/motorist.line';
import { ContractMotoristLine } from '../../../../composition/line/contract-motorist.line';
import { ContractWizardInfos } from '../../../../composition/wizard-infos/contract.wizard-infos';

export const ConfirmationStep = ({
  context: {
    contract,
    motorists,
    masterPoolContractId,
    subPoolContractId,
    motoristMode,
  },
  validate,
  submit,
}: WizardItemType.Config.Component<AddSeveralMotoristsToAnExistingPoolContractWizardContext>) => {
  const { t, lang } = useTranslation();

  const [allFeatures, setAllFeatures] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const features: JSX.Element[] = [];

    if (motoristMode === 'create') {
      motorists.created.forEach(({ email, firstName, lastName }) => {
        features.push(
          <MotoristLine
            data={{
              firstName,
              lastName,
              status: 'UNVERIFIED',
              email,
            }}
            config={{ icon: true, action: false }}
          />,
        );

        features.push(
          <ContractMotoristLine
            config={{
              action: false,
            }}
            data={{
              firstName,
              lastName,
              status: contract.state ? 'ACTIVE' : 'SUSPENDED',
              contractId: t('willBeGenerated'),
            }}
          />,
        );
      });
    } else {
      motorists.checked.forEach(({ firstName, lastName }) => {
        features.push(
          <ContractMotoristLine
            config={{
              action: false,
            }}
            data={{
              firstName: firstName || null,
              lastName: lastName || null,
              status: contract.state ? 'ACTIVE' : 'SUSPENDED',
              contractId: t('willBeGenerated'),
            }}
          />,
        );
      });
    }

    setAllFeatures(features);
  }, [lang]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'list']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ContractWizardInfos
          data={{
            APBMode: contract.APBMode,
            APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
            state: contract.state,
            startDate: contract.startDate,
            endDate: contract.endDate,
            description: contract.description,
            reference: contract.reference,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'list' }}>
        <ConfirmationForm
          data={{
            list: allFeatures.map((node) => {
              return {
                _id: uuidv4(),
                items: {
                  component: node,
                },
              };
            }),
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
