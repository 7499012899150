import { gql } from '@apollo/client';

export const createPoolGQL = gql`
  mutation createPool($input: CreatePoolDto!) {
    createPool(input: $input) {
      _id
      ospContractId
      name1
      name2
      address1
      address2
      zipcode
      city
      country
      createdAt
      defaultPoolId
      isEnabled
    }
  }
`;
