import { Col, Input, Row, Space } from '@gimlite/watermelon';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { ContractWizardInfos } from '../../../../composition/wizard-infos/contract.wizard-infos';
import { CreateNewSubPoolContractContext } from '../../createNewSubPoolContract.wizard';

export const InformationsStep = ({
  context: { contract, informations },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewSubPoolContractContext>) => {
  const { t, lang } = useTranslation();

  return (
    <Form
      data={{ value: informations }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit({ informations: { ...value } });
        },
        validate: (value: boolean) => {
          validate(value);
        },
      }}
    >
      <Zone
        config={{
          gap: {
            y: 1,
            x: 1,
          },
          zones: [['info', 'form']],
          rows: ['1fr'],
          columns: ['min-content', '1fr'],
        }}
      >
        <Zone.Area config={{ area: 'info' }}>
          <ContractWizardInfos
            data={{
              APBMode: contract.APBMode,
              APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
              state: contract.state,
              startDate: contract.startDate,
              endDate: contract.endDate,
              description: contract.description,
              reference: contract.reference,
            }}
          />
        </Zone.Area>

        <Zone.Area config={{ area: 'form' }}>
          <Zone
            config={{
              zones: [['identity', 'empty']],
              rows: ['1fr'],
              columns: ['1fr', '1fr'],
            }}
          >
            <Zone.Area config={{ area: 'identity' }}>
              <Widget config={{ title: t('identity'), shadow: false }}>
                <Col>
                  <Row>
                    <Form.Item
                      data={{ defaultValue: informations.name1 }}
                      config={{
                        name: 'name1',
                        label: t('name1'),
                        rules: [{ required: true }],
                        way: 'vertical',
                      }}
                    >
                      <Input config={{ width: 'full' }} />
                    </Form.Item>
                    <Space config={{ count: 2, way: 'horizontal' }} />
                    <Form.Item
                      data={{ defaultValue: informations.name2 }}
                      config={{
                        name: 'name2',
                        label: t('name2'),
                        way: 'vertical',
                      }}
                    >
                      <Input config={{ width: 'full' }} />
                    </Form.Item>
                  </Row>
                </Col>
              </Widget>
            </Zone.Area>

            <Zone.Area config={{ area: 'empty' }}>
              <div></div>
            </Zone.Area>
          </Zone>
        </Zone.Area>
      </Zone>
    </Form>
  );
};
