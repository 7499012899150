import {
  Button,
  ListFlatInfo,
  ListFlatInfoType,
  Row,
  Widget,
  Write,
  Zone,
  useCSV,
  useTranslation,
} from '@gimlite/watermelon';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Popover } from '@gimlite/watermelon/components/popover/popover.component';
import { LineState } from '../../components/line-state/line-state.component';
import {
  MotoristCSVParams,
  MotoristsCreateFormType,
} from '../motorists-create/motorists-create.form';
import './summary.form.scss';
import { Action } from '../../components/action/action.component';

export declare namespace SummaryFormType {
  type Props = {
    data: {
      list?: SummaryFormType.Data.Line[];
      created?: Array<SummaryFormType.Data.Created>;
    };
    config?: {
      credentialsEdit?: {
        RFID?: boolean;
        PLATE?: boolean;
      };
    };
  };

  namespace Data {
    type Line = {
      _id: ListFlatInfoType.Data.Line<any>['_id'];
      items: {
        component: ListFlatInfoType.Data.Item;
        state:
          | {
              type: 'success';
            }
          | {
              type: 'error';
              message: string;
            };
      };
    };

    type Created = Pick<
      MotoristsCreateFormType.Data.Created,
      'firstName' | 'lastName' | 'plate' | 'uid' | 'email' | 'visibleId'
    > & { state: 'success' | 'error' };
  }

  namespace Config {
    type CredentialsEdit = {
      RFID?: boolean;
      PLATE?: boolean;
    };
  }
}

export const SummaryForm = ({
  data: { list, created = [] },
  config: { credentialsEdit = { RFID: true, PLATE: true } } = {},
}: SummaryFormType.Props) => {
  const { t, lang } = useTranslation();

  const { exportCSV, sortCSV } = useCSV<MotoristsCreateFormType.Extra.CSV>({
    ...MotoristCSVParams(credentialsEdit),
    data: created,
  });

  return (
    <Widget
      config={{
        title: t('statusOfNewReleases'),
        shadow: false,
      }}
    >
      <Zone
        config={{
          gap: {
            y: 1,
            x: 0,
          },
          zones: [['description'], ['details']],
          rows: ['min-content', '1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'description' }}>
          <Row config={{ horizontal: 'spaceBetween', vertical: 'end' }}>
            {list && (
              <Description
                data={[
                  { key: 'total', label: t('total'), value: list.length },
                  {
                    key: 'accepted',
                    label: t('accepted'),
                    value: list.filter(
                      ({ items: { state } }) => state.type === 'success',
                    ).length,
                    color: 'success',
                  },
                  {
                    key: 'rejected',
                    label: t('rejected'),
                    value: list.filter(
                      ({ items: { state } }) => state.type === 'error',
                    ).length,
                    color: 'error',
                  },
                ]}
                config={{ way: 'horizontal' }}
              ></Description>
            )}
            {created && created.length > 0 && (
              <Popover
                data={
                  <Action
                    data={[
                      {
                        key: 'all',
                        text: t('allMotorists'),
                        color: 'label',
                        event: () => {
                          exportCSV(
                            sortCSV(
                              created.map(({ state, ...rest }) => ({
                                ...rest,
                              })),
                            ),
                          );
                        },
                      },
                      {
                        show:
                          created.filter(({ state }) => state === 'error')
                            .length > 0,
                        key: 'errorsOnly',
                        text: t('errorsMotoristsOnly'),
                        color: 'label',
                        event: () => {
                          exportCSV(
                            sortCSV(
                              created
                                .filter(({ state }) => state === 'error')
                                .map(({ state, ...rest }) => ({
                                  ...rest,
                                })),
                            ),
                          );
                        },
                      },
                    ]}
                  />
                }
                config={{ placement: 'bottom', trigger: 'hover' }}
              >
                <Button
                  config={{
                    text: t('export'),
                    icon: 'faChevronDownSolid',
                    size: 'medium',
                  }}
                ></Button>
              </Popover>
            )}
          </Row>
        </Zone.Area>

        <Zone.Area config={{ area: 'details' }}>
          <ListFlatInfo<any>
            data={{
              list: list
                ? list.map(({ _id, items: { component, state } }) => {
                    return {
                      _id,
                      items: {
                        component,
                        state:
                          state.type === 'success' ? (
                            <LineState config={{ state: 'success' }} />
                          ) : (
                            <Popover
                              data={
                                <Write
                                  data={{
                                    item: t(`error-code-${state.message}`),
                                  }}
                                  config={{
                                    color: 'error',
                                    mode: 'value-medium',
                                  }}
                                />
                              }
                            >
                              <LineState config={{ state: 'error' }} />
                            </Popover>
                          ),
                      },
                    };
                  })
                : undefined,
            }}
            config={{
              pagination: 'none',
              columns: [
                { key: 'component', size: '1fr' },
                { key: 'state', size: 'min-content' },
              ],
              border: true,
            }}
          />
        </Zone.Area>
      </Zone>
    </Widget>
  );
};
