import {
  Col,
  CountryEntry,
  FormType,
  Segmented,
  Write,
  Zone,
  countries,
  useMutation,
  useMyUrl,
  useTranslation,
} from '@gimlite/watermelon';
import {
  Widget,
  WidgetGroupType,
} from '@gimlite/watermelon/components/widget/widget.component';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { addCredGql } from '../../common/gql/add-cred.gql';
import { editCredGql } from '../../common/gql/edit-cred.gql';
import { loadCredGql } from '../../common/gql/load-cred.gql';
import { regenQRGql } from '../../common/gql/regen-qr.gql';
import { removeCredGql } from '../../common/gql/remove-cred.gql';
import { updateContractGql } from '../../common/gql/update-contract.gql';
import { APBModeGlobal } from '../../common/mapper/abp.mapper';
import { downloadQrCode } from '../../common/mapper/credential.mapper';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';

import {
  AntiPassBackMode,
  CredentialEntity,
  MotoristContractEntity,
  Mutation,
  MutationAddCredOnContractArgs,
  MutationEditCredOnContractArgs,
  MutationLoadCredOnContractArgs,
  MutationRegenerateQrcodeArgs,
  MutationRemoveCredOnContractArgs,
  MutationUpdateContractArgs,
  ProductRules,
} from '../../client/graphql';
import {
  ContractBadgesType,
  useContractBadges,
} from '../../common/utils/contract-badges.hook';
import { CredentialCard } from '../../components/credential/credential.component';
import {
  ProfilDetails,
  ProfilDetailsType,
} from '../../components/profil-details/profil-details.component';
import { MotoristContractAction } from '../action/motorist-contract.action';
import { PLATECredentialCard } from '../credential/plate.credential';
import { QRCODECredentialCard } from '../credential/qrcode.credential';
import { RFIDCredentialCard } from '../credential/rfid.credential';
import { TCSCredentialCard } from '../credential/tcs.credential';
import { MotoristContractList } from '../list/motorist-contract.list';
import { SessionList } from '../list/session.list';
import { config } from '../../config';
import { ApolloError } from '@apollo/client';

type MotoristContractDetailsProps = {
  parkingId: string;
  contract: MotoristContractEntity;
  widget?: {
    title?: string;
    state: WidgetGroupType.Props['state'];
  };
};

export function rules2APB({ cycleControl }: ProductRules): AntiPassBackMode {
  if (
    !cycleControl ||
    !cycleControl.pastDaysCount ||
    !cycleControl.maxOpenedSessions
  )
    return AntiPassBackMode.None;

  const { pastDaysCount, maxOpenedSessions } = cycleControl || {};
  if (pastDaysCount === 1 && maxOpenedSessions === 100)
    return AntiPassBackMode.Soft;
  if (pastDaysCount === 7 && maxOpenedSessions === 1)
    return AntiPassBackMode.Hard;
  return AntiPassBackMode.None;
}

export const MotoristContractDetails = observer(
  ({ widget, parkingId, contract }: MotoristContractDetailsProps) => {
    const { motorist, isEnabled, credentials, freeflag } = contract;
    const { t, lang } = useTranslation();
    const [segmented, setSegmented] = useState<string>('credentials');
    const { setParamsUrl } = useMyUrl({});

    const [updateContractCall] = useMutation<
      { updateContract: Mutation['updateContract'] },
      MutationUpdateContractArgs
    >(updateContractGql);

    const [removeCredOnContractCall] = useMutation<
      { removeCredOnContract: Mutation['removeCredOnContract'] },
      MutationRemoveCredOnContractArgs
    >(removeCredGql);

    const [loadCredOnContractCall] = useMutation<
      { loadCredOnContract: Mutation['loadCredOnContract'] },
      MutationLoadCredOnContractArgs
    >(loadCredGql);

    const [regenerateQrcodeCall] = useMutation<
      { regenerateQrcode: Mutation['regenerateQrcode'] },
      MutationRegenerateQrcodeArgs
    >(regenQRGql, {
      notification: {
        success: t('theQrcodeHasBeenRegenerated'),
        error: (error) =>
          error instanceof ApolloError
            ? t(`error-code-${error.message!}`)
            : `${error}`,
      },
    });

    const [addCredOnContractCall] = useMutation<
      { addCredOnContract: Mutation['addCredOnContract'] },
      MutationAddCredOnContractArgs
    >(addCredGql, {
      notification: {
        error: t('theCredentialAlreadyExists'),
      },
    });

    const [editCredOnContractCall] = useMutation<
      { editCredOnContract: Mutation['editCredOnContract'] },
      MutationEditCredOnContractArgs
    >(editCredGql, {
      notification: {
        error: (e) => t(`error-code-${e.message}`),
      },
    });

    const credentialsTypes = contract?.product?.rules?.credentialsTypes
      ? contract.product.rules.credentialsTypes
      : null;

    const extractFormData = useCallback(
      (contract: MotoristContractEntity): FormType.Data.Value => {
        const {
          motorist,
          ospContractId,
          presenceCounter,
          startDate,
          stopDate,
          freeflag,
          rules,
          description,
          reference,
          productId,
        } = contract;

        const {
          firstName,
          lastName,
          address1,
          address2,
          zipcode,
          city,
          country,
        } = motorist!!;
        const freeFlagIsDefined = freeflag === null || freeflag === undefined;
        return {
          firstName,
          lastName,
          code: ospContractId,
          reference: reference,
          streetName: address1,
          streetNumber: address2,
          zipCode: zipcode,
          noOfSpace: [`${presenceCounter || 0}`, `/1`],
          city,
          country,
          APBMode: rules ? rules2APB(rules!!) : 'NONE',
          APBNext: freeFlagIsDefined ? t('active') : t('suspended'),
          startDate,
          endDate: stopDate,
          description,
          isEnabled: contract.isEnabled,
          product: t(`product-${productId}-name`),
        };
      },
      [lang],
    );

    const [form, setForm] = useState<FormType.Data.Value>({});

    const [data, setData] = useState<MotoristContractEntity>(contract);
    const countryOptions = useMemo(() => {
      if (!countries || !lang || !countries[lang]) return [];

      return countries[lang].map(({ value, label }: CountryEntry) => ({
        label,
        value: value.ISO3,
      }));
    }, [countries, lang]);

    useEffect(() => {
      setSegmented('credentials');
    }, [contract]);

    useEffect(() => {
      setForm(extractFormData(contract));
    }, [lang, contract]);

    const handleSubmit = ({
      firstName,
      lastName,
      startDate,
      endDate,
      streetName,
      streetNumber,
      noOfSpace,
      city,
      country,
      zipCode,
      APBMode,
      description,
      reference,
    }: FormType.Data.Value) => {
      const updatedContract = {
        name1: firstName || '',
        name2: lastName || '',
        startDate,
        stopDate: endDate,
        address: {
          address1: streetName,
          address2: streetNumber,
          city,
          country,
          zipcode: zipCode,
        },
        apbMode: APBMode,
        description,
        reference,
        presenceCounter: Number(noOfSpace[0]),
      } as Partial<MotoristContractEntity>;

      updateContract(updatedContract);
    };

    const updateContract = async (
      updatedContract: Partial<MotoristContractEntity>,
    ) => {
      if (data.isEnabled === updatedContract.isEnabled) return;

      setParamsUrl({ clicked: undefined });

      updateContractCall({
        variables: {
          contractId: contract._id,
          input: updatedContract,
        },
      }).then(({ data }) => {
        setData({ ...contract, ...data });
      });
    };

    const unloadCred = async (value: string, contractId: string) => {
      await removeCredOnContractCall({
        variables: { contractId, value },
      });
    };

    const loadCred = async (
      credential: CredentialEntity,
      contractId: string,
    ) => {
      await loadCredOnContractCall({
        variables: { contractId, credential },
      });
    };

    // console.log({ credentials, credentialsTypes });

    // console.log('MERDIA,', [
    //   ...credentials!!,
    //   ...contract.motorist?.credentials.filter(
    //     ({ type, value }) =>
    //       type !== 'RFID' &&
    //       !credentials?.find((c) => c.value === value) &&
    //       !!credentialsTypes?.types?.find((t) => t.type === type),
    //   )!!,
    // ]);

    const credentialsRender = () =>
      [
        ...credentials!!.filter(
          ({ type, value, provider }) =>
            !!credentialsTypes?.types?.find((t) => {
              return t.type === 'PROVIDER_EXTERNAL_ID' && provider === 'OSP'
                ? false
                : t.type === 'PROVIDER_EXTERNAL_ID'
                  ? t.type === type &&
                    (t.provider || null) === (provider || null)
                  : t.type === type;
            }),
        )!!,
        ...contract.motorist?.credentials.filter(
          ({ type, value, provider }) =>
            !credentials?.find((c) => c.value === value) &&
            !!credentialsTypes?.types?.find((t) => {
              return t.type === 'PROVIDER_EXTERNAL_ID' && provider === 'OSP'
                ? false
                : t.type === 'PROVIDER_EXTERNAL_ID'
                  ? t.type === type &&
                    (t.provider || null) === (provider || null)
                  : t.type === type;
            }),
        )!!,
      ]?.map(({ type, value, provider, description }) => {
        switch (type) {
          case 'PLATE':
            return (
              <PLATECredentialCard
                handleEvent={{
                  event: async ({ event, primaryValue }) => {
                    if (event === 'unload') {
                      unloadCred(primaryValue!!, contract._id);
                    }
                    if (event === 'import') {
                      loadCred(
                        { type, value, provider, description },
                        contract._id,
                      );
                    }
                  },
                }}
                data={{ description: description || '', plate: value }}
                config={{
                  isActive: !!credentials?.find((c) => c.value === value),
                }}
              />
            );

          case 'RFID':
            return (
              <RFIDCredentialCard
                handleEvent={{
                  event: async ({ event, primaryValue }) => {
                    if (event === 'unload') {
                      unloadCred(primaryValue!!, contract._id);
                    }
                    if (event === 'import') {
                      loadCred(
                        { type, value, provider, description },
                        contract._id,
                      );
                    }
                  },
                }}
                data={{ uid: value, visibleId: description || '' }}
                config={{
                  isActive: !!credentials?.find((c) => c.value === value),
                }}
              />
            );

          case 'QRCODE':
            return (
              <QRCODECredentialCard
                handleEvent={{
                  event: async ({ event, name }) => {
                    if (
                      event === 're-generate' &&
                      name === 'QRCODE' &&
                      motorist?._id
                    ) {
                      await regenerateQrcodeCall({
                        variables: { motoristId: motorist._id },
                      });
                    } else if (event === 'download') {
                      await downloadQrCode({
                        motoristId: motorist?._id!,
                        host: config.BFF_OSP_MOTORISTS_HOST!,
                        token: config.BFF_OSP_MOTORIST_TOKEN!,
                      });
                    }
                  },
                }}
                data={{
                  generatedAt: '',
                  value: value,
                }}
              />
            );

          case 'PROVIDER_EXTERNAL_ID':
            switch (provider) {
              case 'OSP':
                return (
                  <RFIDCredentialCard
                    handleEvent={{
                      event: async ({ event, primaryValue }) => {
                        if (event === 'unload') {
                          unloadCred(primaryValue!!, contract._id);
                        }
                        if (event === 'import') {
                          loadCred(
                            { type, value, provider, description },
                            contract._id,
                          );
                        }
                      },
                    }}
                    data={{ uid: value, visibleId: description || '' }}
                    config={{
                      isActive: !!credentials?.find((c) => c.value === value),
                    }}
                  />
                );

              case 'TCS':
                return (
                  <TCSCredentialCard
                    handleEvent={{
                      event: async ({ event, primaryValue }) => {
                        if (event === 'unload') {
                          unloadCred(primaryValue!!, contract._id!!);
                        }
                        if (event === 'import') {
                          loadCred(
                            { type, value, provider, description },
                            contract._id,
                          );
                        }
                      },
                    }}
                    data={{ externalId: value }}
                    config={{
                      isActive: !!credentials?.find((c) => c.value === value),
                    }}
                  />
                );

              default:
                return <></>;
            }

          default:
            return <></>;
        }
      });

    const status = isEnabled ? 'ACTIVE' : 'SUSPENDED';

    const isStopped = contract?.stopDate
      ? DateTime.fromISO(contract.stopDate).diffNow().as('milliseconds') < 0
      : false;
    const isUpcoming = DateTime.fromISO(contract.startDate) > DateTime.now();
    const states: [ContractBadgesType.Data.State] = [status];
    if (isUpcoming) states.push('UPCOMING');
    if (isStopped) states.push('EXPIRED');
    if (!!contract.presenceCounter && contract.presenceCounter > 0)
      states.push('PRESENT');
    if (isStopped || isUpcoming) states.shift();

    const badges = useContractBadges({ states });

    const profileDetails = () => {
      return (
        <Widget>
          <ProfilDetails
            handleEvent={{ submit: handleSubmit }}
            data={{
              icon:
                contract.category === 'MOTORIST_POOL'
                  ? iconGlobalEntity.motoristContractPool
                  : iconGlobalEntity.motoristContractSolo,
              form,
              badges: badges as ProfilDetailsType.Data.Badge[],
            }}
            config={{
              form: {
                title1: {
                  name: 'lastName',
                  placeholder: t('lastName'),
                  edit: false,
                },
                title2: {
                  name: 'firstName',
                  placeholder: t('firstName'),
                  edit: false,
                },
                description1: {
                  name: 'code',
                  placeholder: t('code'),
                  edit: false,
                },
                description2: {
                  name: 'reference',
                  placeholder: t('reference'),
                  edit: true,
                },
                group1: [
                  {
                    name: 'product',
                    label: t('product'),
                    element: {
                      type: 'input',
                    },
                    edit: false,
                  },
                  {
                    name: 'noOfSpace',
                    label: t('noOfSpace'),
                    element: { type: 'counter' },
                    render: (value = ['0', '0']) =>
                      `${value[0].replace('/', '')} / ${value[1].replace(
                        '/',
                        '',
                      )}`,
                  },
                  {
                    name: 'APBMode',
                    label: t('APBMode'),
                    tooltip: (
                      <Write
                        data={{
                          item: t('TOOLPIP_PRESENT_ANTIPASSBACK_MODE'),
                        }}
                        config={{ mode: 'key-small-bold' }}
                      />
                    ),
                    element: {
                      type: 'select',
                      items: APBModeGlobal.map((value) => ({
                        label: t(value),
                        value,
                      })),
                    },
                  },
                  {
                    name: 'APBNext',
                    label: t('APBNext'),
                    tooltip: (
                      <Write
                        data={{
                          item: t('TOOLPIP_PRESENT_ANTIPASSBACK_NEXT'),
                        }}
                        config={{ mode: 'key-small-bold' }}
                      />
                    ),
                    element: {
                      type: 'input',
                    },
                    color: !freeflag ? 'success' : 'warn',
                    edit: false,
                  },
                  {
                    name: 'startDate',
                    label: t('startDate'),
                    element: {
                      type: 'datetime',
                      min: contract.dateBoundaryFromParent?.from || null,
                      max:
                        contract.stopDate ||
                        contract.dateBoundaryFromParent?.to,
                    },
                    render: (value: string) =>
                      DateTime.fromISO(value).toFormat('dd/MM/yy HH:mm:ss'),
                  },
                  {
                    name: 'endDate',
                    label: t('endDate'),
                    element: {
                      type: 'datetime',
                      min:
                        contract.startDate ||
                        contract.dateBoundaryFromParent?.from,
                      max: contract.dateBoundaryFromParent?.to || null,
                    },
                    render: (value: string) =>
                      DateTime.fromISO(value).toFormat('dd/MM/yy HH:mm:ss'),
                  },
                  {
                    name: 'description',
                    label: t('description'),
                    element: { type: 'textarea' },
                  },
                ],
                group2: [
                  {
                    name: 'streetName',
                    label: t('address1'),
                    element: { type: 'input' },
                    edit: false,
                  },
                  {
                    name: 'streetNumber',
                    label: t('address2'),
                    element: { type: 'input' },
                    edit: false,
                  },
                  {
                    name: 'zipCode',
                    label: t('zipCode'),
                    element: { type: 'input' },
                    edit: false,
                  },
                  {
                    name: 'city',
                    label: t('city'),
                    element: { type: 'input' },
                    edit: false,
                  },
                  {
                    name: 'country',
                    label: t('country'),
                    element: {
                      type: 'select',
                      items: countryOptions,
                    },
                    edit: false,
                  },
                ],
              },
              actions: (
                <MotoristContractAction
                  initialState={{
                    status: contract.isEnabled,
                    id: contract._id,
                    parkingId,
                    isStopped: contract.stopDate !== undefined && isStopped,
                    motoristId: motorist!!._id,
                    APBNext:
                      contract.freeflag === null ||
                      contract.freeflag === undefined,
                    category: contract.category,
                  }}
                  config={{ size: 'xlarge' }}
                  key={Math.random()}
                />
              ),
              edit:
                contract.category === 'MOTORIST_POOL' ||
                (contract.category === 'PERIOD_SUBSCRIPTION' && !isStopped),
            }}
          />
        </Widget>
      );
    };

    const motoristCredentials = () => (
      <Col config={{ scrollY: true, height: 'full' }}>
        <CredentialCard.Group
          handleEvent={{
            event: async ({ event, oldValue, newValue }) => {
              if (event === 'create') {
                switch (newValue.name) {
                  case 'RFID':
                    const { uid, visibleId } = newValue;

                    try {
                      await addCredOnContractCall({
                        variables: {
                          motoristId: motorist?._id,
                          contractId: contract._id,
                          type: 'RFID',
                          uid,
                          visibleId,
                          // oldValue,
                        },
                      });
                    } catch (e) {
                      throw new Error('theCredentialAlreadyExists');
                    }

                    break;

                  case 'PLATE':
                    const { description, plate } = newValue;
                    try {
                      await addCredOnContractCall({
                        variables: {
                          motoristId: motorist?._id,
                          contractId: contract._id,
                          type: 'PLATE',
                          description,
                          plate,
                          // oldValue,
                        },
                      });
                      break;
                    } catch (e) {
                      throw new Error('theCredentialAlreadyExists');
                    }
                  case 'TCS':
                    const { externalId, name } = newValue;

                    try {
                      await addCredOnContractCall({
                        variables: {
                          motoristId: motorist?._id,
                          contractId: contract._id,
                          type: 'TCS',
                          externalId,
                          name,
                          // oldValue,
                        },
                      });
                    } catch (e) {
                      throw new Error('theCredentialAlreadyExists');
                    }
                    break;
                }
              } else if (event === 'edit') {
                switch (newValue.name) {
                  case 'PLATE':
                    try {
                      const { description, plate } = newValue;

                      await editCredOnContractCall({
                        variables: {
                          // motoristId: motorist?._id,
                          contractId: contract._id,
                          type: 'PLATE',
                          description,
                          plate,
                          oldValue: {
                            description: (oldValue as any).description,
                            plate: (oldValue as any).plate,
                          },
                        },
                      });

                      break;
                    } catch (e: any) {
                      throw new Error(`error-code-${e.message}`);
                    }
                }
              }

              return 'ok';
            },
          }}
          config={{
            place: isStopped ? 'contractDisabled' : 'contract',
            credentials: {
              RFID: !!credentialsTypes?.types?.find(
                ({ type, provider }) =>
                  type === 'RFID' ||
                  (type === 'PROVIDER_EXTERNAL_ID' && provider === 'OSP'),
              ),
              PLATE: !!credentialsTypes?.types?.find(
                ({ type }) => type === 'PLATE',
              ),
              TCS: !!credentialsTypes?.types?.find(({ type, provider }) =>
                type === 'PROVIDER_EXTERNAL_ID' && provider
                  ? ['TCSOSP', 'TCS'].includes(provider)
                  : false,
              ),
            },
          }}
        >
          {credentialsRender()}
        </CredentialCard.Group>
      </Col>
    );

    return (
      <Widget.Group
        config={{
          title: widget?.title,
          backtitle: !!widget?.title,
        }}
        state={widget?.state}
      >
        <Zone
          config={{
            zones: [['profil'], ['segmented'], ['details']],
            rows: ['min-content', 'min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'profil' }}>{profileDetails()}</Zone.Area>

          <Zone.Area config={{ area: 'segmented' }}>
            <Widget>
              <Segmented
                handleEvent={{
                  option: (value) => setSegmented(value.toString()),
                }}
                data={{ selected: segmented }}
                config={{
                  size: 'large',
                  options: [
                    { label: t('credentials'), value: 'credentials' },
                    { label: t('contracts'), value: 'contracts' },
                    { label: t('sessions'), value: 'sessions' },
                  ],
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'details' }}>
            <>
              {segmented === 'credentials' && motoristCredentials()}

              {segmented === 'contracts' && (
                <MotoristContractList
                  motoristId={motorist!!._id}
                  parkingId={parkingId}
                />
              )}

              {segmented === 'sessions' && (
                <SessionList
                  motoristId={motorist?._id}
                  parkingId={parkingId}
                  contractId={contract._id}
                  pageSize={15}
                />
              )}
            </>
          </Zone.Area>
        </Zone>
      </Widget.Group>
    );
  },
);
