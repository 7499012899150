import { gql } from '@apollo/client';

export const verifyMotoristGQL = gql`
  query verifyMotorist($token: String!) {
    verifyMotorist(token: $token) {
      motorist {
        _id
        username
        firstName
        lastName
        address1
        address2
        isAnonymous
        zipcode
        city
        state
        country
        phone
        lang
        gender
      }
      fieldsToVerify {
        motorist
      }
    }
  }
`;
