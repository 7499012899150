import { gql } from '@apollo/client';

export const verifyAndUpdateMotoristGQL = gql`
  mutation verifyAndUpdateMotorist($input: SetVerifyDto!) {
    verifyAndUpdateMotorist(input: $input) {
      motorist {
        _id
        username
        firstName
        lastName
        address1
        address2
        isAnonymous
        zipcode
        city
        state
        country
        phone
        lang
        gender
      }
    }
  }
`;
