import {
  Empty,
  FormType,
  Segmented,
  SegmentedType,
  Widget,
  Zone,
  useLazyQuery,
  useTranslation,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import {
  EventEntity,
  Query,
  QuerySessionArgs,
  SessionEntity,
} from '../../client/graphql';
import { sessionGql } from '../../common/gql/session.gql';
import { ComponentInfosSelector } from '../../common/mapper/eventsInfos.mapper';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import { DurationTimer } from '../../common/utils/duration-timer/duration-timer';
import { ProfilDetails } from '../../components/profil-details/profil-details.component';
import { TimelineGroup } from '../../components/timeline/timeline.component';
import { SessionAction } from '../action/session.action';

export type SessionDetailsProps = {
  sessionId: string | null;
  parkingId: string;
  widget?: {
    title?: string;
  };
};

const sessionRender = ({
  events,
  entryDate,
  exitDate,
  amount,
  currency,
  ...session
}: SessionEntity) => ({
  ...session,
  startDate: entryDate,
  amount:
    amount && currency
      ? new Intl.NumberFormat(currency, {
          style: 'currency',
          currency: currency,
        }).format(amount / 100)
      : currency
        ? new Intl.NumberFormat(currency, {
            style: 'currency',
            currency: currency,
          }).format(0)
        : '-',
  entryDate:
    entryDate !== '1970-01-01T00:00:00.000Z'
      ? entryDate
        ? DateTime.fromISO(entryDate).toLocaleString(
            DateTime.DATETIME_SHORT_WITH_SECONDS,
          )
        : 'N/A'
      : 'N/A',
  exitDate:
    exitDate !== '1970-01-01T00:00:00.000Z'
      ? exitDate
        ? DateTime.fromISO(exitDate).toLocaleString(
            DateTime.DATETIME_SHORT_WITH_SECONDS,
          )
        : 'N/A'
      : 'N/A',
  events: events.map((event: EventEntity) => {
    const accesspoint = session?.parking?.accessPoints?.find(
      (access: any) => access._id === event.accessPointId,
    );

    return {
      ...event,
      currency: currency,
      accessPointCode: accesspoint?.shortCode || 'undefined',
      createdAt: DateTime.fromISO(event.createdAt).toLocaleString(
        DateTime.DATETIME_SHORT_WITH_SECONDS,
      ),
    };
  }),
});

export const SessionDetails = ({
  widget,
  sessionId,
  parkingId,
}: SessionDetailsProps) => {
  const { t, lang } = useTranslation();
  const [form, setForm] = useState<FormType.Data.Value>();
  const [segmented, setSegmented] =
    useState<SegmentedType.Data.Selected>('history');

  const [sessionCall, sessionState] = useLazyQuery<
    {
      session: Query['session'];
    },
    QuerySessionArgs
  >(sessionGql, {
    variables: {
      sessionId: sessionId || '',
    },
    socket: {
      'sessions:updated': () => true,
    },
  });

  const session = useMemo(() => {
    if (!sessionState.data) return null;

    return sessionRender(sessionState.data.session);
  }, [sessionState.data]);

  const parkingName = useMemo(() => {
    const translations = session?.parking?.translation?.find(
      (tr: any) => tr.lang === lang,
    );
    return translations?.name;
  }, [session]);

  const eventsList = useMemo(() => session?.events, [session]);

  useEffect(() => {
    if (!!session) {
      const {
        motorist,
        amount,
        occupancyDuration,
        entryDate,
        startDate,
        exitDate,
        product,
        authorizeTransactionId,
      } = session;

      setForm({
        firstName: motorist?.firstName || '-',
        lastName: motorist?.lastName || '',
        product: product?.name || '-',
        entryDate,
        exitDate,
        parking: parkingName,
        duration: occupancyDuration ? occupancyDuration : startDate,
        amount: amount && authorizeTransactionId ? amount : '-',
      });
    }
  }, [session, parkingName]);

  useEffect(() => {
    if (sessionId) {
      sessionCall({
        variables: {
          sessionId,
        },
      });
    } else {
      sessionState.resetData();
    }
  }, [sessionId]);

  return (
    <Widget.Group
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
      }}
      state={{
        loading: sessionState.loading,
        error: sessionState.error,
        refetch: sessionState.refetch,
        showPermanentRefetch: false,
      }}
    >
      {sessionId && session && form ? (
        <Zone
          config={{
            zones: [['profil'], ['segmented'], ['details']],
            rows: ['min-content', 'min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'profil' }}>
            <Widget>
              <ProfilDetails
                handleEvent={{
                  submit: (form) => setForm(form),
                }}
                data={{
                  icon: iconGlobalEntity['session'],
                  form,
                  badges: [
                    {
                      text: t(
                        `global-state-${session.state
                          .replaceAll('_', '-')
                          .toLocaleLowerCase()}`,
                      ),
                      color: session.state.includes('DENIED')
                        ? 'error'
                        : 'success',
                    },
                  ],
                }}
                config={{
                  form: {
                    title1: {
                      name: 'lastName',
                      placeholder: t('lastName'),
                      edit: false,
                    },
                    title2: {
                      name: 'firstName',
                      placeholder: t('firstName'),
                      edit: false,
                    },
                    description1: {
                      name: 'product',
                      placeholder: t('product'),
                      edit: false,
                    },
                    group1: [
                      {
                        name: 'entryDate',
                        label: t('entryDate'),
                        element: {
                          type: 'datetime',
                        },
                        edit: false,
                      },
                      {
                        name: 'parking',
                        label: t('parking'),
                        element: {
                          type: 'input',
                        },
                        edit: false,
                      },
                      {
                        name: 'amount',
                        label: t('amount'),
                        element: {
                          type: 'input',
                        },
                        edit: false,
                      },
                    ],
                    group2: [
                      {
                        name: 'exitDate',
                        label: t('exitDate'),
                        element: { type: 'datetime' },
                        edit: false,
                      },
                      {
                        name: 'duration',
                        label: t('duration'),
                        element: { type: 'input' },
                        render: (data) => DurationTimer(data),
                        edit: false,
                      },
                    ],
                  },
                  actions: (
                    <SessionAction
                      config={{ size: 'xlarge' }}
                      data={{
                        contractId: session?.contractId || '',
                        motoristId: session?.motorist?._id || '',
                        parkingId: parkingId,
                        sessionId: session?._id || '',
                      }}
                    />
                  ),
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'segmented' }}>
            <Widget>
              <Segmented
                handleEvent={{
                  option: (value) => setSegmented(value),
                }}
                data={{ selected: segmented }}
                config={{
                  size: 'large',
                  options: [{ label: t('history'), value: 'history' }],
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'details' }}>
            {segmented === 'history' && (
              <Widget>
                <TimelineGroup config={{ height: 'large', width: 'full' }}>
                  {eventsList?.map((event) => ComponentInfosSelector(event))}
                </TimelineGroup>
              </Widget>
            )}
          </Zone.Area>
        </Zone>
      ) : (
        <Empty
          config={{
            mode: { name: 'select', text: `${t('selectSession')} ...` },
          }}
        ></Empty>
      )}
    </Widget.Group>
  );
};
