import { ReactElement } from 'react';
import { CredentialEnum, EventEntity } from '../../client/graphql';
import { PLATECredentialMini } from '../../composition/credential/plate.credential';
import { QRCODECredentialMini } from '../../composition/credential/qrcode.credential';
import { RFIDCredentialMini } from '../../composition/credential/rfid.credential';
import { TCSCredentialMini } from '../../composition/credential/tcs.credential';
import { EnteredSessionTimeline } from '../../composition/session-timeline/ENTERED.session-timeline';
import { EntryAuthorizedSessionTimeline } from '../../composition/session-timeline/ENTRY-AUTHORIZED.session-timeline';
import { EntryDeniedSessionTimeline } from '../../composition/session-timeline/ENTRY-DENIED.session-timeline';
import { ExitAuthorizedSessionTimeline } from '../../composition/session-timeline/EXIT-AUTHORIZED.session-timeline';
import { ExitDeniedSessionTimeline } from '../../composition/session-timeline/EXIT-DENIED.session-timeline';
import { ExitedSessionTimeline } from '../../composition/session-timeline/EXITED.session-timeline';
import { PaymentNotifiedSessionTimeline } from '../../composition/session-timeline/PAYMENT-NOTIFIED.session-timeline';
import { PedestrianAccessSessionTimeline } from '../../composition/session-timeline/PEDESTRIAN-ACCESS.session-timeline';

const CredentialComponentSelector = (
  credentials: EventEntity['credentials'],
) => {
  const externalId = (credentials || [])
    .filter((e) => e !== null)
    .find(({ type }) => type === CredentialEnum.ProviderExternalId);

  const mapedCredentialToComponent = ({
    type,
    value,
  }: {
    type?: CredentialEnum | null;
    value?: string | null;
  }) => {
    const credentialMap: { [key: string]: JSX.Element } = {
      RFID: <RFIDCredentialMini data={{ value: value }} />,
      PLATE: <PLATECredentialMini data={{ value: value }} />,
      QRCODE: <QRCODECredentialMini data={{ value: '' }} />,
      TCS: <TCSCredentialMini data={{ value: value }} />,
      TCSOSP: <TCSCredentialMini data={{ value: value }} />,
    };

    return type && credentialMap[type] ? credentialMap?.[type] : '';
  };

  return externalId
    ? mapedCredentialToComponent({
        type: externalId.type,
        value: externalId.value,
      })
    : mapedCredentialToComponent({
        type: credentials?.[0]?.type,
        value: credentials?.[0]?.value,
      });
};

export const ComponentInfosSelector = ({
  state,
  createdAt,
  credentials,
  accessPointCode,
  reason,
  amount,
  currency,
}: EventEntity & {
  accessPointCode?: string | null;
  currency?: string | null;
  createdAt: string;
}) => {
  const mapedStateToComponent: { [key: string]: ReactElement } = {
    ENTRY_AUTHORIZED: (
      <EntryAuthorizedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
          extra: CredentialComponentSelector(credentials),
        }}
      />
    ),
    EXIT_AUTHORIZED: (
      <ExitAuthorizedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
          extra: CredentialComponentSelector(credentials),
        }}
      />
    ),
    EXITED: (
      <ExitedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
        }}
      />
    ),
    ENTRY_DENIED: (
      <EntryDeniedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
          extra: CredentialComponentSelector(credentials),
          subtitle: reason || 'undefined',
        }}
      />
    ),
    ENTERED: (
      <EnteredSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
        }}
      />
    ),
    EXIT_DENIED: (
      <ExitDeniedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
          extra: CredentialComponentSelector(credentials),
          subtitle: reason || 'undefined',
        }}
      />
    ),
    AUTHORIZE_PEDESTRIAN: (
      <PedestrianAccessSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
          extra: CredentialComponentSelector(credentials),
        }}
      />
    ),
    PAYMENT_NOTIFIED: (
      <PaymentNotifiedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
          extra:
            amount && currency
              ? new Intl.NumberFormat(currency, {
                  style: 'currency',
                  currency: currency,
                }).format(amount / 100)
              : currency
                ? new Intl.NumberFormat(currency, {
                    style: 'currency',
                    currency: currency,
                  }).format(0)
                : '-',
        }}
      />
    ),
  };
  return mapedStateToComponent[state];
};
