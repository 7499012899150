import { gql } from '@apollo/client';

export const regenerateQrcodeGql = gql`
  mutation regenerateQrcode($motoristId: String!) {
    regenerateQrcode(motoristId: $motoristId) {
      motorist {
        _id
      }
    }
  }
`;
