export default () => (
  <svg
    id="uuid-6c55eb04-a1f9-41d7-8c13-378abc88d1c8"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 78.08 32.44"
  >
    <g>
      <path d="M15.53,32.28C6.97,32.28,0,25.04,0,16.14S6.97,0,15.53,0s15.53,7.24,15.53,16.14-6.97,16.14-15.53,16.14ZM15.53,2.7c-7.14,0-12.94,6.02-12.94,13.44s5.8,13.44,12.94,13.44,12.94-6.02,12.94-13.44c.02-7.42-5.8-13.44-12.94-13.44Z" />
      <path
        class="uuid-58226565-d5f6-4a63-850f-9924b4267eee"
        d="M43.62,32.44c-5.04,0-9.52-3.71-10.62-8.83l2.53-.59c.84,3.9,4.24,6.72,8.09,6.72,4.58,0,8.3-3.87,8.3-8.62,0-2.87-1.37-5.54-3.65-7.16l1.46-2.23c3,2.11,4.81,5.62,4.81,9.38-.02,6.23-4.89,11.32-10.91,11.32Z"
      />
      <path d="M41.96,0c-4.96,0-8.98,4.2-8.98,9.33,0,3.85,2.23,7.26,5.68,8.69l.96-2.51c-2.45-1.01-4.04-3.43-4.04-6.18,0-3.66,2.87-6.65,6.4-6.65h9.12V0h-9.13Z" />
      <path d="M69.14,0h-15.45v2.7h15.45c3.5,0,6.35,2.96,6.35,6.58s-2.85,6.58-6.35,6.58h-7.62v15.22h2.6v-12.52h5.03c4.93,0,8.93-4.16,8.93-9.28S74.07,0,69.14,0Z" />
    </g>
  </svg>
);
