import {
  Button,
  Col,
  Form,
  Header,
  Icon,
  IconType,
  Input,
  Row,
  Space,
  Widget,
  Write,
  Zone,
  useMutation,
  useRootStore,
  useTranslation,
} from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import OspFull from '../../../assets/logos/osp_full';
import {
  Mutation,
  MutationLoginArgs,
  MutationValidate2faArgs,
} from '../../../client/graphql';
import { loginGql } from '../../../common/gql/login.gql';
import { validate2faGql } from '../../../common/gql/validate-2fa.gql';
import { config } from '../../../config';
import './login.page.scss';
const PakingScene = require('../../../assets/images/parking_scene.jpg');

type StrategysPossibility = 'local' | 'twofa';
const defaultFirstStep: StrategysPossibility = 'local';

const convertStrategyTerms: Record<string, StrategysPossibility> = {
  TWO_FACTOR_AUTH: 'twofa',
};

export const LoginPage = observer(() => {
  const { t, lang } = useTranslation();
  const { AuthStore } = useRootStore();
  const [sAllSteps, setSAllSteps] = useState<StrategysPossibility[]>([
    defaultFirstStep,
  ]);

  const [sStepsExe, setSStepsExe] = useState<StrategysPossibility[]>([]);

  // const [mCurrentStep, setSCurrentStep] =
  //   useState<StrategysPossibility>(defaultFirstStep)

  const [sUsername, setSUsername] = useState<string>('');
  const [sPassword, setSPassword] = useState<string>('');
  const [sTwofa, setSTwofa] = useState<string>('');
  const [sError, setSError] = useState<boolean>(false);

  const [sUserId, setSUserId] = useState<string>('');
  const [sToken, setSToken] = useState<string | null>(null);

  const [loginCall] = useMutation<
    { login: Mutation['login'] },
    MutationLoginArgs
  >(loginGql);

  const [validate2faCall] = useMutation<
    { validate2fa: Mutation['validate2fa'] },
    MutationValidate2faArgs
  >(validate2faGql);

  const features = useMemo((): Array<{
    icon: IconType.Config.Type;
    title: string;
    description: string;
  }> => {
    return [
      {
        icon: 'faLocationDotSolid',
        title: t('LOGIN_MULTI_VENDOR_PLATFORM_TITLE'),
        description: t('LOGIN_MULTI_VENDOR_PLATFORM_DESCRIPTION'),
      },
      {
        icon: 'faSquareParkingSolid',
        title: t('LOGIN_SIMPLIFIED_CARD_MANAGE_TITLE'),
        description: t('LOGIN_SIMPLIFIED_CARD_MANAGE_DESCRIPTION'),
      },
      {
        icon: 'faDesktopSolid',
        title: t('LOGIN_CENTRALIZE_SUBSCRIPTIONS_AND_DATA_TITLE'),
        description: t('LOGIN_CENTRALIZE_SUBSCRIPTIONS_AND_DATA_DESCRIPTION'),
      },
      {
        icon: 'faAddressCardSolid',
        title: t('LOGIN_COLLABORATION_WITH_TCS_TITLE'),
        description: t('LOGIN_COLLABORATION_WITH_TCS_DESCRIPTION'),
      },
    ];
  }, [lang]);

  const mCurrentStep = useMemo((): StrategysPossibility => {
    if (sStepsExe.length < sAllSteps.length) {
      return sAllSteps[sStepsExe.length];
    } else {
      return sAllSteps[0];
    }
  }, [sAllSteps, sStepsExe]);

  const errorFormatted = useMemo((): string => {
    switch (mCurrentStep) {
      case 'local':
        return t('watermelon-theIdentifiersAreIncorrect');
      case 'twofa':
        return t('watermelon-theTwofaAreIncorrect');
    }
  }, [mCurrentStep, lang]);

  const localStrategy = useCallback(
    async ({
      username,
      password,
    }: {
      username: string;
      password: string;
    }): Promise<{
      userId: string | null;
      token: string | null;
      steps: StrategysPossibility[];
    }> => {
      try {
        const { data, errors } = await loginCall({
          variables: { username, password },
        });

        if (errors && errors.length > 0) setSError(() => true);

        console.log('data', { data });

        return {
          userId: data?.login.user._id || null,
          token: data?.login.token || null,
          steps:
            data?.login.validationSteps.map(
              (value) => convertStrategyTerms[value],
            ) || [],
        };
      } catch (error) {
        throw error;
      }
    },
    [],
  );

  const twofaStrategy = useCallback(
    async ({
      token,
      userId,
    }: {
      token: string;
      userId: string;
    }): Promise<{
      token: string | null;
    }> => {
      try {
        const { data } = await validate2faCall({
          variables: { token, userId },
        });

        if (!data?.validate2fa.granted || !data.validate2fa.token)
          throw new Error();
        return { token: data.validate2fa.token };
      } catch (error) {
        throw error;
      }
    },
    [],
  );

  const submit = useCallback(async () => {
    setSError(() => false);

    try {
      switch (mCurrentStep) {
        case 'local':
          const local = await localStrategy({
            username: sUsername,
            password: sPassword,
          });

          setSToken(() => local.token);
          setSUserId(() => local.userId!);
          setSAllSteps((items) => [...items, ...local.steps]);
          break;
        case 'twofa':
          const twofa = await twofaStrategy({ token: sTwofa, userId: sUserId });
          setSToken(() => twofa.token);
          break;
      }

      setSStepsExe((items) => [...items, mCurrentStep]);
    } catch (error) {
      setSError(() => true);
    }
  }, [mCurrentStep, sAllSteps, sUsername, sPassword, sTwofa, sUserId]);

  useEffect(() => {
    if (sToken) {
      AuthStore.login(sToken);
      setSAllSteps(() => [defaultFirstStep]);
    }
  }, [sToken]);

  useEffect(() => {
    setSAllSteps(() => [defaultFirstStep]);
  }, []);

  return (
    <div className="login">
      {config.PORTAL === 'tenant' && config.ENV === 'production' ? (
        <div className="login__comming">
          <Write
            data={{ item: t('tenant-comming-soon') }}
            config={{
              mode: 'title-medium',
              color: 'white',
            }}
          ></Write>
        </div>
      ) : null}
      <div className="login__background">
        <div className="login__background__mask"></div>
        <img className="login__background__image" src={PakingScene} />
      </div>

      <Header config={{ position: 'fixed', logo: OspFull() }} />

      <div className="login__contain">
        <Zone
          config={{
            gap: {
              x: 4,
              y: 5,
            },
            zones: [['login'], ['details']],
            rows: ['1fr', 'min-content'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area
            config={{
              area: 'login',
            }}
          >
            <Row
              config={{ height: 'full', horizontal: 'end', vertical: 'center' }}
            >
              <Widget
                className="login__contain__form"
                config={{
                  title: 'Login',
                  responsive: {
                    padding: false,
                  },
                }}
              >
                <Form
                  className="loginForm"
                  config={{ validateOnChange: false }}
                  handleEvent={{
                    change: (value: object) => {},
                    submit: (value: object) => {},
                  }}
                >
                  <Col
                    config={{
                      width: 'full',
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                  >
                    <Space config={{ way: 'vertical', count: 1.5 }} />
                    {mCurrentStep === 'local' && (
                      <>
                        <Form.Item
                          config={{
                            label: 'Username',
                            name: 'username',
                            way: 'vertical',
                            rules: [{ required: true }],
                          }}
                        >
                          <Input
                            className="loginFormInput"
                            config={{
                              height: 'large',
                              width: 'full',
                              placeholder: 'user@openswissparking.ch',
                            }}
                            handleEvent={{
                              input: (value: string | number | null) => {
                                setSError(() => false);
                                setSUsername(value as string);
                              },
                            }}
                          />
                        </Form.Item>

                        <Space config={{ way: 'vertical', count: 1.5 }} />
                        <Form.Item
                          config={{
                            label: 'Password',
                            name: 'password',
                            way: 'vertical',
                            rules: [{ required: true }],
                          }}
                        >
                          <Input
                            className="loginFormInput"
                            config={{
                              height: 'large',
                              width: 'full',
                              placeholder: 'Password123!',
                              type: { name: 'password' },
                            }}
                            handleEvent={{
                              input: (value: string | number | null) => {
                                setSError(() => false);
                                setSPassword(value as string);
                              },
                            }}
                          />
                        </Form.Item>
                      </>
                    )}

                    {mCurrentStep === 'twofa' && (
                      <Form.Item
                        config={{
                          label: t(
                            'watermelon-enterYourGoogleAuthenticatorCode',
                          ),
                          name: 'tfaCode',
                          way: 'vertical',
                          rules: [{ required: true }],
                        }}
                      >
                        <Input
                          className="loginFormInput"
                          config={{
                            height: 'large',
                            width: 'full',
                            placeholder: '123456',
                          }}
                          handleEvent={{
                            input: (value: string | number | null) =>
                              setSTwofa(`${value || ''}`),
                          }}
                        />
                      </Form.Item>
                    )}

                    <Space config={{ way: 'vertical', count: 2 }} />
                    <Button
                      handleEvent={{
                        click: async () => {
                          await submit();
                        },
                      }}
                      config={{
                        color: 'primary',
                        text: t('watermelon-login'),
                      }}
                    />

                    <Space config={{ count: 1 }} />

                    {sError && (
                      <Write
                        data={{ item: errorFormatted }}
                        config={{
                          color: 'error',
                          mode: 'key-small-light',
                        }}
                      />
                    )}
                  </Col>
                </Form>
              </Widget>
            </Row>
          </Zone.Area>

          <Zone.Area
            config={{
              area: 'details',
            }}
          >
            <Zone
              config={{
                gap: {
                  y: 4,
                },
                zones: [['present'], ['features']],
                rows: ['min-content', 'min-content'],
                columns: ['1fr'],
              }}
            >
              <Zone.Area
                config={{
                  area: 'present',
                }}
              >
                <Col
                  config={{
                    height: 'full',
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                >
                  <Write
                    config={{
                      lineHeight: true,
                      align: 'center',
                      mode: 'title-xlarge',
                      color: 'white',
                    }}
                    data={{ item: t('LOGIN_WELCOME_TO_OSP') }}
                  ></Write>
                  <Write
                    config={{
                      lineHeight: true,
                      align: 'center',
                      mode: 'title-xlarge',
                      color: 'white',
                    }}
                    data={{
                      item: t('LOGIN_LEADING_IN_SWITZERLAND'),
                    }}
                  ></Write>
                </Col>
              </Zone.Area>

              <Zone.Area
                config={{
                  area: 'features',
                }}
              >
                <Zone
                  config={{
                    gap: {
                      x: 4,
                    },
                    vertical: 'center',
                    zones: [features.map((_, index) => `feature${index}`)],
                    columns: [...features.map(() => `minmax(100px, 500px)`)],
                    rows: ['250px'],
                  }}
                >
                  {features.map(({ icon, title, description }, index) => {
                    return (
                      <Zone.Area
                        key={index}
                        config={{
                          area: `feature${index}`,
                        }}
                      >
                        <Widget
                          config={{
                            responsive: {
                              padding: false,
                            },
                          }}
                        >
                          <Col
                            config={{
                              height: 'full',
                              vertical: 'center',
                              horizontal: 'center',
                            }}
                          >
                            <Icon
                              config={{
                                type: icon,
                                color: 'primary',
                                size: 'mega',
                              }}
                            ></Icon>
                            <Space config={{ way: 'vertical', count: 2 }} />
                            <Write
                              config={{
                                lineHeight: true,
                                align: 'center',
                                mode: 'title-small',
                              }}
                              data={{
                                item: title,
                              }}
                            ></Write>
                            <Space config={{ way: 'vertical', count: 1 }} />
                            <Write
                              config={{
                                lineHeight: true,
                                align: 'center',
                                mode: 'value-medium',
                              }}
                              data={{
                                item: description,
                              }}
                            ></Write>
                          </Col>
                        </Widget>
                      </Zone.Area>
                    );
                  })}
                </Zone>
              </Zone.Area>
            </Zone>
          </Zone.Area>
        </Zone>
      </div>
    </div>
  );
});

{
  /* <Form.Item
config={{
  label: 'Enter your Google Authenticator code',
  name: 'tfaCode',
  way: 'vertical',
  rules: [{ required: true }]
}}
>
<Input
  className="loginFormInput"
  config={{
    height: 'large',
    width: 'full',
    placeholder: '123456789',
    type: 'text'
  }}
  handleEvent={{
    input: (value: string | number | null) =>
      setTfa(value as string)
  }}
/>
</Form.Item>{' '} */
}

// {granted === false && (
//   <Write
//     data={{ item: 'wrong-2fa' }}
//     config={{
//       color: 'error',
//       mode: 'key-small-light'
//     }}
//   />
// )}

// const accessToOffice = useCallback(() => {
//   setStep(() => 0)
//   authService.send('LOGIN', { token })
// }, [token])

// const handleLogin = async () => {
//   if (!token) {
//     const {
//       token: loginToken,
//       user: loginUser,
//       validationSteps: loginValidationSteps
//     } = await requestGQL({
//       operationName: getOperationName(loginGql)!!,
//       params: { username, password },
//       gql: loginGql
//     })
//     setToken(loginToken)
//     setUser(loginUser)
//     setValidationSteps(loginValidationSteps)
//   }
//   if (
//     step === 1 &&
//     validationSteps &&
//     validationSteps[step - 1] === 'TWO_FACTOR_AUTH'
//   ) {
//     const { granted: loginGranted } = await requestGQL({
//       operationName: getOperationName(validate2faGql)!!,
//       params: { token: tfa, userId: user?._id },
//       gql: validate2faGql
//     })
//     setGranted(loginGranted)
//   }
// }
