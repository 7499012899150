import { useRootStore } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import { config } from './config';
import { MainUse } from './main';
import { BreakdownsPage } from './pages/breakdowns/breakdowns.page';
import { ContractPage } from './pages/contracts/contracts.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { IncidentPage } from './pages/incidents/incidents.page';
import { LoginPage } from './pages/login/view/login.page';
import { MotoristConfirm } from './pages/motorist-confirm/motorist-confirm';
import { MotoristPage } from './pages/motorists/motorists.page';
import { ProductPage } from './pages/products/products.page';
import { SessionPage } from './pages/sessions/session.page';

export const CustomRouter = observer(() => {
  const { AuthStore } = useRootStore();

  const guestPathToPage: RouteObject[] = [
    {
      path: '*',
      element: <Navigate to={`/`} replace />,
    },
    {
      path: '/',
      element: <LoginPage />,
    },
    {
      path: '/verificator',
      element: <MotoristConfirm withHeader={true} />,
    },
  ];

  const userPathToPage: RouteObject[] = [
    {
      path: '/',
      element: <MainUse />,
      children: [
        {
          path: 'verificator',
          element: <MotoristConfirm withHeader={false} />,
        },
        {
          path: ':parkingId',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <DashboardPage />,
            },
            {
              path: 'dashboard',
              element: <DashboardPage />,
            },
            {
              path: 'sessions',
              element: <SessionPage />,
              children: [
                {
                  path: ':sessionId',
                  element: <SessionPage />,
                },
              ],
            },
            {
              path: 'contracts',
              element: <ContractPage />,
              children: [
                {
                  path: ':contractId',
                  element: <ContractPage />,
                },
              ],
            },
            ...(config.PORTAL === 'operator'
              ? [
                  {
                    path: 'breakdowns',
                    element: <BreakdownsPage />,
                    children: [
                      {
                        path: ':breakdownId',
                        element: <BreakdownsPage />,
                      },
                    ],
                  },
                ]
              : []),
            {
              path: 'incidents',
              element: <IncidentPage />,
              children: [
                {
                  path: ':incidentId',
                  element: <IncidentPage />,
                },
              ],
            },
            ...(config.PORTAL === 'operator'
              ? [
                  {
                    path: 'products',
                    element: <ProductPage />,
                  },
                ]
              : []),
          ],
        },
        {
          path: 'motorists',
          element: <MotoristPage />,

          children: [
            {
              index: true,
              element: <MotoristPage />,
            },
            {
              path: ':motoristId',
              element: <MotoristPage />,
              handle: {
                navKey: 'motorists',
              },
            },
          ],
        },
      ],
    },
  ];

  return (
    <RouterProvider
      router={createBrowserRouter(
        AuthStore.token ? userPathToPage : guestPathToPage,
      )}
    ></RouterProvider>
  );
});
