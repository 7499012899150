import {
  Icon,
  ListFlatInfo,
  Write,
  useLazyQuery,
  useTranslation,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Query, QueryGlobalSearchArgs } from '../../client/graphql';
import { globalSearchGQL } from '../../common/gql/globalSearch.gql';
import { convertCredentialsBackToFront } from '../../common/mapper/credential.mapper';
import { anonimizedEmail } from '../../common/utils/anonymise-email';
import { PLATECredentialMini } from '../../composition/credential/plate.credential';
import { QRCODECredentialMini } from '../../composition/credential/qrcode.credential';
import { RFIDCredentialMini } from '../../composition/credential/rfid.credential';
import { TCSCredentialMini } from '../../composition/credential/tcs.credential';
import { ContractMasterPoolLine } from '../../composition/line/contract-master-pool.line';
import { ContractMotoristLine } from '../../composition/line/contract-motorist.line';
import { ContractSubPoolLine } from '../../composition/line/contract-sub-pool.line';
import { MotoristLine } from '../../composition/line/motorist.line';
import { CredentialMini } from '../credential/credential.component';
import './searchbar.component.scss';

class Static {
  static minCharactersLength = 4;
}

export declare namespace SearchBarType {
  type Props = {
    className?: string;
  };
}

export const SearchBar = ({ className = '' }: SearchBarType.Props) => {
  const { t, lang } = useTranslation();
  const [charactersLength, setCharactersLength] = useState<number>(0);
  const [currentSelected, setCurrentSelected] = useState<string>('all');
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [results, setResults] = useState<
    Pick<Query['globalSearch'], 'localMotorists' | 'contracts'>
  >({
    localMotorists: [],
    contracts: [],
  });

  const [globalSearchCall] = useLazyQuery<
    { globalSearch: Query['globalSearch'] },
    QueryGlobalSearchArgs
  >(globalSearchGQL);

  const navigate = useNavigate();
  const handleSearch = useCallback(async (value: string) => {
    setCurrentSearch(() => value);
    if (value && value.length >= Static.minCharactersLength) {
      const { data } = await globalSearchCall({
        variables: { content: value },
      });

      if (!data?.globalSearch) return;

      const { localMotorists, contracts } = data.globalSearch;

      setResults(() => ({
        localMotorists,
        contracts: contracts.filter(
          ({ linkedContractId }) =>
            !contracts.find(
              ({ _id, contractCount }) =>
                linkedContractId === _id && contractCount === 1,
            ),
        ),
      }));
      setIsOpen(() => true);
    } else {
      setResults(() => ({
        localMotorists: [],
        contracts: [],
      }));
      setIsOpen(() => false);
    }

    setCharactersLength(value ? value.length : 0);
  }, []);

  const summaryItems = useMemo((): Array<{
    key: string;
    label: string;
    count: number;
    contain:
      | Array<{ _id: string; items: { component: React.ReactNode } }>
      | undefined;
  }> => {
    const localMotoristsNodes = results
      ? results.localMotorists.map(
          ({
            _id,
            firstName,
            lastName,
            email,
            status,
            uids,
            plates,
            cards,
            activeAndTotalCount,
          }) => {
            return {
              _id: `${_id}`,
              items: {
                component: (
                  <MotoristLine
                    data={{
                      email: email ? anonimizedEmail(email) : null,
                      firstName: firstName || null,
                      lastName: lastName || null,
                      status: status === 'VERIFIED' ? 'VERIFIED' : 'UNVERIFIED',
                      credentialsList: (
                        <CredentialMini.Group config={{ hiddenMin: 3 }}>
                          {convertCredentialsBackToFront([
                            ...cards,
                            ...plates,
                            ...uids,
                          ]).map((element) => {
                            if (element.name === 'PLATE') {
                              return (
                                <PLATECredentialMini
                                  data={{ value: element.plate }}
                                />
                              );
                            }
                            if (element.name === 'RFID') {
                              return (
                                <RFIDCredentialMini
                                  data={{
                                    value: element.visibleId,
                                  }}
                                />
                              );
                            }
                            if (element.name === 'TCS') {
                              return (
                                <TCSCredentialMini
                                  data={{ value: element.externalId }}
                                />
                              );
                            } else return <></>;
                          })}
                        </CredentialMini.Group>
                      ),

                      credentials: uids.length + plates.length,
                      activeContracts: [
                        activeAndTotalCount.active,
                        activeAndTotalCount.total,
                      ],
                    }}
                    config={{ icon: true }}
                  ></MotoristLine>
                ) as React.ReactNode,
              },
            };
          },
        )
      : undefined;

    const contractsNodes = results
      ? results?.contracts.map(
          ({
            _id,
            name1,
            name2,
            isEnabled,
            ospContractId,
            category,
            contractCount,
            stopDate,
            credentials,
          }) => ({
            _id: `${_id}`,
            items: {
              component: ['PERIOD_SUBSCRIPTION', 'MOTORIST_POOL'].includes(
                category,
              ) ? (
                <ContractMotoristLine
                  data={{
                    firstName: name1 || null,
                    lastName: name2 || null,
                    contractId: ospContractId || null,
                    status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                    isStopped: stopDate
                      ? DateTime.fromISO(stopDate)
                          .diffNow()
                          .as('milliseconds') < 0
                      : false,
                    credentialsList: (
                      <CredentialMini.Group config={{ hiddenMin: 3 }}>
                        {convertCredentialsBackToFront([
                          ...(credentials || []),
                        ]).map((element, index) => {
                          if (element.name === 'PLATE') {
                            return (
                              <PLATECredentialMini
                                key={index}
                                data={{ value: element.plate }}
                              />
                            );
                          }
                          if (element.name === 'RFID') {
                            return (
                              <RFIDCredentialMini
                                key={index}
                                data={{ value: element.visibleId }}
                              />
                            );
                          }
                          if (element.name === 'TCS') {
                            return (
                              <TCSCredentialMini
                                key={index}
                                data={{ value: element.externalId }}
                              />
                            );
                          }
                          if (element.name === 'QRCODE') {
                            return (
                              <QRCODECredentialMini
                                key={index}
                                data={{ value: '' }}
                              />
                            );
                          }
                        })}
                      </CredentialMini.Group>
                    ),
                  }}
                />
              ) : category === 'OPERATOR_POOL' ? (
                <ContractMasterPoolLine
                  data={{
                    name1: name1!,
                    name2: name2 || null,
                    code: ospContractId || null,
                    status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                    isStopped: stopDate
                      ? DateTime.fromISO(stopDate)
                          .diffNow()
                          .as('milliseconds') < 0
                      : false,
                    contractCounter: contractCount,
                    credentialsList: (
                      <CredentialMini.Group config={{ hiddenMin: 3 }}>
                        {convertCredentialsBackToFront([
                          ...(credentials || []),
                        ]).map((element) => {
                          if (element.name === 'PLATE') {
                            return (
                              <PLATECredentialMini
                                data={{ value: element.plate }}
                              />
                            );
                          }
                          if (element.name === 'RFID') {
                            return (
                              <RFIDCredentialMini
                                data={{ value: element.visibleId }}
                              />
                            );
                          }
                          if (element.name === 'TCS') {
                            return (
                              <TCSCredentialMini
                                data={{ value: element.externalId }}
                              />
                            );
                          }
                          if (element.name === 'QRCODE') {
                            return (
                              <QRCODECredentialMini
                                data={{ value: element.value }}
                              />
                            );
                          }
                        })}
                      </CredentialMini.Group>
                    ),
                  }}
                />
              ) : (
                <ContractSubPoolLine
                  data={{
                    name1: name1!,
                    name2: name2 || null,
                    code: ospContractId || null,
                    status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                    isStopped: stopDate
                      ? DateTime.fromISO(stopDate)
                          .diffNow()
                          .as('milliseconds') < 0
                      : false,
                    contractCounter: contractCount,
                    credentialsList: (
                      <CredentialMini.Group config={{ hiddenMin: 3 }}>
                        {convertCredentialsBackToFront([
                          ...(credentials || []),
                        ]).map((element) => {
                          if (element.name === 'PLATE') {
                            return (
                              <PLATECredentialMini
                                data={{ value: element.plate }}
                              />
                            );
                          }
                          if (element.name === 'RFID') {
                            return (
                              <RFIDCredentialMini
                                data={{ value: element.visibleId }}
                              />
                            );
                          }
                          if (element.name === 'TCS') {
                            return (
                              <TCSCredentialMini
                                data={{ value: element.externalId }}
                              />
                            );
                          }
                          if (element.name === 'QRCODE') {
                            return (
                              <QRCODECredentialMini
                                data={{ value: element.value }}
                              />
                            );
                          }
                        })}
                      </CredentialMini.Group>
                    ),
                  }}
                />
              ),
            },
          }),
        )
      : undefined;

    return [
      {
        key: 'all',
        label: t('allResults'),
        count: results
          ? results.localMotorists.length + results.contracts.length
          : 0,
        contain:
          localMotoristsNodes && contractsNodes
            ? [...localMotoristsNodes, ...contractsNodes]
            : undefined,
      },
      {
        key: 'motorists',
        label: t('motorists'),
        count: results ? results.localMotorists.length : 0,
        contain: localMotoristsNodes ? localMotoristsNodes : undefined,
      },
      {
        key: 'contracts',
        label: t('contracts'),
        count: results ? results.contracts.length : 0,
        contain: contractsNodes ? contractsNodes : undefined,
      },
    ];
  }, [lang, results]);

  useEffect(() => {
    const click = (e: MouseEvent) => {
      const clickX = e.clientX;
      const clickY = e.clientY;

      const elementInSearch = ['#searchbar__contain', '#searchbar__result'];

      const giveMeAccord =
        elementInSearch.reduce((acc, value) => {
          const currentElement = () => document.querySelector(value);

          if (!currentElement()) return acc + 1;

          const { left, width, top, height } =
            currentElement()!.getBoundingClientRect();

          const startX = left;
          const endX = left + width;
          const startY = top;
          const endY = top + height;

          if (
            startX <= clickX &&
            clickX <= endX &&
            startY <= clickY &&
            clickY <= endY
          )
            return acc;

          return acc + 1;
        }, 0) === elementInSearch.length;

      if (currentSearch.length >= Static.minCharactersLength) {
        setIsOpen(!giveMeAccord);
      }
    };

    window.addEventListener('click', click);

    return () => {
      window.removeEventListener('click', click);
    };
  }, [currentSearch]);

  return (
    <div className="searchbar">
      <div id="searchbar__contain" className="searchbar__contain">
        <Icon
          config={{
            type: 'faMagnifyingGlassSolid',
            color: 'yang',
            size: 'medium',
          }}
        ></Icon>
        <input
          value={currentSearch}
          placeholder={t('searchForWhatYouWant?')}
          onInput={(e: any) => {
            handleSearch(e.target.value);
          }}
          className="searchbar__contain__input"
          type="text"
        />
        <Write
          className="searchbar__contain__limit"
          data={{
            item: t('CHARACTERS_MIN')
              .replace('[count]', `${charactersLength}`)
              .replace('[min]', `${Static.minCharactersLength}`),
          }}
          config={{ mode: 'input', wrap: false }}
        ></Write>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(() => false);
            setCurrentSearch(() => '');
            setCharactersLength(() => 0);
          }}
          className="searchbar__contain__close"
        >
          <Icon
            config={{
              type: 'faXmarkSolid',
              color: 'yang',
              size: 'medium',
            }}
          ></Icon>
        </div>
      </div>
      {isOpen && (
        <div id="searchbar__result" className="searchbar__result">
          <div className="searchbar__result__summary">
            {summaryItems.map(({ label, count, key }, index) => {
              return (
                <div
                  key={`searchbar__result__summary__item-${index}`}
                  onClick={() => setCurrentSelected(key)}
                  className={`searchbar__result__summary__item ${
                    key === currentSelected
                      ? 'searchbar__result__summary__item--select'
                      : ''
                  }`}
                >
                  <Write
                    data={{ item: label }}
                    config={{ mode: 'link' }}
                  ></Write>
                  <div className="searchbar__result__summary__item__count">
                    <span>{count}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <ListFlatInfo<any>
            className="searchbar__result__list"
            data={{
              list: summaryItems.find(({ key }) => key === currentSelected)!
                .contain,
              paging: {
                count: 4,
                current: 1,
                limit: 1,
              },
            }}
            handleEvent={{
              click: (select) => {
                const contract = results.contracts.find(
                  ({ _id }) => _id === select,
                );
                if (!!contract) {
                  navigate(`${contract.parkingId}/contracts/${select}`);
                } else {
                  navigate(`/motorists/${select}?localMotoristId=${select}`);
                }
              },
            }}
            config={{
              pagination: 'none',
              columns: [{ key: 'component', size: '1fr' }],
              border: true,
            }}
          />
        </div>
      )}
    </div>
  );
};
