import { useMutation, useRootStore, useTranslation } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Mutation, MutationCloseSessionArgs } from '../../client/graphql';
import { closeSessionGql } from '../../common/gql/session-close.gql';
import {
  ActionInline,
  ActionInlineType,
} from '../../components/action-inline/action-inline.component';

export declare namespace SessionActionType {
  type Props = {
    config: {
      size: ActionInlineType.Config.Size;
    };
    data: {
      sessionId: string;
      motoristId: string;
      contractId: string;
      parkingId: string;
    };
  };
}

export const SessionAction = observer(
  ({
    config: { size },
    data: { sessionId, motoristId, contractId, parkingId },
  }: SessionActionType.Props) => {
    const { AppStore } = useRootStore();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const actions: ActionInlineType.Data[] = [
      {
        key: 'showContractOfMotorist',
        text: t('showMotoristContract'),
        color: 'label',
        event: () => navigate(`/${parkingId}/contracts/${contractId}`),
        show: !!contractId,
      },
    ];

    const [closeSessionCall] = useMutation<
      { closeSession: Mutation['closeSession'] },
      MutationCloseSessionArgs
    >(closeSessionGql);

    if (
      AppStore.version?.env === 'LOCAL' ||
      AppStore.version?.env === 'develop'
    )
      actions.push({
        key: 'stopSession',
        text: t('stopSession'),
        color: 'error',
        isCritical: true,
        event: async () => {
          try {
            await closeSessionCall({
              variables: { sessionId },
            });
          } catch (error) {
            console.error(error);
          }
        },
      });

    return <ActionInline config={{ size }} data={actions} />;
  },
);
