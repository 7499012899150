import { useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import { MotoristsCreateFormType } from '../../form/motorists-create/motorists-create.form';
import { ConfirmationStep } from './steps/confirmation/confirmation.step';
import { MotoristsStep } from './steps/motorists/motorists.step';
import { SummaryStep } from './steps/summary/summary.step';

export type CreateSeveralMotoristsContext = {
  motorists: {
    created: MotoristsCreateFormType.Data.Created[];
  };
};

export const CreateSeveralMotoristsWizard = observer(() => {
  const { t, lang } = useTranslation();
  const { WizardStore } = useRootStore();

  return (
    <Wizard<CreateSeveralMotoristsContext>
      config={{
        title: t(`createSeveralMotorists`),
      }}
      data={{
        defaultContext: {
          motorists: {
            created: [],
          },
        },
      }}
      handleEvent={{
        done: async (data) => {
          console.log('done', { data });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item
        config={{ title: t('motorists'), component: MotoristsStep }}
      />
      <Wizard.Item
        config={{ title: t('confirmation'), component: ConfirmationStep }}
      />
      <Wizard.Item config={{ title: t('summary'), component: SummaryStep }} />
    </Wizard>
  );
});
