import {
  DatePicker,
  FilterReduced,
  Form,
  IconType,
  ListFlatInfo,
  Select,
  Widget,
  useLazyQuery,
  useMyUrl,
  useQuery,
  useTranslation,
} from '@gimlite/watermelon';
import { DocumentNode } from 'graphql';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Query,
  QueryContractSessionsArgs,
  QueryParkingArgs,
  QuerySessionsArgs,
} from '../../client/graphql';
import { parkingGql } from '../../common/gql/parking.gql';
import { sessionsGql } from '../../common/gql/sessions.gql';
import { SessionLine } from '../line/session.line';

export type SessionListProps = {
  parkingId?: string;
  motoristId?: string;
  contractId?: string;
  category?: string;
  widget?: {
    title?: string;
    icon?: IconType.Config.Type;
  };
  setSelectedSession?: (id: string) => void;
  selectedSession?: string;
  wsEnabled?: boolean;
  pageSize?: number;
  overrideGQL?: DocumentNode;
};

export const SessionList = ({
  parkingId,
  motoristId,
  contractId,
  category,
  widget,
  setSelectedSession,
  wsEnabled = true,
  pageSize = 50,
  overrideGQL,
}: SessionListProps) => {
  const { t, lang } = useTranslation();
  const { getParamsUrl, setParamsUrl } = useMyUrl<{
    dateRangeStart?: string;
    dateRangeEnd?: string;
    status?: string;
    type?: string;
    device?: string;
    sessionPage: number;
    sessionLimit: number;
    motoristId?: string;
    archived: string;
    parkingId: string;
  }>();
  const { sessionId } = useParams();

  const queryParking = useQuery<
    {
      parking: Query['parking'];
    },
    QueryParkingArgs
  >(parkingGql, {
    variables: {
      parkingId: parkingId || '',
    },
  });

  const parking = useMemo(() => {
    if (!queryParking.data) return null;

    return queryParking.data.parking;
  }, [queryParking.data]);

  useEffect(() => {
    setParamsUrl((existParamsUrl) => ({
      ...existParamsUrl,
      dateRangeStart: undefined,
      type: 'all',
      dateRangeEnd: undefined,
      archived: 'active',
      sessionPage: 1,
      sessionLimit: pageSize,
      motoristId,
      parkingId,
      contractId,
    }));
  }, [motoristId, parkingId, pageSize, contractId]);

  // const { list, paging, setSearch } = useSearchOf<
  //   Session,
  //   { sessionPage: number; sessionLimit: number }
  // >({
  //   gql: overrideGQL || sessionsGql,
  //   wsSubscriptions: wsEnabled ? ['sessions:created', 'sessions:updated'] : [],
  //   limit: pageSize,
  //   queryBuilder: () => ({
  //     parkingId: parkingId,
  //     contractId: contractId,
  //     category: category,
  //   }),
  // });

  const [sessionsCall, sessionsState] = useLazyQuery<
    | {
        sessions: Query['sessions'];
      }
    | { contractSessions: Query['contractSessions'] },
    QuerySessionsArgs | QueryContractSessionsArgs
  >(overrideGQL || sessionsGql, {
    socket: {
      'sessions:created': () => true,
      'sessions:updated': () => true,
    },
  });

  const sessions = useMemo(() => {
    if (!sessionsState.data) return null;

    return 'sessions' in sessionsState.data
      ? sessionsState.data.sessions
      : sessionsState.data?.contractSessions;
  }, [sessionsState.data]);

  console.log('sessions', { sessions });

  const {
    status,
    type,
    device,
    sessionPage,
    sessionLimit,
    dateRangeStart,
    dateRangeEnd,
  } = useMemo(() => {
    const {
      status,
      type,
      device,
      sessionPage,
      sessionLimit,
      dateRangeStart,
      dateRangeEnd,
    } = getParamsUrl;

    return {
      motoristId,
      parkingId,
      sessionPage,
      sessionLimit,
      dateRangeStart:
        !dateRangeStart || dateRangeStart === 'null'
          ? undefined
          : Array.isArray(dateRangeStart)
            ? dateRangeStart.filter(
                (dateRangeStart: any) => dateRangeStart !== null,
              )
            : [dateRangeStart],
      dateRangeEnd: !dateRangeEnd
        ? undefined
        : Array.isArray(dateRangeEnd)
          ? dateRangeEnd.filter((dateRangeEnd: any) => dateRangeEnd !== null)
          : [dateRangeEnd],
      status: !status
        ? undefined
        : Array.isArray(status)
          ? status.filter((status) => status !== null)
          : [status],
      type:
        !type || type === 'all'
          ? undefined
          : Array.isArray(type)
            ? type.filter((type) => type !== 'all')
            : [type],
      device: !device
        ? undefined
        : Array.isArray(device)
          ? device.filter((device) => device !== null)
          : [device],
    };
  }, [getParamsUrl]);

  useEffect(() => {
    console.log('🚀 ~ useEffect ~ contractId:', contractId);

    sessionsCall({
      variables: {
        page: sessionPage || 1,
        limit: sessionLimit || pageSize,
        status,
        type,
        dateRangeStart,
        dateRangeEnd,
        device,
        parkingId,
        motoristId,
        contractId,
        category,
      },
    });
  }, [
    status,
    type,
    device,
    sessionPage,
    sessionLimit,
    parkingId,
    contractId,
    motoristId,
    dateRangeStart,
    dateRangeEnd,
    sessionsCall,
    pageSize,
    category,
  ]);

  return (
    <Widget
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
        icon: widget?.icon,
      }}
      state={{
        loading: sessionsState.loading,
        error: sessionsState.error,
        refetch: sessionsState.refetch,
        showPermanentRefetch: false,
      }}
    >
      <ListFlatInfo<any>
        handleEvent={{
          paging: (paging) => {
            setParamsUrl({
              ...getParamsUrl,
              sessionPage: paging.page,
              sessionLimit: paging.limit,
            });
          },
          click: setSelectedSession,
        }}
        data={{
          defaultSelected: sessionId || '',
          list: sessions
            ? sessions.list.map(
                ({
                  _id,
                  motorist,
                  entryDate,
                  startDate,
                  exitDate,
                  product,
                  contractId,
                  isInconsistent,
                  parking,
                  amount,
                  events,
                  currency,
                }) => {
                  const {
                    firstName,
                    lastName,
                    _id: motoristId,
                  } = motorist || {};

                  const getEventByState = (state: string) =>
                    events.find((event) => event.state === state);

                  const enteredEvent = getEventByState('ENTERED');
                  const authorizedEvent = getEventByState('ENTRY_AUTHORIZED');
                  const deniedEvent = getEventByState('ENTRY_DENIED');

                  let entryDateFormatted: string = '';

                  if (enteredEvent) {
                    entryDateFormatted = entryDate || enteredEvent?.createdAt;
                  } else if (authorizedEvent) {
                    entryDateFormatted = startDate || authorizedEvent.createdAt;
                  } else if (deniedEvent) {
                    entryDateFormatted = startDate || deniedEvent.createdAt;
                  }

                  return {
                    _id,
                    items: {
                      component: _id && (
                        <SessionLine
                          data={{
                            sessionId: _id,
                            firstName: firstName || null,
                            lastName: lastName || null,
                            productName: product?._id
                              ? t(`product-${product._id}-name`)
                              : null,
                            exitDate: exitDate || '',
                            entryDate: entryDateFormatted,
                            motoristId: motoristId || '',
                            contractId: contractId || '',
                            parkingId: parking._id,
                            isInconsistent: isInconsistent || false,
                            events: events.map((event) => {
                              const accesspoint = parking?.accessPoints?.find(
                                (access) =>
                                  access && access._id === event.accessPointId,
                              );

                              return {
                                ...event,
                                currency: currency,
                                accessPointCode: accesspoint?.shortCode,
                                createdAt: DateTime.fromISO(
                                  event.createdAt,
                                ).toLocaleString(
                                  DateTime.DATETIME_SHORT_WITH_SECONDS,
                                ),
                              };
                            }),
                          }}
                        />
                      ),
                    },
                  };
                },
              )
            : undefined,
          paging: sessions?.paging,
        }}
        config={{
          pagination: 'scroll',
          columns: [
            { key: 'component', label: t('identity'), size: '1fr' },
            { key: 'component', label: t('entryDate'), size: '1fr' },
            { key: 'component', label: t('exitDate'), size: '1fr' },
          ],
          header: true,
          filter: (
            <FilterReduced
              data={{
                value: {
                  dateRangeStart: getParamsUrl.dateRangeStart || undefined,
                  dateRangeEnd: getParamsUrl.dateRangeEnd || undefined,
                  status: getParamsUrl.status || undefined,
                  type: getParamsUrl.type || undefined,
                  device: getParamsUrl.device || undefined,
                },
              }}
              handleEvent={{
                submit: ({ dateRangeStart, dateRangeEnd, ...rest }) => {
                  setParamsUrl({
                    ...getParamsUrl,
                    ...rest,
                    dateRangeStart: dateRangeStart || undefined,
                    dateRangeEnd: dateRangeEnd || undefined,
                    sessionPage: 1,
                  });
                },
                clear: () => {
                  setParamsUrl({
                    ...getParamsUrl,
                    dateRangeStart: undefined,
                    dateRangeEnd: undefined,
                    type: 'all',
                    status: undefined,
                    device: undefined,
                    sessionPage: 1,
                    sessionLimit: 50,
                  });
                },
              }}
            >
              <Form.Item
                config={{
                  labelLimitation: true,
                  name: 'dateRangeStart',
                  label: t('dateRangeStart'),
                  way: 'vertical',
                  rules: [],
                }}
                data={{
                  defaultValue: getParamsUrl.dateRangeStart || undefined,
                }}
              >
                <DatePicker
                  config={{ format: 'datetime', mode: 'range', clear: true }}
                />
              </Form.Item>
              <Form.Item
                config={{
                  labelLimitation: true,
                  name: 'dateRangeEnd',
                  label: t('dateRangeEnd'),
                  way: 'vertical',
                  rules: [],
                }}
                data={{ defaultValue: getParamsUrl.dateRangeEnd || undefined }}
              >
                <DatePicker
                  config={{ format: 'datetime', mode: 'range', clear: true }}
                />
              </Form.Item>
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('status'),
                  name: 'status',
                }}
                data={{ defaultValue: getParamsUrl.status }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false, mode: 'multiple' }}
                  data={{
                    items: [
                      {
                        label: t('running'),
                        value: 'running',
                      },
                      {
                        label: t('closed'),
                        value: 'closed',
                      },
                      {
                        label: t('incident'),
                        value: 'incident',
                      },
                    ],
                  }}
                />
              </Form.Item>
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('type'),
                  name: 'type',
                }}
                data={{ defaultValue: getParamsUrl.type || 'all' }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false }}
                  data={{
                    items: [
                      {
                        label: t('all'),
                        value: 'all',
                      },
                      {
                        label: t('pay-per-use'),
                        value: 'pay-per-use',
                      },
                      {
                        label: t('contract'),
                        value: 'contract',
                      },
                    ],
                  }}
                />
              </Form.Item>
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('devices'),
                  name: 'device',
                }}
                data={{ defaultValue: getParamsUrl.device }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false, mode: 'multiple' }}
                  data={{
                    items:
                      parking && parking.accessPoints
                        ? parking.accessPoints.map((access) => ({
                            label: access?.shortCode,
                            value: access?._id,
                          }))
                        : [],
                  }}
                />
              </Form.Item>
            </FilterReduced>
          ),
        }}
      />
    </Widget>
  );
};
