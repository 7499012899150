import { gql } from '@apollo/client';

export const productGQL = gql`
  query product($productId: String!) {
    product(productId: $productId) {
      _id
      name
      category
      parameters {
        durationValue
        durationUnit
        cents
        currency
        contractTag
      }
      rules {
        cycleControl {
          pastDaysCount
          maxOpenedSessions
        }
        credentialsTypes {
          types {
            type
            provider
          }
        }
      }
      externalPayload {
        ArticleCode
      }
      createdAt
    }
  }
`;
