import {
  FilterReduced,
  Form,
  IconType,
  Input,
  ListFlatInfo,
  Select,
  Widget,
  useLazyQuery,
  useMyUrl,
  useTranslation,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Query, QueryLocalMotoristsArgs } from '../../client/graphql';
import { localMotoristsGql } from '../../common/gql/local-motorists.gql';
import { anonimizedEmail } from '../../common/utils/anonymise-email';
import { MotoristLine } from '../line/motorist.line';

type MotoristListProps = {
  widget?: {
    title?: string;
    icon?: IconType.Config.Type;
  };
  setSelectedMotorist?: (id: string) => void;
};

export const MotoristList = ({
  widget,
  setSelectedMotorist,
}: MotoristListProps) => {
  const { getParamsUrl, setParamsUrl } = useMyUrl<{
    motoristPage: number;
    motoristLimit: number;
    isLinked: 'all' | 'linked' | 'unlinked';
    left: 'active' | 'left';
    localMotoristId: string;
  }>();

  const urlMotoristId = useParams().motoristId;
  const { t } = useTranslation();
  const [localMotoristsCall, localMotoristsState] = useLazyQuery<
    {
      localMotorists: Query['localMotorists'];
    },
    QueryLocalMotoristsArgs
  >(localMotoristsGql, {
    socket: {
      'localmotorist:created': () => true,
      'localmotorist:updated': (data) => {
        return true;
      },
    },
  });

  const localMotorists = useMemo(() => {
    if (!localMotoristsState?.data?.localMotorists) return null;

    return localMotoristsState.data.localMotorists;
  }, [localMotoristsState.data]);

  const { isLinked, left, localMotoristId } = useMemo(() => {
    // get filters params here

    return {
      isLinked: getParamsUrl.isLinked || 'all',
      left: getParamsUrl.left,
      localMotoristId: getParamsUrl.localMotoristId || undefined,
    };
  }, [getParamsUrl]);

  useEffect(() => {
    setParamsUrl({
      ...getParamsUrl,
      left: 'active',
      isLinked: isLinked || 'all',
      motoristPage: 1,
      motoristLimit: 50,
    });

    return () => {
      setParamsUrl({
        motoristPage: 1,
        motoristLimit: 50,
      });
    };
  }, []);

  useEffect(() => {
    localMotoristsCall({
      variables: {
        limit: getParamsUrl.motoristLimit || 50,
        page: getParamsUrl.motoristPage || 1,
        left: getParamsUrl.left,
        isLinked: getParamsUrl.isLinked,
        localMotoristId: getParamsUrl.localMotoristId || undefined,
        order: 'lastName',
      },
    });
  }, [getParamsUrl]);

  return (
    <Widget
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
        icon: widget?.icon,
      }}
      state={{
        loading: localMotoristsState.loading,
        error: localMotoristsState.error,
        refetch: localMotoristsState.refetch,
        showPermanentRefetch: false,
      }}
    >
      <ListFlatInfo<any>
        config={{
          pagination: 'scroll',
          columns: [
            { key: 'component', label: t('identity'), size: '1fr' },
            { key: 'component', label: t('details'), size: 'min-content' },
          ],
          header: true,
          filter: (
            <FilterReduced
              data={{
                value: {
                  isLinked: getParamsUrl.isLinked || undefined,
                  localMotoristId: getParamsUrl.localMotoristId || undefined,
                  left: getParamsUrl.left || false,
                },
              }}
              handleEvent={{
                submit: (submit) => {
                  setParamsUrl({ ...getParamsUrl, ...submit, motoristPage: 1 });
                },
                clear: () => {
                  setParamsUrl({
                    isLinked: 'all',
                    motoristPage: 1,
                    motoristLimit: 50,
                  });
                },
              }}
            >
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('motorist'),
                  name: 'isLinked',
                }}
                data={{ defaultValue: getParamsUrl.isLinked || 'all' }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false }}
                  data={{
                    items: [
                      {
                        label: t('all'),
                        value: 'all',
                      },
                      {
                        label: t('linked'),
                        value: 'linked',
                      },
                      {
                        label: t('unlinked'),
                        value: 'unlinked',
                      },
                    ],
                  }}
                />
              </Form.Item>
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('status'),
                  name: 'left',
                }}
                data={{ defaultValue: getParamsUrl.left || 'active' }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false }}
                  data={{
                    items: [
                      {
                        label: t('active'),
                        value: 'active',
                      },
                      {
                        label: t('left'),
                        value: 'left',
                      },
                    ],
                  }}
                />
              </Form.Item>
              {getParamsUrl.localMotoristId && (
                <Form.Item
                  config={{
                    labelLimitation: true,
                    label: t('selectedMotorist'),
                    name: 'selectedMotorist',
                  }}
                  data={{
                    defaultValue:
                      localMotorists?.list &&
                      localMotorists?.list[0]?.firstName &&
                      localMotorists?.list[0]?.lastName
                        ? `${localMotorists?.list[0]?.firstName} ${localMotorists?.list[0]?.lastName}`
                        : localMotorists?.list && localMotorists?.list[0]?.email
                          ? localMotorists?.list[0]?.email
                          : localMotorists?.list && localMotorists?.list[0]._id
                            ? localMotorists?.list[0]._id
                            : '-',
                  }}
                >
                  <Input
                    config={{
                      type: { name: 'text' },
                      placeholder: getParamsUrl.localMotoristId,
                      disabled: true,
                    }}
                  ></Input>
                </Form.Item>
              )}
            </FilterReduced>
          ),
        }}
        handleEvent={{
          paging: (paging) => {
            setParamsUrl({
              ...getParamsUrl,
              motoristPage: paging.page,
              motoristLimit: paging.limit,
            });
          },
          click: (motoristId) => setSelectedMotorist?.(motoristId),
        }}
        data={{
          defaultSelected: getParamsUrl.localMotoristId || urlMotoristId || '',
          list: localMotorists?.list
            ? localMotorists?.list.map((element, index) => {
                const {
                  _id,
                  firstName,
                  lastName,
                  email,
                  status,
                  inside,
                  plates,
                  cards,
                  uids,
                  linkedmotorist,
                  activeAndTotalCount,
                } = element;

                const credentialCount = [
                  ...plates,
                  ...uids,
                  ...cards,
                  ...(linkedmotorist?.credentials
                    ? linkedmotorist.credentials.filter(
                        ({ type }) => type === 'QRCODE',
                      )
                    : []),
                ].length;

                //anonimize email
                const anonEmail = email ? anonimizedEmail(email) : null;

                return {
                  _id,
                  items: {
                    component: (
                      <MotoristLine
                        data={{
                          firstName: firstName || null,
                          localMotoristId: _id,
                          lastName: lastName || null,
                          hasUnfinishedContract:
                            linkedmotorist &&
                            linkedmotorist.contracts &&
                            !!linkedmotorist.contracts.list.find(
                              ({ stopDate }) =>
                                stopDate &&
                                DateTime.fromISO(stopDate) > DateTime.now(),
                            )
                              ? true
                              : false,
                          activeContracts:
                            activeAndTotalCount && activeAndTotalCount.total > 0
                              ? [
                                  activeAndTotalCount.active,
                                  activeAndTotalCount.total,
                                ]
                              : undefined,
                          credentials: credentialCount,
                          email: anonEmail,
                          status:
                            status === 'VERIFIED' ? 'VERIFIED' : 'UNVERIFIED',
                          inside: inside || [],
                        }}
                      />
                    ),
                  },
                };
              })
            : undefined,
          paging: localMotorists?.paging,
        }}
      />
    </Widget>
  );
};
