import { useTranslation } from '@gimlite/watermelon';
import {
  ActionInline,
  ActionInlineType,
} from '../../components/action-inline/action-inline.component';

export declare namespace PoolAdminActionType {
  type Props = {
    config: {
      size: ActionInlineType.Config.Size;
    };
  };
}

export const PoolAdminAction = ({
  config: { size },
}: PoolAdminActionType.Props) => {
  const { t } = useTranslation();

  return (
    <ActionInline
      config={{ size }}
      data={
        [
          // {
          //   key: 'editPoolAdmin',
          //   text: t('editPoolAdmin'),
          //   color: 'label',
          //   event
          // },
          // {
          //   key: 'deletePoolAdmin',
          //   text: t('deletePoolAdmin'),
          //   color: 'error',
          //   event,
          //   isCritical: true
          // }
        ]
      }
    />
  );
};
