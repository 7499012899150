import { gql } from '@apollo/client';

export const createLocalMotoristGQL = gql`
  mutation createLocalMotorist($input: CreateLocalMotoristsDto!) {
    createLocalMotorist(input: $input) {
      motorist {
        _id
        firstName
        lastName
        email
        lang
        status
        linkedMotoristId
      }
    }
  }
`;
