import { useLazyQuery } from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  PoolContractEntity,
  Query,
  QueryGlobalSearchArgs,
  QueryPoolContractAndSubArgs,
  QueryProductArgs,
  QuerySubContractsArgs,
} from '../../../client/graphql';
import { ContractSubPoolLine } from '../../../composition/line/contract-sub-pool.line';
import {
  SingleCheckForm,
  SingleCheckFormType,
} from '../../../form/single-check/single-check.form';
import { AddOneMotoristToAnExistingPoolContractWizardContext } from '../../../wizards/addOneMotoristToAnExistingPoolContract/addOneMotoristToAnExistingPoolContract.wizard';
import { AddSeveralMotoristsToAnExistingPoolContractWizardContext } from '../../../wizards/addSeveralMotoristsToAnExistingPoolContract/addSeveralMotoristsToAnExistingPoolContract.wizard';
import { globalSearchGQL } from '../../gql/globalSearch.gql';
import { poolContractAndSubGQL } from '../../gql/poolContractAndSub.gql';
import { productGQL } from '../../gql/product.gql';
import { subContractsGql } from '../../gql/subContracts.gql';
import { convertCredentialsBackToFrontName } from '../../mapper/credential.mapper';
import { APBModeDetectWithCycleControl } from '../../mapper/product.mapper';

export const SubContractStep = ({
  context: {
    subPoolContractId,
    subPoolExistedSave,
    masterPoolContractId,
    contract,
  },
  validate,
  submit,
}: WizardItemType.Config.Component<
  | AddSeveralMotoristsToAnExistingPoolContractWizardContext
  | AddOneMotoristToAnExistingPoolContractWizardContext
>) => {
  const params = { order: 'name1', linkedContractId: masterPoolContractId };
  const pageLimit = 50;

  const { t, lang } = useTranslation();

  const [searchSubPoolContractResult, setSearchSubPoolContractResult] =
    useState<PoolContractEntity[] | null>(null);
  const [selectSubPoolId, setSelectSubPoolId] = useState<string>();

  const [subContractsCall, subContractsQuery] = useLazyQuery<
    { subContracts: Query['subContracts'] },
    QuerySubContractsArgs
  >(subContractsGql, {
    fetchPolicy: 'network-only',
  });

  const [globalSearchCall] = useLazyQuery<
    { globalSearch: Query['globalSearch'] },
    QueryGlobalSearchArgs
  >(globalSearchGQL, {
    fetchPolicy: 'network-only',
  });

  const [poolContractAndSubCall] = useLazyQuery<
    { poolContractAndSub: Query['poolContractAndSub'] },
    QueryPoolContractAndSubArgs
  >(poolContractAndSubGQL, {
    fetchPolicy: 'network-only',
  });

  const [productCall] = useLazyQuery<
    { product: Query['product'] },
    QueryProductArgs
  >(productGQL);

  const subContractFormattedLine = useCallback(
    (contracts: PoolContractEntity[]) => {
      return contracts.map(
        ({
          _id,
          name1,
          name2,
          isEnabled,
          ospContractId,
          spacesAllocated,
          contractCount,
          spacesOccupied,
          reference,
        }) => ({
          _id,
          items: {
            component: (
              <ContractSubPoolLine
                config={{
                  action: false,
                }}
                data={{
                  code: ospContractId,
                  name1: name1!,
                  name2: name2 || null,
                  status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                  carCounter: [spacesOccupied || 0, spacesAllocated!],
                  classification: reference,
                  contractCounter: contractCount,
                }}
              />
            ),
          },
        }),
      );
    },
    [],
  );

  const subContracts = useMemo(() => {
    if (!subContractsQuery?.data?.subContracts) return null;
    return subContractsQuery.data.subContracts;
  }, [subContractsQuery]);

  const handleSearch = useCallback(
    async (value: string) => {
      if (value && value.length >= 3) {
        const queryGlobalSearch = await globalSearchCall({
          variables: { content: value, linkedContractId: masterPoolContractId },
        });

        const contractsNotFormatted =
          queryGlobalSearch.data?.globalSearch.contracts;

        if (!contractsNotFormatted) return;

        const subPoolContract = contractsNotFormatted.filter(
          (element) => element.category === 'TENANT_POOL',
        ) as unknown as PoolContractEntity[];

        setSearchSubPoolContractResult(() => subPoolContract);

        submit({
          subPoolExistedSave: [
            ...subPoolExistedSave,
            ...subPoolContract,
          ].reduce((acc: PoolContractEntity[], { _id, ...rest }) => {
            if (!acc.find((element) => element._id === _id)) {
              return [...acc, { _id, ...rest }];
            }
            return acc;
          }, []),
        });

        await subContractsCall({
          variables: { page: 1, limit: pageLimit, ...params },
        });
      } else {
        setSearchSubPoolContractResult(() => null);
      }
    },
    [searchSubPoolContractResult, masterPoolContractId],
  );

  const { fresh, paging: pagingFormatted } = useMemo((): {
    fresh: PoolContractEntity[] | undefined;
    paging?: SingleCheckFormType.Props['data']['paging'];
  } => {
    if (!subContracts?.list) {
      return { fresh: undefined };
    } else {
      const listTarget = !searchSubPoolContractResult
        ? subContracts.list
        : searchSubPoolContractResult;

      return {
        fresh: listTarget,
        paging: !searchSubPoolContractResult
          ? subContracts?.paging
          : {
              count: searchSubPoolContractResult.length,
              current: 1,
              limit: searchSubPoolContractResult.length,
            },
      };
    }
  }, [subContracts?.list, subContracts?.paging, searchSubPoolContractResult]);

  useEffect(() => {
    if (subPoolContractId && subPoolContractId !== selectSubPoolId) {
      setSelectSubPoolId(() => subPoolContractId);
      (async () => {
        const currentContractData = await poolContractAndSubCall({
          variables: {
            contractId: subPoolContractId,
          },
        });
        if (!currentContractData?.data?.poolContractAndSub) return;

        const currentContract = currentContractData.data.poolContractAndSub;

        const productData = await productCall({
          variables: {
            productId: currentContract.productId,
          },
        });

        if (!productData?.data?.product) return;

        const rules = productData.data.product?.rules;

        submit({
          product: {
            id: currentContract.productId,
            credentials: rules?.credentialsTypes?.types
              ? rules.credentialsTypes.types
                  .map((item) => convertCredentialsBackToFrontName(item))
                  .filter((item) => item !== null)
              : [],
          },
          parentContract: {
            capacity: currentContract.spacesAllocated || null,
            reference: currentContract.reference || null,
            description: currentContract.description || null,
            startDate: currentContract.startDate,
            endDate: currentContract.stopDate,
            APBMode: APBModeDetectWithCycleControl(
              currentContract?.rules?.cycleControl || null,
            ),
            APBNext: null,
            state: currentContract.isEnabled,
          },
          contract: {
            ...contract,
            startDate: currentContract.startDate,
            endDate: currentContract.stopDate,
            APBMode: APBModeDetectWithCycleControl(
              currentContract?.rules?.cycleControl || null,
            ),
          },
        });
      })();
    }
  }, [fresh, contract, subPoolContractId, subPoolExistedSave]);

  useEffect(() => {
    const newList = subPoolExistedSave;
    if (subContracts?.list) {
      subContracts.list.forEach(({ _id, ...rest }) => {
        if (
          subPoolExistedSave.findIndex((element) => element._id === _id) === -1
        ) {
          newList.push({ _id, ...rest });
        }
      });
    }

    submit({
      subPoolExistedSave: newList,
    });
  }, [subContracts?.list, subPoolExistedSave]);

  useEffect(() => {
    subContractsCall({
      variables: { page: 1, limit: pageLimit, ...params },
    });
  }, []);

  useEffect(() => {
    validate(!!subPoolContractId);
  }, [subPoolContractId]);

  return (
    <Zone
      config={{
        gap: {
          y: 0,
          x: 0,
        },
        zones: [['form']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'form' }}>
        <Widget
          config={{
            title: t('selectASubPoolContract'),
            shadow: false,
          }}
          state={{
            error: subContractsQuery.error,
            loading: subContractsQuery.loading,
            refetch: subContractsQuery.refetch,
            showPermanentRefetch: false,
          }}
        >
          <SingleCheckForm
            handleEvent={{
              paging: async (data) => {
                await subContractsCall({
                  variables: { page: data.page, limit: data.limit, ...params },
                });
              },
              checkItem: (id) => {
                submit({ subPoolContractId: id });
              },
              search: handleSearch,
            }}
            data={{
              check: subPoolContractId,
              fresh: fresh ? subContractFormattedLine(fresh) : fresh,
              saved: subContractFormattedLine(subPoolExistedSave),
              paging: pagingFormatted,
            }}
            config={{
              searchPlaceholder: t('searchASubPoolContract'),
            }}
          />
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
