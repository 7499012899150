import { gql } from '@apollo/client';

export const poolContractAndSubGQL = gql`
  query poolContractAndSub($contractId: String!) {
    poolContractAndSub(contractId: $contractId) {
      _id
      name1
      name2
      isEnabled
      ospContractId
      presenceCounter
      spacesAllocated
      spacesOccupied
      contractCount
      productId
      startDate
      stopDate
      reference
      description
      tenantContracts {
        _id
      }
      rules
    }
  }
`;
