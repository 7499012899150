import {
  Button,
  Col,
  Empty,
  Form,
  Header,
  Icon,
  Input,
  Loading,
  Space,
  Widget,
  Write,
  Zone,
  digitRule,
  lowercaseLetterRule,
  minLengthPasswordRule,
  specialCharRule,
  uppercaseLetterRule,
  useLazyQuery,
  useMutation,
  useMyUrl,
  useTranslation,
} from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import OspFull from '../../assets/logos/osp_full';
import {
  Mutation,
  MutationVerifyAndUpdateMotoristArgs,
  Query,
  QueryVerifyMotoristArgs,
} from '../../client/graphql';
import { verifyAndUpdateMotoristGQL } from '../../common/gql/verifyAndUpdateMotorist.gql';
import { verifyMotoristGQL } from '../../common/gql/verifyMotorist.gql';
import { config } from '../../config';
import './motorist-confirm.scss';

export const MotoristConfirm = observer(
  ({ withHeader }: { withHeader: boolean }) => {
    const { t } = useTranslation();
    const { getParamsUrl } = useMyUrl();
    const [redirect, setRedirect] = useState<boolean>(false);
    const [inLoad, setInLoad] = useState<boolean>(true);
    const [error, setError] = useState<'LINK' | 'TOKEN' | null>();
    const [passwordValidFormat, setPasswordValidForm] = useState(false);
    const [passwordValidSame, setPasswordValidSame] = useState(false);
    const [passwordValues, setPasswordValues] = useState<Object>({});

    const [verityMotoristCall, verityMotoristState] = useLazyQuery<
      {
        verifyMotorist: Query['verifyMotorist'];
      },
      QueryVerifyMotoristArgs
    >(verifyMotoristGQL);

    const [verifyAndUpdateMotoristCall, verifyAndUpdateMotoristState] =
      useMutation<
        {
          verifyAndUpdateMotorist: Mutation['verifyAndUpdateMotorist'];
        },
        MutationVerifyAndUpdateMotoristArgs
      >(verifyAndUpdateMotoristGQL, {
        notification: {
          success: t('thePasswordHasBeenRegistered'),
          error: t('thePasswordCouldNotBeRegistered'),
        },
      });

    const verifyMotorist = useCallback(
      async (data: QueryVerifyMotoristArgs) => {
        try {
          await verityMotoristCall({ variables: { token: data.token } });

          setRedirect(() => false);
          setError(() => null);
        } catch (error: any) {
          if (error?.message === 'MSAF_4') {
            setError(() => null);
            setRedirect(() => true);
          } else {
            setError(() => 'TOKEN');
          }
        } finally {
          setInLoad(() => false);
        }
      },
      [],
    );

    const verifyAndUpdateMotorist = useCallback(
      async ({
        token,
        motorist,
      }: MutationVerifyAndUpdateMotoristArgs['input']) => {
        try {
          await verifyAndUpdateMotoristCall({
            variables: {
              input: {
                token,
                motorist,
              },
            },
          });
        } finally {
          await verifyMotorist({ token });
        }
      },
      [getParamsUrl],
    );

    useEffect(() => {
      if (getParamsUrl.token) {
        verifyMotorist({ token: getParamsUrl.token as string });
      } else {
        setError(() => 'LINK');
        setInLoad(() => false);
      }
    }, [getParamsUrl]);

    return (
      <div className="motoristConfirm">
        {withHeader && <Header config={{ logo: OspFull() }}></Header>}
        {inLoad ? (
          <div className="motoristConfirm__load">
            <Loading></Loading>
            <Write
              className="motoristConfirm__load__text"
              data={{ item: t('waitingForServerResponse') }}
              config={{ mode: 'value-medium' }}
            ></Write>
          </div>
        ) : error ? (
          <div className="motoristConfirm__error">
            <Icon
              config={{
                type: 'faCircleExclamationSolid',
                color: 'error',
                size: 'giga',
              }}
            ></Icon>
            <Write
              className="motoristConfirm__error__text"
              data={{
                item: error === 'LINK' ? t('fakeLink') : t('fakeToken'),
              }}
              config={{ mode: 'value-large', color: 'error' }}
            ></Write>
          </div>
        ) : redirect ? (
          <div className="motoristConfirm__redirect">
            <div
              onClick={() => {
                if (config.FRONT_OSP_MOTORISTS_HOST) {
                  window.location.replace(config.FRONT_OSP_MOTORISTS_HOST);
                }
              }}
              className="motoristConfirm__redirect__contain"
            >
              <Write
                className="motoristConfirm__redirect__contain__text"
                data={{
                  item: t('goToMotoristPortal'),
                }}
                config={{ mode: 'value-large', color: 'black' }}
              ></Write>
              <Icon
                config={{
                  type: 'faArrowRightSolid',
                  color: 'black',
                  size: 'medium',
                }}
              ></Icon>
            </div>
          </div>
        ) : (
          <Zone
            className="motoristConfirm__contain"
            config={{
              zones: [
                ['identity', 'auth'],
                ['identity', 'empty'],
              ],
              rows: ['min-content'],
              columns: ['1fr', '1fr'],
            }}
          >
            <Zone.Area
              config={{
                area: 'identity',
              }}
            >
              <Widget config={{ title: t('confirmYourIdentity') }}>
                <Empty config={{ mode: { name: 'commingSoon' } }}></Empty>
              </Widget>
            </Zone.Area>

            <Zone.Area
              config={{
                area: 'empty',
              }}
            >
              <></>
            </Zone.Area>

            <Zone.Area
              config={{
                area: 'auth',
              }}
            >
              <Widget config={{ title: t('authentication') }}>
                <Form
                  config={{
                    validateOnChange: true,
                  }}
                  data={{
                    value: passwordValues,
                  }}
                  handleEvent={{
                    submit: async (value) => {
                      await verifyAndUpdateMotorist({
                        motorist: {
                          password: value.confirmNewPassword,
                        },
                        token: getParamsUrl.token,
                      });
                    },
                    validate: (isValid) => {
                      setPasswordValidForm(() => isValid);
                    },
                    change: (values) => {
                      setPasswordValues(() => values);
                      setPasswordValidSame(
                        () => values.newPassword === values.confirmNewPassword,
                      );
                    },
                  }}
                >
                  <Form.Item
                    config={{
                      name: 'newPassword',
                      label: t('newPassword'),
                      way: 'vertical',
                      rules: [
                        { required: true },
                        {
                          validator: minLengthPasswordRule,
                        },
                        {
                          validator: uppercaseLetterRule,
                        },
                        {
                          validator: lowercaseLetterRule,
                        },
                        {
                          validator: digitRule,
                        },
                        {
                          validator: specialCharRule,
                        },
                      ],
                    }}
                  >
                    <Input config={{ type: { name: 'password' } }}></Input>
                  </Form.Item>
                  <Space config={{ way: 'vertical', count: 1 }}></Space>
                  <Form.Item
                    config={{
                      name: 'confirmNewPassword',
                      label: t('confirmNewPassword'),
                      way: 'vertical',
                      rules: [
                        { required: true },
                        {
                          validator: minLengthPasswordRule,
                        },
                        {
                          validator: uppercaseLetterRule,
                        },
                        {
                          validator: lowercaseLetterRule,
                        },
                        {
                          validator: digitRule,
                        },
                        {
                          validator: specialCharRule,
                        },
                      ],
                    }}
                  >
                    <Input config={{ type: { name: 'password' } }}></Input>
                  </Form.Item>
                  <Space config={{ way: 'vertical', count: 2 }}></Space>
                  <Button
                    config={{
                      text: t('registerPassword'),
                      type: { value: 'submit' },
                      disabled: !(passwordValidFormat && passwordValidSame),
                    }}
                  ></Button>
                  <Space config={{ way: 'vertical', count: 2 }} />

                  {passwordValidFormat && !passwordValidSame && (
                    <Col
                      config={{
                        width: 'full',
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                    >
                      <Write
                        data={{ item: t('passwordsAreDifferent') }}
                        config={{ color: 'error', mode: 'key-small-light' }}
                      />
                    </Col>
                  )}
                </Form>
              </Widget>
            </Zone.Area>
          </Zone>
        )}
      </div>
    );
  },
);
