import { useQuery, useRootStore } from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import {
  Wizard,
  WizardItemType,
} from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Query, QueryProductsArgs } from '../../../../client/graphql';
import { productsGql } from '../../../../common/gql/products.gql';
import { ChoiceCreateContractWizardContext } from '../../choiceCreateContract.wizard';

export const ActionStep = observer(
  ({
    context: { parkingId, actions, mode },
    validate,
    submit,
  }: WizardItemType.Config.Component<ChoiceCreateContractWizardContext>) => {
    const { t }: any = useTranslation();
    const { WizardStore } = useRootStore();

    const queryProducts = useQuery<
      { products: Query['products'] },
      QueryProductsArgs
    >(productsGql, {
      variables: {
        page: 1,
        limit: 10000,
        parkingId: parkingId!,
      },
    });

    const products = useMemo(() => {
      if (!queryProducts.data) return null;

      return queryProducts.data?.products;
    }, [queryProducts.data?.products]);

    const containsPool = products?.list.find(({ category }) =>
      category.includes('POOL'),
    );

    return (
      <Zone
        config={{
          zones: [['contain']],
          rows: ['1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'contain' }}>
          <Widget
            config={{
              shadow: false,
            }}
          >
            <Wizard.CardGroup
              config={{ title: t('selectAction'), mode: 'wrapper' }}
            >
              {actions.createNewSingleContract && (
                <Wizard.CardLink
                  handleEvent={{
                    click: () => {
                      WizardStore.select({
                        id: 'createNewSingleContract',
                        store: {
                          parkingId,
                        },
                      });
                    },
                  }}
                  config={{
                    title: t(`createANewSingleContract`),
                    icon: 'customCreateNewSingleContract',
                  }}
                />
              )}
              {containsPool && actions.createNewMasterPoolContract && (
                <Wizard.CardLink
                  handleEvent={{
                    click: () => {
                      WizardStore.select({
                        id: 'createNewMasterPoolContract',
                        store: {
                          parkingId,
                        },
                      });
                    },
                  }}
                  config={{
                    title: t(`createANewMasterPoolContract`),
                    icon: 'customCreateNewMasterPoolContract',
                  }}
                />
              )}
              {containsPool && actions.createNewSubPoolContract && (
                <Wizard.CardLink
                  handleEvent={{
                    click: () => {
                      WizardStore.select({
                        id: 'createNewSubPoolContract',
                        store: {
                          parkingId,
                          mode,
                        },
                      });
                    },
                  }}
                  config={{
                    title: t(`createANewSubPoolContract`),
                    icon: 'customCreateNewSubPoolContract',
                  }}
                />
              )}
              {containsPool &&
                actions.addOneMotoristToAnExistingPoolContract && (
                  <Wizard.CardLink
                    handleEvent={{
                      click: () => {
                        WizardStore.select({
                          id: 'addOneMotoristToAnExistingPoolContract',
                          store: {
                            parkingId,
                            mode,
                          },
                        });
                      },
                    }}
                    config={{
                      title: t(`addOneMotoristToAnExistingPoolContract`),
                      icon: 'customAddOneMotoristToAnExistingPoolContract',
                    }}
                  />
                )}
              {containsPool &&
                actions.addSeveralMotoristsToAnExistingPoolContract && (
                  <Wizard.CardLink
                    handleEvent={{
                      click: () => {
                        WizardStore.select({
                          id: 'addSeveralMotoristsToAnExistingPoolContract',
                          store: {
                            parkingId,
                            mode,
                          },
                        });
                      },
                    }}
                    config={{
                      title: t(`addSeveralMotoristsToAnExistingPoolContract`),
                      icon: 'customAddSeveralMotoristToAnExistingPoolContract',
                    }}
                  />
                )}
            </Wizard.CardGroup>
          </Widget>
        </Zone.Area>
      </Zone>
    );
  },
);
