import {
  Widget,
  Write,
  toCapitalizeCase,
  useMyUrl,
  useQuery,
  useTranslation,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import {
  ProductCategoryEnum,
  ProductEntity,
  Query,
  QueryProductsArgs,
} from '../../../client/graphql';
import { productsGql } from '../../../common/gql/products.gql';
import { convertCredentialsBackToFrontName } from '../../../common/mapper/credential.mapper';
import { APBModeDetectWithCycleControl } from '../../../common/mapper/product.mapper';
import { Product } from '../../../components/product/product.component';
import './product.list.scss';

const categorys: Array<{
  title: string;
  category: ProductCategoryEnum;
}> = [
  { title: 'forAVisitor', category: ProductCategoryEnum.Visitor },
  {
    title: 'forASingleContract',
    category: ProductCategoryEnum.PeriodSubscription,
  },
  { title: 'forAPoolContract', category: ProductCategoryEnum.OperatorPool },
];

export type ProductsWidgetProps = {
  parkingId: string;
};

export const ProductList = observer(({ parkingId }: ProductsWidgetProps) => {
  const { t, lang } = useTranslation();
  const { getParamsUrl, setParamsUrl } = useMyUrl<{
    productPage: number;
    productLimit: number;
  }>();

  const { data, loading, error, refetch } = useQuery<
    {
      products: Query['products'];
    },
    QueryProductsArgs
  >(productsGql, {
    variables: {
      parkingId,
      limit: getParamsUrl.productLimit || 100,
      page: getParamsUrl.productPage || 1,
    },
  });

  const products = useMemo(() => {
    if (!data) return null;

    return data.products;
  }, [data]);

  const contractsGroup = useMemo(
    () =>
      products
        ? categorys.reduce(
            (
              acc: Array<{ title: string; items: ProductEntity[] }>,
              { title, category },
            ) => [
              ...acc,
              {
                title,
                items: products.list
                  ? products.list.filter(
                      (product) => product.category === category,
                    )
                  : [],
              },
            ],
            [],
          )
        : [],
    [products, lang],
  );

  return (
    <Widget
      className="product-list"
      config={{
        title: 'Products',
        backtitle: true,
        extra: true,
      }}
      state={{
        loading,
        error,
        refetch,
        showPermanentRefetch: false,
      }}
    >
      <div className="product-list__contain">
        {contractsGroup
          .filter(({ items }) => items.length > 0)
          .map(({ items, title }, index) => (
            <div
              key={`product-list__contain__line-${index}`}
              className="product-list__contain__line"
            >
              <Write
                className="product-list__contain__line__title"
                config={{
                  mode: 'title-medium',
                }}
                data={{
                  item: t(title),
                }}
              />
              <div className="product-list__contain__line__product">
                {items.map(
                  (
                    {
                      _id,
                      parameters,
                      rules,
                      externalPayload,
                      createdAt,
                      category,
                    },
                    index,
                  ) => {
                    const APBMode = APBModeDetectWithCycleControl(
                      rules?.cycleControl || null,
                    );

                    return (
                      <div
                        key={`product-list__contain__line__product__item-${index}`}
                        className="product-list__contain__line__product__item"
                      >
                        <Product
                          data={{
                            _id,
                            title: t(`product-${_id}-name`),
                            description: t(`product-${_id}-description`),
                            price:
                              parameters?.cents &&
                              typeof parameters?.cents === 'number'
                                ? parameters.cents / 100
                                : null,
                            currency: parameters?.currency || null,
                            period: {
                              time: parameters?.durationUnit
                                ? t(parameters.durationUnit)
                                : null,
                              count: parameters?.durationValue || null,
                            },
                            witEngament: false,
                            advantage: [
                              {
                                label: t(
                                  `antiPassback${toCapitalizeCase(APBMode)}`,
                                ),
                                isPresent: !(APBMode === 'NONE'),
                              },
                            ],
                            credential:
                              rules?.credentialsTypes?.types &&
                              Array.isArray(rules?.credentialsTypes?.types)
                                ? rules.credentialsTypes.types
                                    .map((item) =>
                                      convertCredentialsBackToFrontName(item),
                                    )
                                    .filter((item) => item !== null)
                                : null,
                            info: {
                              articleCode: externalPayload?.ArticleCode || null,
                              createdAt:
                                DateTime.fromISO(createdAt).toFormat(
                                  'dd/MM/yy HH:mm:ss',
                                ),
                            },
                            category,
                          }}
                        />
                      </div>
                    );
                  },
                )}
              </div>
            </div>
          ))}
      </div>
    </Widget>
  );
});
