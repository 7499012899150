import { Form, FormItemType, Input, Write } from '@gimlite/watermelon';
import { useEffect, useState } from 'react';
import './ratio.component.scss';

export declare namespace RatioType {
  type Props = {
    data?: {
      defaultValue?: string[];
      value?: string[];
    };
    handleEvent?: {
      input?: (value: any) => void;
    };
  };
}

export function Ratio({
  handleEvent: { input } = {},
  value,
  onChange: onChangeFormItem,
}: FormItemType.Legacy<RatioType.Props>) {
  const [tuple, setTuple] = useState<string[]>([...value]);

  useEffect(() => {
    input?.(tuple);
    onChangeFormItem?.(tuple);
  }, [tuple]);

  if (!value || value.length !== 2) return <></>;

  return (
    <Form.Context.Consumer>
      {() => (
        <div style={{ display: 'flex' }}>
          {decodeInput(tuple[0], (value) => setTuple([`${value}`, tuple[1]]))}
          <Write
            data={{ item: ' / ' }}
            config={{ mode: 'input' }}
            className="ratio"
          />
          {decodeInput(tuple[1], (value) => setTuple([tuple[0], `${value}`]))}
        </div>
      )}
    </Form.Context.Consumer>
  );
}

function decodeInput(value: string, setTuple: (value: number) => void) {
  const editable = !value.startsWith('/');
  const n = editable ? value : value.replace('/', '');

  return (
    <Input
      data={{
        value: parseInt(n),
      }}
      config={{
        width: 'full',
        disabled: !editable,
        type: { name: 'number', min: 0 },
      }}
      handleEvent={{
        input(value) {
          if (typeof value === 'number') setTuple(value);
        },
      }}
    />
  );
}
