import { IconType, useQuery } from '@gimlite/watermelon';
import { ListFlatInfo } from '@gimlite/watermelon/components/list/list.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Query, QueryContractsArgs } from '../../client/graphql';
import { contractsGql } from '../../common/gql/contracts.gql';
import { ContractMotoristLine } from '../line/contract-motorist.line';

export type MotoristContractListProps = {
  parkingId?: string;
  motoristId?: string | null;
  freeflagId?: string;
  widget?: {
    title?: string;
    icon?: IconType.Config.Type;
  };
};

export const MotoristContractList = ({
  parkingId,
  motoristId,
  freeflagId,
  widget,
}: MotoristContractListProps) => {
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useQuery<
    {
      contracts: Query['contracts'];
    },
    QueryContractsArgs
  >(contractsGql, {
    variables: {
      parkingId,
      motoristId,
      freeflagId,
    },
  });

  const contracts = useMemo(() => {
    if (!data) return null;

    return data.contracts;
  }, [data]);

  return (
    <Widget
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
        icon: widget?.icon,
      }}
    >
      <ListFlatInfo<any>
        handleEvent={{
          click: (id) => {
            const selectedContract = contracts?.list.find(
              ({ _id }) => _id === id,
            );
            navigate(`/${selectedContract?.parkingId}/contracts/${id}`);
          },
        }}
        data={{
          paging: contracts?.paging,
          list: contracts?.list.map(
            ({
              _id,
              motorist,
              ospContractId,
              isInside,
              isEnabled,
              stopDate,
            }) => ({
              _id,
              items: {
                component: (
                  <ContractMotoristLine
                    data={{
                      id: _id,
                      firstName: motorist?.firstName || null,
                      lastName: motorist?.lastName || null,
                      status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                      isStopped: stopDate
                        ? DateTime.fromISO(stopDate)
                            .diffNow()
                            .as('milliseconds') < 0
                        : false,
                      contractId: ospContractId || null,
                      carCounter: [Number(isInside), 1],
                      motoristId: motorist?._id,
                    }}
                    config={{ context: 'flat' }}
                  />
                ),
              },
            }),
          ),
        }}
        config={{
          pagination: 'scroll',
          columns: [{ key: 'component', size: '1fr' }],
        }}
      />
    </Widget>
  );
};
