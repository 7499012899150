import {
  Button,
  Col,
  Popover,
  Row,
  useCSV,
  useLazyQuery,
} from '@gimlite/watermelon';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LocalMotoristsEntity,
  Query,
  QueryGlobalSearchArgs,
  QueryLocalMotoristsArgs,
} from '../../../../client/graphql';
import {
  DefaultCredentialsQrcodeWizard,
  DefaultMotoristWizard,
} from '../../../../common/defaultValue/wizard';
import { globalSearchGQL } from '../../../../common/gql/globalSearch.gql';
import { localMotoristsGql } from '../../../../common/gql/local-motorists.gql';
import { anonimizedEmail } from '../../../../common/utils/anonymise-email';
import { PopupInfo } from '../../../../components/popup-info/popup-info.component';
import { SwitcherText } from '../../../../components/switcher-text/switcher-text.component';
import { MotoristLine } from '../../../../composition/line/motorist.line';
import { ContractWizardInfos } from '../../../../composition/wizard-infos/contract.wizard-infos';
import { MotoristCreateForm } from '../../../../form/motorist-create/motorist-create.form';
import {
  MotoristCSVParams,
  MotoristsCreateFormType,
} from '../../../../form/motorists-create/motorists-create.form';
import {
  SingleCheckForm,
  SingleCheckFormType,
} from '../../../../form/single-check/single-check.form';
import { AddSeveralMotoristsToAnExistingPoolContractWizardContext } from '../../../addSeveralMotoristsToAnExistingPoolContract/addSeveralMotoristsToAnExistingPoolContract.wizard';
import { AddOneMotoristToAnExistingPoolContractWizardContext } from '../../addOneMotoristToAnExistingPoolContract.wizard';

const params = { order: 'lastName' };
const pageLimit = 50;

export const MotoristStep = ({
  context: {
    contract,
    credentials,
    motorist,
    motoristExistedSave,
    motoristMode,
    masterPoolContractId,
    selectExistMotorist,
    subPoolExistedSave,
    subPoolContractId,
    product: { id: productId, credentials: productCredentials },
  },
  validate,
  submit,
}: WizardItemType.Config.Component<AddOneMotoristToAnExistingPoolContractWizardContext>) => {
  const { t, lang } = useTranslation();

  const [searchLocalMotoristResult, setSearchLocalMotoristResult] = useState<
    LocalMotoristsEntity[] | null
  >(null);

  const [localMotoristsCall, localMotoristsQuery] = useLazyQuery<
    { localMotorists: Query['localMotorists'] },
    QueryLocalMotoristsArgs
  >(localMotoristsGql, {
    variables: {
      page: 1,
      limit: pageLimit,
    },
    socket: {
      'localmotorist:created': () => true,
      'localmotorist:updated': () => true,
    },
  });

  const [globalSearchCall, globalSearchQuery] = useLazyQuery<
    { globalSearch: Query['globalSearch'] },
    QueryGlobalSearchArgs
  >(globalSearchGQL);

  useEffect(() => {
    (async () => {
      try {
        await localMotoristsCall({
          variables: {
            page: 1,
            limit: pageLimit,
          },
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const globalSearch = useMemo(() => {
    if (!globalSearchQuery.data) return null;

    return globalSearchQuery.data?.globalSearch;
  }, [globalSearchQuery.data?.globalSearch]);

  const localMotorists = useMemo(() => {
    if (!localMotoristsQuery.data) return null;

    return localMotoristsQuery.data?.localMotorists;
  }, [localMotoristsQuery.data?.localMotorists]);

  const motoristFormattedLine = useCallback(
    (motorists: LocalMotoristsEntity[]) => {
      return motorists.map(({ _id, email, firstName, status, lastName }) => ({
        _id,
        items: {
          component: (
            <MotoristLine
              config={{
                icon: true,
                action: false,
              }}
              data={{
                email: email ? anonimizedEmail(email) : null,
                firstName: firstName || '',
                lastName: lastName || '',
                status: status === 'VERIFIED' ? 'VERIFIED' : 'UNVERIFIED',
              }}
            />
          ),
        },
      }));
    },
    [],
  );

  const { fresh, paging: pagingFormatted } = useMemo((): {
    fresh: LocalMotoristsEntity[] | undefined;
    paging?: SingleCheckFormType.Props['data']['paging'];
  } => {
    if (!localMotorists?.list) {
      return { fresh: undefined };
    } else {
      const listTarget = !searchLocalMotoristResult
        ? localMotorists.list
        : searchLocalMotoristResult;

      return {
        fresh: listTarget,
        paging: !searchLocalMotoristResult
          ? localMotorists.paging
          : {
              count: searchLocalMotoristResult.length,
              current: 1,
              limit: searchLocalMotoristResult.length,
            },
      };
    }
  }, [localMotorists?.list, localMotorists?.paging, searchLocalMotoristResult]);

  const handleSearch = useCallback(async (value: string) => {
    if (value && value.length >= 3) {
      const { data } = await globalSearchCall({
        variables: {
          content: value,
        },
      });

      const localMotorists = data?.globalSearch.localMotorists;

      setSearchLocalMotoristResult(() => localMotorists || []);
      submit({
        motoristExistedSave: [
          ...motoristExistedSave,
          ...(data?.globalSearch?.localMotorists || []),
        ].reduce((acc: LocalMotoristsEntity[], { _id, ...rest }) => {
          if (!acc.find((element) => element._id === _id)) {
            return [...acc, { _id, ...rest }];
          }
          return acc;
        }, []),
      });

      try {
        await localMotoristsCall({
          variables: {
            page: 1,
            limit: pageLimit,
          },
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      setSearchLocalMotoristResult(() => null);
    }
  }, []);

  useEffect(() => {
    if (motoristMode === 'exist') {
      const newList = motoristExistedSave;

      if (localMotorists?.list) {
        localMotorists.list.forEach(({ _id, ...rest }) => {
          if (
            motoristExistedSave.findIndex((element) => element._id === _id) ===
            -1
          ) {
            newList.push({ _id, ...rest });
          }
        });
      }

      submit({
        motoristExistedSave: newList,
      });
    }
  }, [localMotorists?.list, motoristExistedSave, motoristMode]);

  useEffect(() => {
    if (motoristMode === 'exist') {
      (async () => {
        try {
          await localMotoristsCall({
            variables: {
              page: 1,
              limit: pageLimit,
              order: 'lastName',
            },
          });
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [motoristMode]);

  useEffect(() => {
    if (
      !!!credentials.actived.find(({ name }) => name === 'QRCODE') &&
      productCredentials.find((item) => item === 'QRCODE') &&
      lang
    ) {
      submit({
        addFakeQRCODE: true,
        credentials: {
          ...credentials,
          actived: [
            DefaultCredentialsQrcodeWizard({
              t,
              lang,
            }),
            ...credentials.actived,
          ],
        },
      });
    }
  }, [credentials.actived, lang]);

  useEffect(() => {
    if (selectExistMotorist) {
      submit({
        motorist: {
          ...motorist,
          exist: [...(fresh ? fresh : []), ...motoristExistedSave].find(
            (element) => element._id === selectExistMotorist,
          )!,
        },
      });
    }
  }, [fresh, motoristExistedSave, selectExistMotorist]);

  useEffect(() => {
    if (motoristMode === 'exist') validate(!!motorist.exist);
  }, [motorist.exist, motoristMode]);

  const credentialsEdit = {
    RFID: productCredentials.includes('RFID'),
    PLATE: productCredentials.includes('PLATE'),
    TCS: productCredentials.includes('TCS'),
  };
  const CSVParams = MotoristCSVParams(credentialsEdit);
  const { firstName, lastName, username } = motorist.create;
  const { CombinationValidNode } = useCSV<MotoristsCreateFormType.Extra.CSV>({
    valid: CSVParams.valid,
    fields: CSVParams.fields.map(({ label, ...rest }) => ({
      ...rest,
      label: t(label),
    })),
    data: [
      {
        uid: !!credentials.actived.find(({ name }) => name === 'TCS')
          ? '123456789'
          : null,
        plate: credentials.actived.find(({ name }) => name === 'PLATE')
          ? '123456789'
          : null,
        visibleId: !!credentials.actived.find(({ name }) => name === 'TCS')
          ? '123456789'
          : null,
        firstName,
        lastName,
        email: username,
      },
    ],
  });

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'form']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ContractWizardInfos
          data={{
            APBMode: contract.APBMode,
            APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
            state: contract.state,
            startDate: contract.startDate,
            endDate: contract.endDate,
            description: contract.description,
            reference: contract.reference,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'form' }}>
        <Zone
          config={{
            gap: {
              y: 0,
              x: 0,
            },
            zones: [
              ['link', 'empty'],
              ['work', 'work'],
            ],
            rows: ['min-content', '1fr'],
            columns: ['1fr', 'min-content'],
          }}
        >
          <Zone.Area config={{ area: 'link' }}>
            <Widget config={{ shadow: false, full: true }}>
              <SwitcherText<
                AddSeveralMotoristsToAnExistingPoolContractWizardContext['motoristMode']
              >
                handleEvent={{
                  mode: (value) => {
                    submit({
                      motoristMode: value,
                      motorist: {
                        create: DefaultMotoristWizard({ t, lang }),
                        exist: null,
                      },
                      selectExistMotorist: null,
                      credentials: {
                        actived: productCredentials.includes('QRCODE')
                          ? [DefaultCredentialsQrcodeWizard({ t, lang })]
                          : [],
                        desactived: [],
                        context: 'wizardNewMotorist',
                      },
                    });

                    validate(false);
                  },
                }}
                data={{ value: motoristMode }}
                config={{
                  mode: [
                    {
                      label: t('createMotorist?'),
                      value: 'exist',
                    },
                    {
                      label: t('chooseExistingMotorist?'),
                      value: 'create',
                    },
                  ],
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'empty' }}>
            <div></div>
          </Zone.Area>
          <Zone.Area config={{ area: 'csv' }}>
            <Col config={{ vertical: 'start' }}>
              <Row>
                {motoristMode === 'create' ? (
                  <Popover
                    data={
                      <PopupInfo
                        data={{
                          node: CombinationValidNode,
                        }}
                        config={{
                          title: t('contractFieldCombinations'),
                          description: [
                            {
                              key: 'required',
                              label: t('required'),
                              value: '*',
                              color: 'error',
                            },
                          ],
                        }}
                      />
                    }
                  >
                    <Button
                      config={{
                        size: 'small',
                        mode: 'stroke',
                        text: t('validContractFieldsCombinations'),
                      }}
                    ></Button>
                  </Popover>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Zone.Area>
          <Zone.Area config={{ area: 'work' }}>
            <>
              {motoristMode === 'create' ? (
                <Form
                  data={{ value: motorist.create }}
                  config={{ validateOnChange: true }}
                  handleEvent={{
                    change: (value) => {
                      submit({ motorist: { ...motorist, create: value } });
                    },
                    validate: (value: boolean) => {
                      validate(value);
                    },
                  }}
                >
                  <MotoristCreateForm
                    handleEvent={{
                      submitCredentials: (value) =>
                        submit({ credentials: value }),
                      submitMotorist: (value) => {
                        submit({ motorist: { ...motorist, create: value } });
                      },
                      validate: () => {
                        const activedCredentials: {
                          uid?: Boolean;
                          visibleId?: Boolean;
                          plate?: Boolean;
                        } = credentials.actived.reduce((acc, credential) => {
                          switch (credential.name) {
                            case 'PLATE':
                              return { ...acc, plate: true };
                            case 'RFID':
                              return {
                                ...acc,
                                uid: true,
                                visibleId: credential.visibleId ? true : false,
                              };
                            case 'QRCODE':
                            default:
                              return acc;
                          }
                        }, {});

                        const currentCombinaison = Object.entries({
                          ...motorist.create,
                          ...activedCredentials,
                        }).reduce((acc: any, [key, value]) => {
                          return value ? [...acc, key] : acc;
                        }, []);

                        const credentialsEdit = {
                          RFID: productCredentials.includes('RFID'),
                          PLATE: productCredentials.includes('PLATE'),
                          TCS: productCredentials.includes('TCS'),
                        };

                        const valid = MotoristCSVParams(
                          credentialsEdit,
                        ).valid.map((combinaison) =>
                          combinaison.map((item) =>
                            item === 'email' ? 'username' : item,
                          ),
                        );

                        const findValidCombinaison = valid.findIndex(
                          (combinaison) =>
                            combinaison.length === 0 ||
                            combinaison.every((item) =>
                              currentCombinaison.includes(item),
                            ),
                        );

                        validate(
                          findValidCombinaison !== -1
                            ? true
                            : t('watermelon-INVALID_COMBINAISON'),
                        );
                      },
                    }}
                    data={{
                      credentials,
                      motorist: motorist.create,
                    }}
                    config={{
                      credentialsEdit: {
                        RFID: productCredentials.includes('RFID'),
                        PLATE: productCredentials.includes('PLATE'),
                        TCS: productCredentials.includes('TCS'),
                      },
                    }}
                  />
                </Form>
              ) : (
                <Widget
                  config={{
                    title: t('listOfExistingMotorist'),
                    shadow: false,
                  }}
                  state={{
                    loading: localMotoristsQuery.loading,
                    refetch: localMotoristsQuery.refetch,
                    error: localMotoristsQuery.error,
                    showPermanentRefetch: false,
                  }}
                >
                  <SingleCheckForm
                    handleEvent={{
                      paging: async (data) => {
                        try {
                          await localMotoristsCall({
                            variables: {
                              page: data.page,
                              limit: data.limit,
                              order: 'lastName',
                            },
                          });
                        } catch (error) {
                          console.error(error);
                        }
                      },
                      checkItem: (id) => {
                        submit({
                          selectExistMotorist: id,
                        });
                      },
                      search: handleSearch,
                    }}
                    data={{
                      check: motorist.exist ? motorist.exist._id : null,
                      fresh: fresh ? motoristFormattedLine(fresh) : undefined,
                      saved: motoristFormattedLine(motoristExistedSave),
                      paging: pagingFormatted,
                    }}
                    config={{
                      searchPlaceholder: t('searchAMotorist'),
                    }}
                  />
                </Widget>
              )}
            </>
          </Zone.Area>
        </Zone>
      </Zone.Area>
    </Zone>
  );
};
