import { useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import { MotoristMergeEntity, ProductsParameters } from '../../client/graphql';
import { DefaultMotoristWizard } from '../../common/defaultValue/wizard';
import { CredentialType } from '../../common/mapper/credential.mapper';
import { ContractCreateFormType } from '../../form/contract-create/contract-create.form';
import { MotoristCreateFormType } from '../../form/motorist-create/motorist-create.form';
import { ProductFormType } from '../../form/product/product.form';
import { ConfirmationStep } from './steps/confirmation/confirmation.step';
import { ContractStep } from './steps/contract/contract.step';
import { MotoristStep } from './steps/motorist/motorist.step';
import { ProductStep } from './steps/product/product.step';
import { SummaryStep } from './steps/summary/summary.step';

export type CreateNewSingleContractWizardContext = {
  parkingId: string | null;
  product: {
    id: ProductFormType.Data.ProductId | null;
    period: number | null;
    credentials: CredentialType.GlobalName[];
    duration: Pick<ProductsParameters, 'durationUnit' | 'durationValue'> | null;
  };
  contract: ContractCreateFormType.Data.ContractMotorist;
  credentials: MotoristCreateFormType.Data.Credentials;
  addFakeQRCODE: boolean;
  motorist: { create: MotoristCreateFormType.Data.Motorist };
  motoristOrigin: {
    _id: string | null;
    origin: MotoristMergeEntity['entityOrigin'] | null;
  };
  motoristSearchSelect: MotoristCreateFormType.Data.CurrentSearchMotoristValue;
};

export const CreateNewSingleContractWizard = observer(() => {
  const { t, lang } = useTranslation();
  const { WizardStore } = useRootStore();

  const { parkingId } = WizardStore?.selected?.store;

  return (
    <Wizard<CreateNewSingleContractWizardContext>
      config={{
        title: t(`createANewSingleContract`),
      }}
      data={{
        defaultContext: {
          parkingId,
          product: {
            id: null,
            period: null,
            credentials: [],
            duration: null,
          },
          contract: {
            startDate: new Date().toISOString(),
            endDate: null,
            APBMode: null,
            APBNext: false,
            reference: null,
            description: null,
            state: true,
          },
          credentials: {
            actived: [],
            desactived: [],
            context: 'wizardNewMotorist',
          },
          addFakeQRCODE: false,
          motorist: {
            create: DefaultMotoristWizard({ t, lang }),
          },
          motoristOrigin: {
            _id: null,
            origin: null,
          },
          motoristSearchSelect: null,
        },
      }}
      handleEvent={{
        done: async (data) => {
          console.log('done', { data });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: t('product'), component: ProductStep }} />
      <Wizard.Item config={{ title: t('contract'), component: ContractStep }} />
      <Wizard.Item config={{ title: t('motorist'), component: MotoristStep }} />
      <Wizard.Item
        config={{ title: t('confirmation'), component: ConfirmationStep }}
      />
      <Wizard.Item config={{ title: t('summary'), component: SummaryStep }} />
    </Wizard>
  );
});
