import {
  BadgeType,
  Icon,
  Row,
  Space,
  Write,
  Zone,
  toCapitalizeCase,
  useTranslation,
} from '@gimlite/watermelon';
import {
  Blurb,
  BlurbType,
} from '@gimlite/watermelon/components/blurb/blurb.component';
import { CredentialMiniGroupType } from '../../components/credential/credential.component';
import { MotoristAction } from '../action/motorist.action';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';

class Static {
  static statusBadgeColorMap: Record<
    MotoristLineType.Data.Status,
    BadgeType.Config.Color
  > = {
    VERIFIED: 'disabled',
    UNVERIFIED: 'warn',
  };
}

export declare namespace MotoristLineType {
  type Props = {
    data: {
      localMotoristId?: string | null;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      status: MotoristLineType.Data.Status;
      credentials?: number;
      activeContracts?: [number, number];
      inside?: string[];
      hasUnfinishedContract?: boolean;
      credentialsList?: React.ReactElement<CredentialMiniGroupType.Props>;
    };
    config?: {
      action?: boolean;
      icon?: boolean;
    };
  };

  namespace Data {
    type Status = 'VERIFIED' | 'UNVERIFIED';
  }
}

export const MotoristLine = ({
  data: {
    localMotoristId,
    firstName,
    lastName,
    email,
    status,
    credentials,
    activeContracts,
    inside,
    hasUnfinishedContract,
    credentialsList,
  },
  config: { action = true, icon = false } = {},
}: MotoristLineType.Props) => {
  const { t } = useTranslation();

  let identityOptions: Pick<BlurbType.Props['data'], 'subcontain'> = {};

  if (credentialsList) {
    identityOptions = { ...identityOptions, subcontain: credentialsList };
  }

  const badges = [
    {
      text: t(status).toUpperCase(),
      color: Static.statusBadgeColorMap[status],
    },
  ];

  if (inside?.length)
    badges.push({
      text: t('present').toUpperCase(),
      color: 'success',
    });

  return (
    <Zone
      config={{
        gap: {
          x: 1,
          y: 0,
        },
        zones: [
          [
            icon ? 'icon' : '',
            'identity',
            'contracts',
            'credentials',
            'action',
          ],
        ],
        rows: ['1fr'],
        columns: [
          icon ? 'min-content' : '',
          '1fr',
          'min-content',
          'min-content',
          'min-content',
        ],
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      {icon && (
        <Zone.Area config={{ area: 'icon' }}>
          <Icon
            config={{
              size: 'list',
              type: iconGlobalEntity['motorist'],
            }}
          ></Icon>
        </Zone.Area>
      )}

      <Zone.Area config={{ area: 'identity' }}>
        <Blurb
          data={{
            ...identityOptions,
            title:
              `${lastName || ''} ${firstName || ''}`.trim() || t('unknown'),
            description: email ? `${email}` : '',
            badge: badges,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'contracts' }}>
        {activeContracts ? (
          <Row config={{ horizontal: 'end', vertical: 'center' }}>
            <Write
              config={{ wrap: false, mode: 'key-small-bold' }}
              data={{ item: `${activeContracts[0]} / ${activeContracts[1]}` }}
            />
            <Space config={{ count: 0.5, way: 'horizontal' }} />
            <Icon config={{ type: 'faAddressCardSolid', size: 'xxsmall' }} />
          </Row>
        ) : (
          <></>
        )}
      </Zone.Area>

      <Zone.Area config={{ area: 'credentials' }}>
        {typeof credentials === 'number' ? (
          <Row config={{ horizontal: 'end', vertical: 'center' }}>
            <Space config={{ count: 2, way: 'horizontal' }} />
            <Write
              config={{ wrap: false, mode: 'key-small-bold' }}
              data={{ item: `${credentials}` }}
            />
            <Space config={{ count: 0.5, way: 'horizontal' }} />
            <Icon config={{ type: 'faKeySolid', size: 'xxsmall' }} />
          </Row>
        ) : (
          <></>
        )}
      </Zone.Area>

      <Zone.Area config={{ area: 'action' }}>
        {action ? (
          <Row config={{ horizontal: 'center', vertical: 'center' }}>
            <MotoristAction
              config={{ size: 'list' }}
              activeContracts={hasUnfinishedContract || false}
              localMotoristId={localMotoristId || null}
            />
          </Row>
        ) : (
          <></>
        )}
      </Zone.Area>
    </Zone>
  );
};
