import { useMutation, useRootStore, useTranslation } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import {
  Mutation,
  MutationArchiveTechnicalEventArgs,
} from '../../client/graphql';
import { archiveTechnicalEventsGql } from '../../common/gql/incidents.gql';
import {
  ActionInline,
  ActionInlineType,
} from '../../components/action-inline/action-inline.component';

export declare namespace IncidentActionType {
  type Props = {
    config: {
      size: ActionInlineType.Config.Size;
    };
    data: {
      technicalEventId: string;
      payload: any;
      isArchived?: boolean;
    };
  };
}

export const IncidentAction = observer(
  ({
    config: { size },
    data: { technicalEventId, payload, isArchived },
  }: IncidentActionType.Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [archiveTechnicalEventCall] = useMutation<
      { archiveTechnicalEvent: Mutation['archiveTechnicalEvent'] },
      MutationArchiveTechnicalEventArgs
    >(archiveTechnicalEventsGql);

    const actions: ActionInlineType.Data[] = [
      {
        key: 'showMotoristContract',
        text: t('showMotoristContract'),
        color: 'label',
        show: !!payload?.contractId && !!payload?.parkingId,
        event: () =>
          navigate(`/${payload?.parkingId}/contracts/${payload?.contractId}`),
      },
      {
        key: 'showLocalMotorist',
        text: t('showLocalMotorist'),
        color: 'label',
        show: !!payload?.localMotoristId,
        event: () => navigate(`/motorists/${payload?.localMotoristId}`),
      },
      {
        key: 'archiveIncident',
        text: t('archiveIncident'),
        color: 'label',
        show: !!technicalEventId && !isArchived,
        event: async () => {
          try {
            if (!technicalEventId)
              throw new Error('Technical Event ID is required');

            await archiveTechnicalEventCall({
              variables: { technicalEventId },
            });
          } catch (error) {
            console.error(error);
          }
        },
      },
    ];

    return <ActionInline config={{ size }} data={actions} />;
  },
);
