import { Page, Zone, useMyUrl, useTranslation } from '@gimlite/watermelon';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BreakdownList } from '../../composition/list/breakdown.list';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import { BreakdownsDetails } from '../../composition/details/breakdown.details';
import { observer } from 'mobx-react-lite';

export const BreakdownsPage = observer(() => {
  const navigate = useNavigate();
  const { getParamsUrlString } = useMyUrl({});
  const { t } = useTranslation();
  const { parkingId, breakdownId } = useParams();
  const [selected, setSelected] = useState<string | null>(breakdownId || null);

  useEffect(() => {
    if (selected) {
      navigate(`/${parkingId}/breakdowns/${selected}?${getParamsUrlString}`);
    }
  }, [selected]);

  useEffect(() => {
    setSelected(null);
  }, [parkingId]);

  return (
    parkingId && (
      <Page>
        <Zone
          config={{
            zones: [['list', 'details']],
            rows: ['1fr'],
            columns: ['1fr', '1fr'],
          }}
        >
          <Zone.Area
            config={{
              area: 'list',
            }}
          >
            <BreakdownList
              widget={{
                title: t('listOfDeviceFailures'),
                icon: iconGlobalEntity['breakdown'],
              }}
              parkingId={parkingId}
              setSelectedBreakdown={setSelected}
            />
          </Zone.Area>

          <Zone.Area
            config={{
              area: 'details',
            }}
          >
            <BreakdownsDetails
              widget={{ title: t('detailsOfDeviceFailure') }}
              breakdownId={selected}
            />
          </Zone.Area>
        </Zone>
      </Page>
    )
  );
});
