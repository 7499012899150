import { Page, Zone } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { ProductList } from '../../composition/list/product/product.list';

export const ProductPage = observer(() => {
  const { parkingId } = useParams();

  return (
    parkingId && (
      <Page>
        <Zone
          config={{
            zones: [['list']],
            rows: ['1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area
            config={{
              area: 'list',
            }}
          >
            <ProductList parkingId={parkingId} />
          </Zone.Area>
        </Zone>
      </Page>
    )
  );
});
