import { gql } from '@apollo/client';

export const sessionsGql = gql`
  query sessions(
    $parkingId: String
    $motoristId: String
    $limit: Int! = 50
    $page: Int! = 1
    $status: [String]
    $dateRangeStart: [String]
    $dateRangeEnd: [String]
    $type: [String]
    $device: [String]
    $contractId: String
  ) {
    sessions(
      parkingId: $parkingId
      motoristId: $motoristId
      limit: $limit
      page: $page
      status: $status
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      type: $type
      device: $device
      contractId: $contractId
    ) {
      list {
        _id
        entryDate
        exitDate
        startDate
        parkingId
        currency
        contractId
        isInconsistent
        motorist {
          _id
          firstName
          lastName
        }

        product {
          _id
          name
        }

        parking {
          _id
          accessPoints {
            shortCode
            _id
          }
        }

        events {
          accessPointId
          createdAt
          state
          reason
          amount
          credentials {
            type
            provider
            value
            description
          }
        }
      }

      paging {
        current
        count
        limit
      }
    }
  }
`;
