import { useRootStore, useTranslation } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import {
  CredentialCard,
  CredentialCardType,
  CredentialMini,
} from '../../components/credential/credential.component';
import { CredentialType } from '../../common/mapper/credential.mapper';

export class RFIDCredentialStatic {
  static info: CredentialType.Static<RFIDCredentialCardType.Data> = {
    name: 'RFID',
    icon: 'customDish',
    backgroundColor: { light: '#333333', dark: '#e8e8e8' },
    color: { light: '#e8e8e8', dark: '#333333' },
    category: 'RFID',
    primaryValue: 'uid',
  };
}

export declare namespace RFIDCredentialMiniType {
  type Props = {
    data?: {
      value?: string | null;
    };
  };
}

export const RFIDCredentialMini = observer(
  ({ data: { value } = {} }: RFIDCredentialMiniType.Props) => {
    const { ThemeStore } = useRootStore();

    return (
      <CredentialMini
        data={{ text: value }}
        config={{
          icon: RFIDCredentialStatic.info.icon,
          backgroundColor:
            RFIDCredentialStatic.info.backgroundColor[ThemeStore.theme],
          color: RFIDCredentialStatic.info.color[ThemeStore.theme],
        }}
      />
    );
  },
);

export declare namespace RFIDCredentialCardType {
  type Props = {
    handleEvent?: {
      event?: CredentialCardType.HandleEvent.Event['function'];
    };
    data: RFIDCredentialCardType.Data;
    config?: {
      isActive?: boolean;
    };
  };

  type Data = {
    uid: string;
    visibleId: string;
  };
}

export const RFIDCredentialCard = observer(
  ({
    handleEvent: { event } = {},
    data: { uid, visibleId },
    config: { isActive = true } = {},
  }: RFIDCredentialCardType.Props) => {
    const { t, lang } = useTranslation();
    const { ThemeStore } = useRootStore();

    return (
      <CredentialCard.Context.Consumer>
        {({ place, edit }) => (
          <CredentialCard
            handleEvent={{ event, edit }}
            data={{
              name: RFIDCredentialStatic.info.name,
              info: [
                { key: 'uid', label: t('uid'), value: uid },
                { key: 'visibleId', label: t('visibleId'), value: visibleId },
              ],
              category: RFIDCredentialStatic.info.category,
              primaryValue: RFIDCredentialStatic.info.primaryValue,
            }}
            config={{
              icon: RFIDCredentialStatic.info.icon,
              backgroundColor:
                RFIDCredentialStatic.info.backgroundColor[ThemeStore.theme],
              color: RFIDCredentialStatic.info.color[ThemeStore.theme],
              disabled: !isActive,
              action:
                place === 'contractDisabled'
                  ? []
                  : !isActive
                    ? [{ label: t('import'), value: 'import' }]
                    : place === 'contract'
                      ? [{ label: t('unload'), value: 'unload' }]
                      : place === 'wizardMotoristExist'
                        ? [{ label: t('unload'), value: 'unload' }]
                        : place === 'wizardNewMotorist'
                          ? [{ label: t('delete'), value: 'delete' }]
                          : place === 'motorist'
                            ? [
                                { label: t('edit'), value: 'edit' },
                                {
                                  label: t('delete'),
                                  value: 'delete',
                                  isCritical: true,
                                },
                              ]
                            : [],
            }}
          />
        )}
      </CredentialCard.Context.Consumer>
    );
  },
);
