import { ListFlatMultipleCheck, useLazyQuery } from '@gimlite/watermelon';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { Query, QueryContractsArgs } from '../../../../client/graphql';
import { contractsGql } from '../../../../common/gql/contracts.gql';
import { ContractMotoristLine } from '../../../../composition/line/contract-motorist.line';
import { ReportTechnicalWizardInfos } from '../../../../composition/wizard-infos/report-technical.wizard-infos';
import { ReportTechnicalDeviceFailureContext } from '../../reportTechnicalDeviceFailure.wizard';

const pageLimit = 10000;

export const MotoristsStep = ({
  context: { issue, parkingId, motoristsConcerned },
  validate,
  submit,
  loading,
}: WizardItemType.Config.Component<ReportTechnicalDeviceFailureContext>) => {
  const { t, lang } = useTranslation();

  const [contractsCall, contractsState] = useLazyQuery<
    { contracts: Query['contracts'] },
    QueryContractsArgs
  >(contractsGql, {
    variables: {
      isActive: true,
      categories: ['PERIOD_SUBSCRIPTION', 'MOTORIST_POOL'],
      page: 1,
      limit: pageLimit,
    },
  });

  const contracts = useMemo(() => {
    if (!contractsState.data) return null;

    return contractsState.data.contracts;
  }, [contractsState.data]);

  const listFormatted = useMemo(() => {
    return (
      contracts?.list &&
      contracts.list.map(
        ({ _id, motorist, ospContractId, stopDate, isEnabled }) => ({
          _id,
          items: {
            component: (
              <ContractMotoristLine
                data={{
                  firstName: motorist?.firstName || null,
                  lastName: motorist?.lastName || null,
                  contractId: ospContractId || null,
                  status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                  isStopped: stopDate
                    ? DateTime.fromISO(stopDate).diffNow().as('milliseconds') <
                      0
                    : false,
                }}
                config={{ action: false }}
              />
            ),
          },
        }),
      )
    );
  }, [contracts]);

  const checkList = useMemo(() => {
    return contracts ? contracts?.list.map(({ _id }) => _id) : undefined;
  }, [contracts]);

  useEffect(() => {
    contractsCall();
  }, [contractsCall]);

  useEffect(() => {
    if (contracts?.list) {
      submit({
        motoristsConcerned: contracts.list,
      });
    }
  }, [contracts]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'list']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ReportTechnicalWizardInfos
          data={{
            startDate: issue.startDate,
            endDate: issue.endDate,
            accessPointType: issue.accessPointType,
            category: issue.category,
            description: issue.description,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'list' }}>
        <Widget
          config={{
            title: t('allContractsConcerned'),
            shadow: false,
          }}
        >
          <Zone
            config={{
              gap: {
                y: 1,
                x: 0,
              },
              zones: [['description'], ['details']],
              rows: ['min-content', '1fr'],
              columns: ['1fr'],
            }}
          >
            <Zone.Area config={{ area: 'description' }}>
              <Description
                data={[
                  {
                    key: 'total',
                    label: t('total'),
                    value: checkList ? checkList.length : 0,
                  },
                ]}
                config={{ way: 'horizontal' }}
              ></Description>
            </Zone.Area>

            <Zone.Area config={{ area: 'details' }}>
              <ListFlatMultipleCheck<any>
                handleEvent={{
                  paging: async (data) => {
                    try {
                      await contractsCall({
                        variables: {
                          page: data.page,
                          limit: data.limit,
                          parkingId,
                        },
                      });
                    } catch (error) {
                      console.error(error);
                    }
                  },
                }}
                data={{
                  list: listFormatted,
                  checkList: checkList || [],
                  paging: contracts?.paging,
                }}
                config={{
                  disabled: true,
                  pagination: 'none',
                  columns: [{ key: 'component', size: '1fr' }],
                  border: true,
                }}
              />
            </Zone.Area>
          </Zone>
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
