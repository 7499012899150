import {
  IconType,
  ListFlatInfo,
  Widget,
  useMyUrl,
  useQuery,
  useTranslation,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Query, QueryBreakdownsArgs } from '../../client/graphql';
import { breakdownsGql } from '../../common/gql/breakdowns.gql';
import { BreakdownLine, BreakdownLineType } from '../line/breakdown.line';

type BreakdownListProps = {
  parkingId: string;
  widget?: {
    title?: string;
    icon?: IconType.Config.Type;
  };
  setSelectedBreakdown?: (id: string) => void;
};

export const BreakdownList = ({
  widget,
  parkingId,
  setSelectedBreakdown,
}: BreakdownListProps) => {
  const { t } = useTranslation();
  const { breakdownId } = useParams();

  const { getParamsUrl, setParamsUrl } = useMyUrl<{
    breakDownPage: number;
    breakDownLimit: number;
  }>();

  const { data, loading, error, refetch } = useQuery<
    {
      breakdowns: Query['breakdowns'];
    },
    QueryBreakdownsArgs
  >(breakdownsGql, {
    variables: {
      parkingId,
      limit: getParamsUrl.breakDownLimit || 15,
      page: getParamsUrl.breakDownPage || 1,
    },
    socket: {
      'breakdowns:created': () => true,
      'breakdowns:updated': () => true,
    },
  });

  const breakdowns = useMemo(() => {
    if (!data) return null;

    return data.breakdowns;
  }, [data]);

  return (
    <Widget
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
        icon: widget?.icon,
      }}
      state={{
        loading,
        error,
        refetch,
        showPermanentRefetch: false,
      }}
    >
      <ListFlatInfo<'component'>
        config={{
          pagination: 'scroll',
          columns: [
            { key: 'component', label: t('type'), size: '1fr' },
            { key: 'component', label: t('startDate'), size: '1fr' },
            { key: 'component', label: t('endDate'), size: '1fr' },
          ],
          header: true,
        }}
        handleEvent={{
          paging: (paging) => {
            setParamsUrl({
              ...getParamsUrl,
              breakDownPage: paging.page,
              breakDownLimit: paging.limit,
            });
          },
          click: (breakdownId) => setSelectedBreakdown?.(breakdownId),
        }}
        data={{
          defaultSelected: breakdownId,
          list: breakdowns
            ? breakdowns.list.map(
                ({ _id, description, startDate, endDate, type }) => ({
                  _id,
                  items: {
                    component: (
                      <BreakdownLine
                        data={{
                          problem: description,
                          status:
                            DateTime.fromISO(endDate) < DateTime.now()
                              ? 'FINISHED'
                              : 'PROGRESS',
                          access:
                            type.toLowerCase() as BreakdownLineType.Data.Access,
                          startDate,
                          endDate,
                          breakdownId: _id,
                        }}
                      />
                    ),
                  },
                }),
              )
            : undefined,
          paging: breakdowns?.paging,
        }}
      />
    </Widget>
  );
};
