import { gql } from '@apollo/client';

export const breakdownsGql = gql`
  query breakdowns(
    $page: Int!
    $limit: Int!
    $order: String
    $parkingId: String!
  ) {
    breakdowns(
      page: $page
      limit: $limit
      order: $order
      parkingId: $parkingId
    ) {
      list {
        _id
        startDate
        endDate
        category
        description
        type
        createdAt
        updatedAt
        contractsNeutralized {
          list {
            _id
            category
            ospContractId
          }
          paging {
            current
            count
            limit
          }
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
