import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ContractSubPoolLine } from '../../../../composition/line/contract-sub-pool.line';
import { ContractWizardInfos } from '../../../../composition/wizard-infos/contract.wizard-infos';
import { ConfirmationForm } from '../../../../form/confirmation/confirmation.form';
import { CreateNewSubPoolContractContext } from '../../createNewSubPoolContract.wizard';

export const ConfirmationStep = ({
  context: { contract, informations },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewSubPoolContractContext>) => {
  const { t, lang } = useTranslation();

  const [allFeatures, setAllFeatures] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const features: JSX.Element[] = [];

    features.push(
      <ContractSubPoolLine
        data={{
          name1: informations.name1 as string,
          name2: informations.name2,
          code: t('willBeGenerated'),
          status: contract.state ? 'ACTIVE' : 'SUSPENDED',
        }}
        config={{ action: false }}
      />,
    );

    setAllFeatures(features);
  }, [lang]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'list']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ContractWizardInfos
          data={{
            APBMode: contract.APBMode,
            APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
            state: contract.state,
            startDate: contract.startDate,
            endDate: contract.endDate,
            description: contract.description,
            reference: contract.reference,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'list' }}>
        <ConfirmationForm
          data={{
            list: allFeatures.map((node) => {
              return {
                _id: uuidv4(),
                items: {
                  component: node,
                },
              };
            }),
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
