import {
  Empty,
  FormType,
  Segmented,
  SegmentedType,
  Zone,
  useLazyQuery,
  useMutation,
  useQuery,
  useTranslation,
} from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import {
  Mutation,
  MutationUpdateBreakdownArgs,
  Query,
  QueryBreakdownArgs,
} from '../../client/graphql';
import { breakdownUpdateGql } from '../../common/gql/breakdown-update.gql';
import { breakdownGql } from '../../common/gql/breakdown.gql';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import { DurationTimer } from '../../common/utils/duration-timer/duration-timer';
import { ProfilDetails } from '../../components/profil-details/profil-details.component';
import { BreakdownAction } from '../action/breakdown.action';
import { MotoristContractList } from '../list/motorist-contract.list';

type BreakdownsDetailsProps = {
  breakdownId: string | null;
  widget?: {
    title?: string;
  };
};

export const BreakdownsDetails = ({
  widget,
  breakdownId,
}: BreakdownsDetailsProps) => {
  const [segmented, setSegmented] =
    useState<SegmentedType.Data.Selected>('contracts');
  const { t } = useTranslation();
  const [form, setForm] = useState<FormType.Data.Value>();

  const [updateBreakdown, updateBreakdownState] = useMutation<
    { updateBreakdown: Mutation['updateBreakdown'] },
    MutationUpdateBreakdownArgs
  >(breakdownUpdateGql);

  const [breakdownCall, breakdownState] = useLazyQuery<
    {
      breakdown: Query['breakdown'];
    },
    QueryBreakdownArgs
  >(breakdownGql, {
    socket: {
      'breakdowns:updated': () => true,
    },
  });

  const breakdown = useMemo(() => {
    if (!breakdownState.data) return null;

    return breakdownState.data.breakdown;
  }, [breakdownState.data]);

  useEffect(() => {
    if (breakdown) {
      setForm({
        problem: breakdown.type || '',
        access: breakdown.description || '-',

        startDate: breakdown.startDate,
        endDate: breakdown.endDate,

        duration: DateTime.fromISO(breakdown.endDate).diff(
          DateTime.fromISO(breakdown.startDate),
          'seconds',
        ).seconds,
      });
    }
  }, [breakdown]);

  useEffect(() => {
    if (breakdownId) {
      breakdownCall({ variables: { breakdownId } });
    } else {
      breakdownState.resetData();
    }
  }, [breakdownId]);

  return (
    <Widget.Group
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
      }}
      state={{
        loading: breakdownState.loading,
        error: breakdownState.error,
        refetch: breakdownState.refetch,
        showPermanentRefetch: false,
      }}
    >
      {breakdown && breakdownId && form ? (
        <Zone
          config={{
            zones: [['profil'], ['segmented'], ['details']],
            rows: ['min-content', 'min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'profil' }}>
            <Widget>
              <ProfilDetails
                handleEvent={{
                  submit: async (form) => {
                    setForm(form);
                    const { endDate } = form;

                    try {
                      await updateBreakdown({
                        variables: {
                          breakdownId: breakdown._id,
                          input: { endDate },
                        },
                      });
                    } catch (error) {
                      console.error(error);
                    }
                  },
                }}
                data={{
                  icon: iconGlobalEntity['breakdown'],
                  form: form,
                  badges:
                    DateTime.fromISO(breakdown.endDate) < DateTime.now()
                      ? [
                          {
                            text: t('FINISHED'),
                            color: 'success',
                          },
                        ]
                      : [
                          {
                            text: t('PROGRESS'),
                            color: 'error',
                          },
                        ],
                }}
                config={{
                  edit: DateTime.fromISO(breakdown.endDate) > DateTime.now(),
                  form: {
                    title1: {
                      name: 'problem',
                      placeholder: t('problem'),
                      edit: false,
                    },
                    description1: {
                      name: 'access',
                      placeholder: t('access'),
                      edit: false,
                    },
                    group1: [
                      {
                        name: 'startDate',
                        label: t('startDate'),
                        render: (value) =>
                          DateTime.fromISO(value).toFormat('dd/MM/yy HH:mm:ss'),
                        element: {
                          type: 'datetime',
                        },
                        edit: false,
                      },
                      {
                        name: 'duration',
                        label: t('duration'),
                        element: { type: 'input' },
                        render: (data) => DurationTimer(data),
                        edit: false,
                      },
                    ],
                    group2: [
                      {
                        name: 'endDate',
                        label: t('endDate'),
                        render: (value) =>
                          DateTime.fromISO(value).toFormat('dd/MM/yy HH:mm:ss'),
                        element: {
                          type: 'datetime',
                          min: DateTime.fromISO(breakdown.startDate).toISO(),
                          max: DateTime.fromISO(breakdown.startDate)
                            .plus({ hours: 72 })
                            .toISO(),
                        },
                        edit:
                          DateTime.fromISO(breakdown.endDate) > DateTime.now(),
                      },
                    ],
                  },
                  actions: (
                    <BreakdownAction
                      config={{ size: 'xlarge' }}
                      data={{ breakdownId }}
                    />
                  ),
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'segmented' }}>
            <Widget>
              <Segmented
                handleEvent={{
                  option: (value) => {
                    setSegmented(value);
                  },
                }}
                data={{ selected: segmented }}
                config={{
                  size: 'large',
                  options: [
                    { label: t('contractsNeutralized'), value: 'contracts' },
                  ],
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'details' }}>
            {segmented === 'contracts' && (
              <MotoristContractList freeflagId={breakdownId} />
            )}
          </Zone.Area>
        </Zone>
      ) : (
        <Empty
          config={{
            mode: { name: 'select', text: `${t('selectBarrierFailure')} ...` },
          }}
        ></Empty>
      )}
    </Widget.Group>
  );
};
