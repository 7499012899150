import {
  toCapitalizeCase,
  useQuery,
  useTranslation,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { DateTime, Duration } from 'luxon';
import { useMemo } from 'react';
import {
  AntiPassBackMode,
  ProductCategoryEnum,
  ProductsParameters,
  Query,
  QueryProductsArgs,
} from '../../client/graphql';
import { productsGql } from '../../common/gql/products.gql';
import {
  convertCredentialsBackToFrontName,
  CredentialType,
} from '../../common/mapper/credential.mapper';
import { APBModeDetectWithCycleControl } from '../../common/mapper/product.mapper';

import {
  Product,
  ProductGroup,
} from '../../components/product/product.component';
import './product.form.scss';

export declare namespace ProductFormType {
  type Props = {
    handleEvent: {
      select: ({
        id,
        period,
        APBMode,
      }: {
        id: string;
        period: number | null;
        APBMode: AntiPassBackMode;
        credentials: CredentialType.GlobalName[] | null;
        duration: Pick<
          ProductsParameters,
          'durationUnit' | 'durationValue'
        > | null;
      }) => void;
    };
    data: {
      category: ProductCategoryEnum;
      productId: string | null;
      parkingId: string;
    };
  };

  namespace Data {
    type ProductId = string | null;
  }
}

export const ProductForm = ({
  data: { productId, category, parkingId },
  handleEvent: { select },
}: ProductFormType.Props) => {
  const { t, lang } = useTranslation();

  const queryProducts = useQuery<
    { products: Query['products'] },
    QueryProductsArgs
  >(productsGql, {
    variables: {
      parkingId,
      category,
    },
  });

  const products = useMemo(() => {
    return queryProducts.data?.products?.list || [];
  }, [queryProducts.data]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['contain']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'contain' }}>
        <Widget
          config={{
            shadow: false,
          }}
          state={{
            loading: queryProducts.loading,
            error: queryProducts.error,
            refetch: queryProducts.refetch,
            showPermanentRefetch: false,
          }}
        >
          <ProductGroup
            data={{ selected: productId }}
            handleEvent={{
              click: (id) => {
                const { parameters, rules } = products.find(
                  (product) => product._id === id,
                )!;

                const APBMode = APBModeDetectWithCycleControl(
                  rules?.cycleControl || null,
                );

                const credentialsCompatibility = rules?.credentialsTypes?.types
                  ? rules.credentialsTypes.types.map((item) =>
                      convertCredentialsBackToFrontName(item),
                    )
                  : [];

                if (
                  parameters &&
                  parameters.durationValue &&
                  parameters.durationUnit
                ) {
                  switch (parameters.durationUnit) {
                    case 'HOUR':
                      select({
                        id,
                        duration: {
                          durationValue: parameters.durationValue,
                          durationUnit: parameters.durationUnit,
                        },
                        period: Duration.fromMillis(0)
                          .plus({
                            hour: parameters.durationValue,
                          })
                          .minus({
                            day: 1,
                          })
                          .toMillis(),
                        APBMode,
                        credentials: credentialsCompatibility.filter(
                          (item) => item !== null,
                        ),
                      });
                      break;

                    case 'MONTH':
                      select({
                        id,
                        duration: {
                          durationValue: parameters.durationValue,
                          durationUnit: parameters.durationUnit,
                        },
                        period: Duration.fromMillis(0)
                          .plus({
                            month: parameters.durationValue,
                          })
                          .minus({
                            day: 1,
                          })
                          .toMillis(),
                        APBMode,
                        credentials: credentialsCompatibility.filter(
                          (item) => item !== null,
                        ),
                      });
                      break;

                    case 'YEAR':
                      select({
                        id,
                        duration: {
                          durationValue: parameters.durationValue,
                          durationUnit: parameters.durationUnit,
                        },
                        period: Duration.fromMillis(0)
                          .plus({
                            years: parameters.durationValue,
                          })
                          .minus({
                            day: 1,
                          })
                          .toMillis(),
                        APBMode,
                        credentials: credentialsCompatibility.filter(
                          (item) => item !== null,
                        ),
                      });
                      break;

                    default:
                      select({
                        id,
                        duration: {
                          durationValue: parameters.durationValue,
                          durationUnit: parameters.durationUnit as
                            | 'HOUR'
                            | 'MONTH'
                            | 'YEAR',
                        },
                        period: null,
                        APBMode,
                        credentials: credentialsCompatibility.filter(
                          (item) => item !== null,
                        ),
                      });
                      break;
                  }
                } else {
                  select({
                    id,
                    duration: null,
                    period: null,
                    APBMode,
                    credentials: credentialsCompatibility.filter(
                      (item) => item !== null,
                    ),
                  });
                }
              },
            }}
          >
            <Wizard.CardGroup
              config={{
                title: t('selectProduct'),
                subtitle: t('SELECT_PRODUCT_EXPLAIN'),
              }}
            >
              {products &&
                products.map(
                  (
                    {
                      _id,
                      parameters,
                      rules,
                      externalPayload,
                      createdAt,
                      category,
                    },
                    index,
                  ) => {
                    const APBMode = APBModeDetectWithCycleControl(
                      rules?.cycleControl || null,
                    );

                    return (
                      <div key={index}>
                        <Product
                          data={{
                            _id,
                            category,
                            title: t(`product-${_id}-name`),
                            description: t(`product-${_id}-description`),
                            price:
                              parameters &&
                              parameters?.cents &&
                              typeof parameters?.cents === 'number'
                                ? parameters.cents / 100
                                : null,
                            currency: parameters?.currency || null,
                            period: {
                              time: parameters?.durationUnit
                                ? t(parameters.durationUnit.toLowerCase())
                                : null,
                              count: parameters?.durationValue || null,
                            },
                            witEngament: false,
                            advantage: [
                              {
                                label: t(
                                  `antiPassback${toCapitalizeCase(APBMode)}`,
                                ),
                                isPresent: !(APBMode === 'NONE'),
                              },
                            ],
                            credential:
                              rules?.credentialsTypes?.types
                                .map((item) =>
                                  convertCredentialsBackToFrontName(item),
                                )
                                .filter((item) => item !== null) || null,
                            info: {
                              articleCode: externalPayload?.ArticleCode || null,
                              createdAt:
                                DateTime.fromISO(createdAt).toFormat(
                                  'dd/MM/yy HH:mm:ss',
                                ),
                            },
                          }}
                        />
                      </div>
                    );
                  },
                )}
            </Wizard.CardGroup>
          </ProductGroup>
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
