import {
  FilterReduced,
  Form,
  IconType,
  ListFlatInfo,
  Select,
  Widget,
  useMyUrl,
  useQuery,
  useTranslation,
} from '@gimlite/watermelon';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Query, QueryTechnicalEventsArgs } from '../../client/graphql';
import { technicalEventsGql } from '../../common/gql/incidents.gql';
import { IncidentLine } from '../line/incident.line';

export type IncidentListProps = {
  widget?: {
    title?: string;
    icon?: IconType.Config.Type;
  };
  parkingId: string;
  eventHandler?: {
    select?: (id: string) => void;
  };
  setSelectedIncident?: (id: string) => void;
};

export const IncidentList = ({
  widget,
  parkingId,
  setSelectedIncident,
}: IncidentListProps) => {
  const { t } = useTranslation();
  const { incidentId } = useParams();
  const { getParamsUrl, setParamsUrl } = useMyUrl<{
    incidentPage: number;
    incidentLimit: number;
    archived: 'active' | 'archived';
    criticity?: Array<string>;
    category?: Array<string>;
    parkingId: string;
  }>();

  const { archived, criticity, category, page, limit } = useMemo(() => {
    // get filters params here
    const { archived, criticity, category, incidentPage, incidentLimit } =
      getParamsUrl;

    return {
      parkingId,
      page: incidentPage || 1,
      limit: incidentLimit || 50,
      archived: archived || 'active',
      criticity: !criticity
        ? undefined
        : Array.isArray(criticity)
          ? criticity.filter((crit) => !!crit)
          : [criticity],
      category: !category
        ? undefined
        : Array.isArray(category)
          ? category.filter((cat) => !!cat)
          : [category],
    };
  }, [getParamsUrl]);

  const { data, loading, error, refetch } = useQuery<
    {
      technicalEvents: Query['technicalEvents'];
    },
    QueryTechnicalEventsArgs
  >(technicalEventsGql, {
    variables: {
      parkingId,
      archived,
      criticity,
      category,
      page,
      limit,
    },
    socket: {
      'technical-events:created': () => true,
      'technical-events:updated': () => true,
    },
  });

  const technicalEvents = useMemo(() => {
    if (!data) return null;

    return data.technicalEvents;
  }, [data]);

  useEffect(() => {
    setParamsUrl({
      ...getParamsUrl,
      archived: 'active',
      incidentPage: 1,
      incidentLimit: 30,
      parkingId,
    });
  }, [parkingId]);

  return (
    <Widget
      config={{
        title: widget?.title,
        icon: widget?.icon,
        backtitle: !!widget?.title,
      }}
      state={{
        loading,
        error,
        refetch,
        showPermanentRefetch: false,
      }}
    >
      <ListFlatInfo<'component'>
        data={{
          defaultSelected: incidentId,
          list: technicalEvents
            ? technicalEvents.list.map(
                ({
                  _id,
                  key,
                  createdAt,
                  payload,
                  category,
                  criticity,
                  archived,
                }) => ({
                  _id,
                  items: {
                    component: (
                      <IncidentLine
                        data={{
                          code: key,
                          payload,
                          device: payload?.accessPoint?.shortCode,
                          date: createdAt,
                          category,
                          criticity,
                          isArchived: archived,
                          technicalEventId: _id,
                        }}
                      />
                    ),
                  },
                }),
              )
            : undefined,
          paging: technicalEvents?.paging,
        }}
        config={{
          pagination: 'scroll',
          columns: [
            { key: 'component', label: t('identity'), size: '1fr' },
            { key: 'component', label: t('category'), size: 'min-content' },
          ],
          header: true,
          filter: (
            <FilterReduced
              data={{
                value: {
                  archived: getParamsUrl.archived,
                  criticity: getParamsUrl.criticity,
                  category: getParamsUrl.category,
                },
              }}
              handleEvent={{
                submit: (submit) => {
                  setParamsUrl({ ...getParamsUrl, ...submit, incidentPage: 1 });
                },
                clear: () => {
                  setParamsUrl({
                    ...getParamsUrl,
                    archived: 'active',
                    criticity: undefined,
                    category: undefined,
                    incidentPage: 1,
                    incidentLimit: 50,
                  });
                },
              }}
            >
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('show'),
                  name: 'archived',
                }}
                data={{ defaultValue: getParamsUrl.archived || 'active' }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false }}
                  data={{
                    items: [
                      {
                        label: t('active'),
                        value: 'active',
                      },
                      {
                        label: t('archived'),
                        value: 'archived',
                      },
                    ],
                  }}
                />
              </Form.Item>
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('severity'),
                  name: 'criticity',
                }}
                data={{ defaultValue: getParamsUrl.criticity }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false, mode: 'multiple' }}
                  data={{
                    items: [
                      {
                        label: t('alarm'),
                        value: 'alarm',
                      },
                      {
                        label: t('warning'),
                        value: 'warning',
                      },
                      {
                        label: t('information'),
                        value: 'information',
                      },
                    ],
                  }}
                />
              </Form.Item>
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('category'),
                  name: 'category',
                }}
                data={{ defaultValue: getParamsUrl.category }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false, mode: 'multiple' }}
                  data={{
                    items: [
                      {
                        label: t('contract'),
                        value: 'contract',
                      },
                      {
                        label: t('anti-passback'),
                        value: 'anti-passback',
                      },
                      {
                        label: t('payment'),
                        value: 'payment',
                      },
                      {
                        label: t('credential'),
                        value: 'credential',
                      },
                    ],
                  }}
                />
              </Form.Item>
            </FilterReduced>
          ),
        }}
        handleEvent={{
          click: setSelectedIncident,
          paging: (paging) => {
            setParamsUrl({
              ...getParamsUrl,
              incidentPage: paging.page,
              incidentLimit: paging.limit,
            });
          },
        }}
      />
    </Widget>
  );
};
