import { useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import { portal } from '../../config';
import { ActionStep } from './steps/action/action.step';

export type ChoiceCreateContractWizardContext = {
  parkingId: string | null;
  actions: {
    createNewSingleContract: boolean;
    createNewMasterPoolContract: boolean;
    createNewSubPoolContract: boolean;
    addOneMotoristToAnExistingPoolContract: boolean;
    addSeveralMotoristsToAnExistingPoolContract: boolean;
  };
  mode: typeof portal;
};

export const ChoiceCreateContractWizard = observer(() => {
  const { t } = useTranslation();
  const { WizardStore } = useRootStore();

  console.log(
    '🚀 ~ ChoiceCreateContractWizard ~ WizardStore:',
    WizardStore?.selected,
  );

  const { parkingId, actions, mode } = WizardStore?.selected?.store;

  return (
    <Wizard<ChoiceCreateContractWizardContext>
      config={{
        title: t(`theDifferentActionsLinkedToContracts`),
        withNavigation: false,
      }}
      data={{
        defaultContext: { parkingId, actions, mode },
      }}
      handleEvent={{
        done: async (data) => {
          console.log('done', { data });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item
        config={{
          title: t('action'),
          component: ActionStep,
        }}
      />
    </Wizard>
  );
});
