import { useMutation, useRootStore, useTranslation } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { Mutation, MutationExitMotoristArgs } from '../../client/graphql';
import { exitLocalMotoristGql } from '../../common/gql/exit-local-motorist.gql';
import {
  ActionInline,
  ActionInlineType,
} from '../../components/action-inline/action-inline.component';

export declare namespace MotoristActionType {
  type Props = {
    config: {
      size: ActionInlineType.Config.Size;
    };
    activeContracts: boolean;
    localMotoristId?: string | null;
  };
}

export const MotoristAction = observer(
  ({
    config: { size },
    activeContracts,
    localMotoristId,
  }: MotoristActionType.Props) => {
    const { t } = useTranslation();

    const [exitMotoristCall] = useMutation<
      { exitMotorist: Mutation['exitMotorist'] },
      MutationExitMotoristArgs
    >(exitLocalMotoristGql);

    return (
      <ActionInline
        config={{ size }}
        data={[
          {
            key: 'exitRegistry',
            text: t('exitRegistry'),
            color: 'error',
            event: async () => {
              try {
                if (!localMotoristId)
                  throw new Error('Motorist ID is required');

                await exitMotoristCall({
                  variables: { localMotoristId },
                });
              } catch (error) {
                console.error(error);
              }
            },
            isCritical: true,
            show: !activeContracts,
          },
        ]}
      />
    );
  },
);
